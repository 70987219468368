'use es6';

export default (({
  EarlyRequester
}) => {
  const {
    portal
  } = EarlyRequester;
  return {
    portalCreationUtc: portal.created_at,
    portalId: portal.portal_id,
    portalIsPartner: portal.partner
  };
});