import { ONBOARDING_SCOPES } from '../scopeConstants';
import { excludesAll } from './arrayUtils';
const {
  CMS_ENTERPRISE,
  CMS_PRO,
  CMS_STARTER,
  MARKETING_ENTERPRISE,
  MARKETING_PRO,
  MARKETING_STARTER,
  OPS_ENTERPRISE,
  OPS_PRO,
  OPS_STARTER,
  SALES_ENTERPRISE,
  SALES_PRO,
  SALES_STARTER,
  SERVICE_ENTERPRISE,
  SERVICE_PRO,
  SERVICE_STARTER
} = ONBOARDING_SCOPES;
export const getHasCmsHubEnterprise = scopes => scopes.includes(CMS_ENTERPRISE);
export const getHasCmsHubPro = scopes => scopes.includes(CMS_PRO) && !scopes.includes(CMS_ENTERPRISE);
export const getHasCmsHubStarter = scopes => scopes.includes(CMS_STARTER) && excludesAll(scopes, [CMS_PRO, CMS_ENTERPRISE]);
export const getHasCmsHubFree = scopes => excludesAll(scopes, [CMS_STARTER, CMS_PRO, CMS_ENTERPRISE]);
export const getHasMarketingHubEnterprise = scopes => scopes.includes(MARKETING_ENTERPRISE);
export const getHasMarketingHubPro = scopes => scopes.includes(MARKETING_PRO) && !scopes.includes(MARKETING_ENTERPRISE);
export const getHasMarketingHubStarter = scopes => scopes.includes(MARKETING_STARTER) && excludesAll(scopes, [MARKETING_PRO, MARKETING_ENTERPRISE]);
export const getHasMarketingHubFree = scopes => excludesAll(scopes, [MARKETING_STARTER, MARKETING_PRO, MARKETING_ENTERPRISE]);
export const getHasOpsHubEnterprise = scopes => scopes.includes(OPS_ENTERPRISE);
export const getHasOpsHubPro = scopes => scopes.includes(OPS_PRO) && !scopes.includes(OPS_ENTERPRISE);
export const getHasOpsHubStarter = scopes => scopes.includes(OPS_STARTER) && excludesAll(scopes, [OPS_PRO, OPS_ENTERPRISE]);
export const getHasOpsHubFree = scopes => excludesAll(scopes, [OPS_STARTER, OPS_PRO, OPS_ENTERPRISE]);
export const getHasSalesHubEnterprise = scopes => scopes.includes(SALES_ENTERPRISE);
export const getHasSalesHubPro = scopes => scopes.includes(SALES_PRO) && !scopes.includes(SALES_ENTERPRISE);
export const getHasSalesHubStarter = scopes => scopes.includes(SALES_STARTER) && excludesAll(scopes, [SALES_PRO, SALES_ENTERPRISE]);
export const getHasSalesHubFree = scopes => excludesAll(scopes, [SALES_STARTER, SALES_PRO, SALES_ENTERPRISE]);
export const getHasServiceHubEnterprise = scopes => scopes.includes(SERVICE_ENTERPRISE);
export const getHasServiceHubPro = scopes => scopes.includes(SERVICE_PRO) && !scopes.includes(SERVICE_ENTERPRISE);
export const getHasServiceHubStarter = scopes => scopes.includes(SERVICE_STARTER) && excludesAll(scopes, [SERVICE_PRO, SERVICE_ENTERPRISE]);
export const getHasServiceHubFree = scopes => excludesAll(scopes, [SERVICE_STARTER, SERVICE_PRO, SERVICE_ENTERPRISE]);