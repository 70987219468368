'use es6';

import { isHubSpotter, isForceOpen, warn } from '../utils';
import translations from '../wootric-translations';
import * as UserAttributes from '../sources/UserAttributes';
const NPSTokenQA = 'NPS-d3f981a5';
const NPSTokenProd = 'NPS-d5cc225c';
export const getConfig = (token, props = {}) => {
  const {
    extra_questions,
    field_required,
    permission_labels,
    customMessages: overrideMessages,
    complete,
    supported
  } = translations;
  const lang = supported[props.language] ? props.language : 'EN';

  // Wootric doesn't support translating custom messages for other languages:
  // http://docs.wootric.com/javascript/#custom-language-setting
  // So we have to build our own light translation setup here
  let wootricTarget;
  let customMessages = {};
  let translatedConfig = {};
  if (complete[lang]) {
    const {
      followup_question_nps,
      followup_thank_you,
      placeholder_text,
      wootric_recommend_target
    } = overrideMessages[lang];
    wootricTarget = wootric_recommend_target;
    customMessages = {
      followup_question: followup_question_nps,
      followup_thank_you,
      placeholder_text
    };
  }
  translatedConfig = Object.assign({
    field_required,
    permission_labels,
    wootric_recommend_target: wootricTarget
  }, {
    extra_questions: [{
      input_type: 'string',
      input_form: 'dropdown',
      question_id: 'role',
      // this will also be the name that shows in the filter
      locales: extra_questions
    }]
  });
  const wootricSettings = Object.assign({}, props, {
    account_token: token,
    ask_permission_to_share_feedback: true,
    // this generates the checkbox (pre-checked)
    modal_footprint: 'normal',
    callbacks: {
      after_show: config => {
        UserAttributes.touch('nps:seen');
        if (config && config.localized_texts && translations.complete[props.language]) {
          try {
            config.localized_texts.send = translations.customMessages[props.language].send;
          } catch (e) {
            warn(`Could not set localized "Send" text for language: ${props.language}; message: ${e.message}`);
          }
        }
      },
      after_decline() {
        UserAttributes.touch('nps:declined');
      },
      after_response() {
        UserAttributes.touch('nps:responded');
      }
    }
  }, translatedConfig);
  return {
    customMessages,
    wootricSettings
  };
};
export const configureNps = props => {
  const token = isHubSpotter(props.email) ? NPSTokenQA : NPSTokenProd;
  if (isForceOpen()) {
    window.wootric_no_surveyed_cookie = true;
    console.log('"wootric-nps-open" flag is set.', 'Wootric should be forced open.', 'Disregard the message it gives saying "remove survey immediately".');
  }

  // seconds to delay before opening (default 10)
  window.wootric_time_delay = 1;
  const {
    wootricSettings,
    customMessages
  } = getConfig(token, props);
  window.customMessages = customMessages;
  window.wootricSettings = wootricSettings;
};