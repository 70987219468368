'use es6';

import http from 'hub-http/clients/apiClient';
import { O365_KEY, VSTO_KEY } from '../constants/AttributeKeys';
export let attributes = null;
export const fetchAttributes = keys => http.put('/users/v1/app/attributes/temp-hubless/bulk-get', {
  data: {
    keys
  },
  timeout: 15000
}).then(response => {
  const attrObject = {};
  response.attributes.forEach(item => {
    attrObject[item.key] = item.value;
  });
  attributes = attrObject;
  return attributes;
});
export const fetchRegisterAttributes = () => {
  return fetchAttributes([O365_KEY, VSTO_KEY]);
};