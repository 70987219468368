import Raven from 'raven-js';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { matchPath, useHistory } from 'react-router-dom';
import { useOnboardingKey } from '../hooks/config';
import * as ALL_ROUTES from '../constants/routeConstants';
export default function RouteChecker() {
  const history = useHistory();
  const onboardingKey = useOnboardingKey();
  const groupKey = useSelector(state => state.task.groupKey);
  useEffect(() => {
    const {
      pathname,
      search
    } = history.location;
    const matchedRoute = Object.values(ALL_ROUTES).find(route => {
      return matchPath(pathname, {
        path: route,
        exact: true
      });
    });
    if (!matchedRoute) {
      Raven.captureMessage('Unknown route in User Guide', {
        level: 'warning',
        extra: {
          groupKey,
          onboardingKey,
          pathname
        }
      });
      history.replace({
        pathname: ALL_ROUTES.BASE_ROUTE,
        search
      });
    }
  }, [history, groupKey, onboardingKey]);
  return null;
}