import http from 'hub-http/clients/apiClient';
import { buildUrl } from 'hub-http/helpers/url';
/**
 * Creates or updates an account's credentials in the platform
 *
 * The params will contain all the required data as specified by the
 * authentication configuration.
 * This includes an OAuth code if the app authenticates via OAuth.
 */
export function postAccountCredentials(portalId, appId, accountId, params) {
  return http.post(accountId ? `/api/sync-bridge/v3/accounts/${accountId}/credentials` : '/api/sync-bridge/v3/install/finish', {
    data: params,
    query: {
      portalId,
      appId: appId.toString()
    }
  });
}
export function getAppResponseUrl(appId) {
  return buildUrl({
    path: `/api/sync-bridge/v5/apps/${appId}`
  });
}
export function postAccountDataset(accountId, portalId) {
  return http.post(buildUrl({
    path: `/api/sync-bridge/v3/dataset-parameters/by-account-id?portalId=${portalId}&accountId=${accountId}`
  }), {
    data: {
      remoteObjectType: 'contacts',
      parameters: [] // The `parameters` field is only used for Outlook.
    }
  });
}
export function postAccountConnection(accountId, portalId, appId, scopes) {
  return http.post(buildUrl({
    path: `/api/sync-bridge/v3/accounts/${accountId}/connection?portalId=${portalId}&appId=${appId}`
  }), {
    data: {
      objectTypes: {
        hubSpotObjectTypeId: '0-1',
        remote: 'contacts'
      },
      scopes,
      direction: 'INBOUND'
    }
  });
}
export function putConnection(connectionId, portalId, appId) {
  return http.put(buildUrl({
    path: `/api/sync-bridge/v3/connections/${connectionId}?portalId=${portalId}&appId=${appId}`
  }), {
    data: {
      conflictResolution: 'DEFAULT_TO_HUBSPOT',
      rules: {
        direction: 'INBOUND',
        filters: {},
        updateOnlyDirection: 'NONE'
      },
      matchingFilter: {
        name: 'email_only',
        value: true
      },
      defaultValueDatasetParameters: []
    }
  });
}
export function postConnectionActivate(connectionId, portalId, appId) {
  return http.post(buildUrl({
    path: `/api/sync-bridge/v3/connections/${connectionId}/activate?portalId=${portalId}&appId=${appId}`
  }));
}