import usageTracker from './usageTracker';
import events from '../../events.yaml';
import { getAppName } from './appNameUtils';
export const namespace = events.sectionView.namespace;
const eventNames = Object.keys(events).reduce((result, name) => {
  result[name] = name;
  return result;
}, {});
export const trackUserInteraction = (action, source = getAppName(), value) => {
  return usageTracker.track(eventNames.userInteraction, {
    action,
    source,
    value
  });
};
export const trackSectionView = (section, source = getAppName()) => {
  return usageTracker.track(eventNames.sectionView, {
    section,
    source
  });
};
export const trackCreateUser = (isSuperAdmin, source) => {
  return usageTracker.track(eventNames.createUser, {
    userCount: 1,
    teamCount: 1,
    existing: false,
    'basePermissions.contacts': true,
    'basePermissions.marketing': false,
    'basePermissions.sales': false,
    'basePermissions.admin': false,
    roleNames: isSuperAdmin ? ['super-admin'] : ['contacts-base'],
    grantedSuperAdmin: isSuperAdmin,
    requiredCheckout: false,
    assignedToTeam: false,
    importFromCsv: false,
    emailSent: true,
    source
  });
};