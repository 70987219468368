import http from 'hub-http/clients/apiClient';
const NAVCONFIG_RESOURCE = 'navconfig/v2/home';
const NAVCONFIG_OPTIONS_RESOURCE = `${NAVCONFIG_RESOURCE}/options`;
export const getNavConfig = () => http.get(NAVCONFIG_OPTIONS_RESOURCE);
export const setDefaultHome = homeKey => http.put(NAVCONFIG_OPTIONS_RESOURCE, {
  data: homeKey,
  headers: {
    'content-type': 'text/plain'
  }
});
export const getDefaultHome = () => http.get(NAVCONFIG_RESOURCE);