import { useContext } from 'react';
import { FREE_GROUP_KEYS } from 'getting-started-shared-tasks/gettingStartedGroupKeys';
import { ONBOARDING_VIEWS } from 'onboarding-settings-client/constants/onboardingViews';
import NavigationContext from '../contexts/NavigationContext';
import { useStorageValue } from '../hooks/storage';
const COMPLETED_GROUP_KEY_TO_NEW_TAB_MAP = {
  [FREE_GROUP_KEYS.marketingToolsGroup]: ONBOARDING_VIEWS.SALES,
  [FREE_GROUP_KEYS.salesToolsGroup]: ONBOARDING_VIEWS.SERVICE,
  [FREE_GROUP_KEYS.serviceToolsGroup]: ONBOARDING_VIEWS.CMS,
  [FREE_GROUP_KEYS.cmsToolsGroup]: ONBOARDING_VIEWS.MARKETING
};
export const useIsCtaRetry = ctaId => {
  return useStorageValue(ctaId) !== undefined;
};
export const useTabNavigationTarget = completedGroupKey => {
  return COMPLETED_GROUP_KEY_TO_NEW_TAB_MAP[completedGroupKey] || ONBOARDING_VIEWS.MARKETING;
};
export const useNavigationContext = () => {
  return useContext(NavigationContext);
};
export const useSetSelectedView = () => {
  return useNavigationContext().setSelectedTabView;
};