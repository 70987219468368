import { FREE_GROUP_KEYS } from 'getting-started-shared-tasks/gettingStartedGroupKeys';
import { ONBOARDING_MARKETING_FREE, ONBOARDING_SALES_FREE, ONBOARDING_FREE_SERVICE, ONBOARDING_CMS_FREE } from 'getting-started-shared-tasks/onboardingKeys';
import { ONBOARDING_VIEWS } from 'user-context/onboardingSettings';
const {
  SALES,
  MARKETING,
  SERVICE,
  CMS
} = ONBOARDING_VIEWS;
export const DEFAULT_FREE_ONBOARDING_SETTINGS_VIEWS = {
  [SALES]: {
    groupKey: FREE_GROUP_KEYS.freeSalesView,
    onboardingKey: ONBOARDING_SALES_FREE
  },
  [MARKETING]: {
    groupKey: FREE_GROUP_KEYS.freeMarketingView,
    onboardingKey: ONBOARDING_MARKETING_FREE
  },
  [SERVICE]: {
    groupKey: FREE_GROUP_KEYS.freeServiceView,
    onboardingKey: ONBOARDING_FREE_SERVICE
  },
  [CMS]: {
    groupKey: FREE_GROUP_KEYS.freeCmsView,
    onboardingKey: ONBOARDING_CMS_FREE
  }
};