export const getAppRootUrl = () => {
  const rootUrl = window.location.pathname.split('/')[0];

  // If rootUrl is 'l', it means we're on the Portal selection page
  if (rootUrl === 'l') {
    return 'hub-recommend-ui';
  }
  return `/${rootUrl}`; // Add leading slash to distinguish from bender.currentProject
};
export const getAppName = () => {
  return window.hubspot &&
  // @ts-expect-error incomplete type for window globals
  window.hubspot.bender &&
  // @ts-expect-error incomplete type for window globals
  window.hubspot.bender.currentProject || getAppRootUrl() || 'unknown';
};