import enviro from 'enviro';
import { getMailchimpImportUrl, getImportUrl } from '../utils/urls';
import { DataSyncOptionApp } from './types';

// @ts-expect-error svg image
import googleContactsSrc from 'bender-url!../../images/google-contacts.svg';
// @ts-expect-error svg image
import outlookExchangeSrc from 'bender-url!../../images/outlook-exchange-contacts.svg';
const GMAIL_PROD_APP_ID = 232794;
const GMAIL_QA_APP_ID = 1203680;
const OUTLOOK_PROD_APP_ID = 236007;
const OUTLOOK_QA_APP_ID = 1208258;
const ZOHO_PROD_APP_ID = 236009;
const ZOHO_QA_APP_ID = 1208260;
const PIPEDRIVE_PROD_APP_ID = 236651;
const PIPEDRIVE_QA_APP_ID = 1208281;
const isProd = enviro.isProd();
export const DATA_SYNC_APP_IDS = {
  [DataSyncOptionApp.Gmail]: isProd ? GMAIL_PROD_APP_ID : GMAIL_QA_APP_ID,
  [DataSyncOptionApp.Outlook]: isProd ? OUTLOOK_PROD_APP_ID : OUTLOOK_QA_APP_ID,
  [DataSyncOptionApp.Zoho]: isProd ? ZOHO_PROD_APP_ID : ZOHO_QA_APP_ID,
  [DataSyncOptionApp.Pipedrive]: isProd ? PIPEDRIVE_PROD_APP_ID : PIPEDRIVE_QA_APP_ID
};
export const DATA_SYNC_OPTIONS = {
  [DataSyncOptionApp.Spreadsheet]: {
    url: getImportUrl(),
    id: 'spreadsheet'
  },
  [DataSyncOptionApp.Gmail]: {
    id: 'google-contacts',
    isOneClickSync: true
  },
  [DataSyncOptionApp.Outlook]: {
    id: 'outlook'
  },
  [DataSyncOptionApp.Mailchimp]: {
    url: getMailchimpImportUrl(),
    id: 'mailchimp'
  },
  [DataSyncOptionApp.Zoho]: {
    id: 'zoho-crm'
  },
  [DataSyncOptionApp.Pipedrive]: {
    id: 'pipedrive'
  }
};
export const ONE_CLICK_SYNC_APPS = Object.keys(DATA_SYNC_OPTIONS).filter(dataSyncApp => DATA_SYNC_OPTIONS[dataSyncApp].isOneClickSync);
export const DataSyncOptionAppsConfigs = {
  [DataSyncOptionApp.Spreadsheet]: {
    illustrationProps: {
      illustration: 'import-file'
    },
    textMessageKey: 'growthDataModal.options.spreadsheet.text'
  },
  [DataSyncOptionApp.Gmail]: {
    appId: DATA_SYNC_APP_IDS[DataSyncOptionApp.Gmail],
    illustrationProps: {
      imageSrc: googleContactsSrc,
      imageAltTextMessageKey: 'growthDataModal.options.google-contacts.alt'
    },
    textMessageKey: 'growthDataModal.options.google-contacts.text'
  },
  [DataSyncOptionApp.Outlook]: {
    appId: DATA_SYNC_APP_IDS[DataSyncOptionApp.Outlook],
    illustrationProps: {
      imageSrc: outlookExchangeSrc,
      imageAltTextMessageKey: 'growthDataModal.options.outlook.alt'
    },
    textMessageKey: 'growthDataModal.options.outlook.text',
    smallTextMessageKey: 'growthDataModal.options.outlook.smallText'
  },
  [DataSyncOptionApp.Mailchimp]: {
    illustrationProps: {
      logo: 'mailchimp'
    },
    textMessageKey: 'growthDataModal.options.mailchimp.text'
  },
  [DataSyncOptionApp.Zoho]: {
    appId: DATA_SYNC_APP_IDS[DataSyncOptionApp.Zoho],
    illustrationProps: {
      logo: 'zoho-crm'
    },
    textMessageKey: 'growthDataModal.options.zoho.text'
  },
  [DataSyncOptionApp.Pipedrive]: {
    appId: DATA_SYNC_APP_IDS[DataSyncOptionApp.Pipedrive],
    illustrationProps: {
      logo: 'pipedrive'
    },
    textMessageKey: 'growthDataModal.options.pipedrive.text'
  }
};