import { TASK_PROGRESS_REQUESTED, TASK_PROGRESS_RECEIVED, TASK_PROGRESS_ERROR, TASK_SET_COMPLETE_RECEIVED, TASK_SKIP_REQUESTED, TASK_SKIP_ERROR, TASK_UNDO_SKIP_REQUESTED, TASK_UNDO_SKIP_ERROR, TASK_ERROR_STATUS_RESET } from '../actions/ActionTypes';
const INITIAL_STATE = {
  isFetching: false,
  hasFetched: false,
  error: undefined,
  tasksCompleted: [],
  tasksSkipped: [],
  timestamp: Date.now()
};
export default function taskProgressReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case TASK_PROGRESS_REQUESTED:
      return Object.assign({}, INITIAL_STATE, {
        hasFetched: false,
        isFetching: true,
        timestamp: action.timestamp || Date.now()
      });
    case TASK_PROGRESS_RECEIVED:
      {
        const {
          tasksCompleted,
          tasksSkipped
        } = action.payload;
        const {
          timestamp
        } = action;
        if (timestamp && timestamp < state.timestamp) {
          return state;
        }
        return {
          isFetching: false,
          hasFetched: true,
          error: undefined,
          tasksCompleted,
          tasksSkipped,
          timestamp: timestamp || Date.now()
        };
      }
    case TASK_PROGRESS_ERROR:
      return Object.assign({}, state, {
        isFetching: false,
        hasFetched: false,
        error: action.payload
      });
    case TASK_SET_COMPLETE_RECEIVED:
      {
        const newTasksCompleted = [...state.tasksCompleted];
        if (!newTasksCompleted.includes(action.payload)) {
          newTasksCompleted.push(action.payload);
        }
        return Object.assign({}, state, {
          tasksCompleted: newTasksCompleted,
          tasksSkipped: state.tasksSkipped.filter(taskKey => taskKey !== action.payload)
        });
      }
    case TASK_SKIP_REQUESTED:
      return Object.assign({}, state, {
        tasksSkipped: [...state.tasksSkipped, action.payload]
      });
    case TASK_SKIP_ERROR:
      return Object.assign({}, state, {
        tasksSkipped: state.tasksSkipped.filter(taskKey => taskKey !== action.payload.taskKey),
        taskErrorStatus: action.payload.taskErrorStatus
      });
    case TASK_UNDO_SKIP_REQUESTED:
      return Object.assign({}, state, {
        tasksSkipped: state.tasksSkipped.filter(taskKey => taskKey !== action.payload)
      });
    case TASK_UNDO_SKIP_ERROR:
      return Object.assign({}, state, {
        tasksSkipped: [...state.tasksSkipped, action.payload.taskKey],
        taskErrorStatus: action.payload.taskErrorStatus
      });
    case TASK_ERROR_STATUS_RESET:
      return Object.assign({}, state, {
        taskErrorStatus: undefined
      });
    default:
      return state;
  }
}