import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
const _excluded = ["suite"];
import { captureNetworkError } from 'growth-onboarding-reliability/utils/raven';
import { getEarlyRequestByKey } from 'setup-guide-api/utils/earlyRequestUtils';
import { getSettings } from 'onboarding-settings-client/api/settings';
import { ONBOARDING_VIEWS } from 'onboarding-settings-client/constants/onboardingViews';
import { CHECKOUT } from '../constants/urlConstants';
import { QUICK_FETCH_ONBOARDING_SETTINGS_KEY } from '../constants/taskConstants';
import { VIEWS_DEFAULT_ORDER } from '../constants/viewsConstants';
import { applyOnboardingSettingsOverrides, setStarterMultiHubOnboardingSettings } from '../utils/onboardingSettingsUtils';
import { getAllQueryParams, getQueryParams } from '../utils/urls';
import { ONBOARDING_SETTINGS_ERROR, ONBOARDING_SETTINGS_RECEIVED } from './ActionTypes';
import { reloadUserGuideTasks } from './AppActions';
export function receiveOnboardingKey(onboardingSettings) {
  return {
    type: ONBOARDING_SETTINGS_RECEIVED,
    onboardingSettings
  };
}
function applyOnboardingAttributeOverrides(onboardingSettings) {
  const {
    enroll,
    onboardingKey,
    groupKey,
    activeTab,
    activeView = activeTab
  } = getQueryParams();
  if (!activeView) {
    return onboardingSettings;
  }
  return applyOnboardingSettingsOverrides(onboardingSettings, activeView, onboardingKey, enroll && groupKey // We only want to store new group key in redux state if enroll=true,
  );
}
export function fetchOnboardingSettings() {
  return (dispatch, getState) => {
    const {
      auth
    } = getState();
    const {
      user: {
        user_id: userId
      }
    } = auth;
    const {
      newHubs,
      via
    } = getAllQueryParams();
    const handleOnboardingSettings = onboardingSettings => {
      const hasViews = onboardingSettings && onboardingSettings.views;
      if (!hasViews) {
        return dispatch(receiveOnboardingKey(undefined));
      }
      const {
        views
      } = onboardingSettings;
      if (!onboardingSettings.selectedView) {
        const defaultSelectedView = VIEWS_DEFAULT_ORDER.find(view => views[view]);
        onboardingSettings.selectedView = defaultSelectedView;
      }
      if (views && views.suite && views.suite.onboardingKey === 'crm_suite_free_overview') {
        // fallback for ONBS008 removal - free users who were assigned the suite tab in ONBS008 variant should no longer see it
        if (onboardingSettings.selectedView === ONBOARDING_VIEWS.SUITE) {
          onboardingSettings.selectedView = ONBOARDING_VIEWS.SALES;
        }
        const viewsWithoutSuite = _objectWithoutPropertiesLoose(views, _excluded);
        onboardingSettings.views = viewsWithoutSuite;
      }
      const onboardingSettingsWithOverride = applyOnboardingAttributeOverrides(onboardingSettings);

      // For multi hub purchase (but not all hubs), onboarding setting happens here instead of the checkout confirmation page.
      // We can ignore single hub purchase as onboarding setting takes place in end-to-end-onboarding-starter-ui.
      // TODO: This is temp fix and will be removed once proper checkout confirmation flow is designed.
      if (via === CHECKOUT && Array.isArray(newHubs)) {
        return setStarterMultiHubOnboardingSettings(onboardingSettings, newHubs, userId).then(newStarterOnboardingSettings => {
          const {
            selectedView,
            views: starterViews
          } = newStarterOnboardingSettings;

          // Get selected view groupkey for tasks and task progress
          const selectedViewOnboardingSettings = selectedView && starterViews && starterViews[selectedView];
          const selectedViewGroupKey = selectedViewOnboardingSettings && selectedViewOnboardingSettings.groupKey;
          if (selectedViewGroupKey) {
            dispatch(reloadUserGuideTasks(selectedViewGroupKey));
          }
          return dispatch(receiveOnboardingKey(newStarterOnboardingSettings));
        }).catch();
      }
      return dispatch(receiveOnboardingKey(onboardingSettingsWithOverride));
    };
    return getEarlyRequestByKey(QUICK_FETCH_ONBOARDING_SETTINGS_KEY, getSettings).then(settings => handleOnboardingSettings(settings)).catch(error => {
      dispatch({
        type: ONBOARDING_SETTINGS_ERROR,
        error
      });
      captureNetworkError(error);
    });
  };
}