import { TASK_REQUEST_RECEIVED } from 'setup-guide-api/actions/ActionTypes';
const INITIAL_STATE = {
  taskGroups: {}
};
export default function cacheDataReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case TASK_REQUEST_RECEIVED:
      {
        const {
          groupKey,
          taskGroups,
          taskMap
        } = action.payload;
        return Object.assign({}, state, {
          taskGroups: Object.assign({}, state.taskGroups, {
            [groupKey]: {
              groupKey,
              taskGroups,
              taskMap,
              timestamp: Date.now()
            }
          })
        });
      }
    default:
      return state;
  }
}