import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
const _excluded = ["childGroups"];
import { markActionComplete, setGettingStartedGroupKey } from 'user-context/onboarding';
import { getTasksByGroupKey } from 'user-context-app-client/api/tasks';
import { getProgress, getProgressSummary } from 'user-context-app-client/api/progress';
import { captureNetworkError, catchAndRethrowNetworkError } from 'growth-onboarding-reliability/utils/raven';
import { getOnboardingProgressEarlyRequestKey, getOnboardingTasksEarlyRequestKey } from '../constants/taskConstants';
import { getEarlyRequestByKey } from '../utils/earlyRequestUtils';
import { getQueryParams } from '../utils/urls';
const fetchUserTasks = (groupKey = '') => getTasksByGroupKey(groupKey);

/**
 * Get task groups from usercontext
 *
 * @returns {Promise} response - a promise which resolves task data
 * groupKey - the key of task group
 * taskGroups - array of task groups, tasks property in group is array of task keys
 * taskMap - object of all tasks, key is task key, value is task configuration
 */
export function getUserTasks(groupKey) {
  const request = getEarlyRequestByKey(getOnboardingTasksEarlyRequestKey(groupKey), () => fetchUserTasks(groupKey));
  return request.then(_ref => {
    let {
        childGroups
      } = _ref,
      groupConfig = _objectWithoutPropertiesLoose(_ref, _excluded);
    const allGroups = childGroups;
    if (groupConfig.tasks && groupConfig.tasks.length > 0) {
      allGroups.push(Object.assign({}, groupConfig, {
        childGroups: []
      }));
    }
    const taskMap = {};
    const taskGroups = allGroups.map(taskGroup => {
      // TODO: Use recursion to support more than 3 levels of depth
      const activeTasks = [...taskGroup.tasks, ...taskGroup.childGroups.flatMap(({
        tasks
      }) => tasks)].filter(task => task.active);
      return Object.assign({}, taskGroup, {
        taskKeys: activeTasks.map(task => {
          taskMap[task.key] = task;
          return task.key;
        }),
        tasks: activeTasks
      });
    });
    const allTasks = Object.values(taskMap);
    const taskMapById = allTasks.reduce((tasks, task) => {
      tasks[task.id] = task;
      return tasks;
    }, {});
    allTasks.forEach(task => {
      const {
        dependencies
      } = task;
      if (Array.isArray(dependencies) && dependencies.length > 0) {
        task.dependentTasks = (task.dependencies || []).map(dependencyTaskId => taskMapById[dependencyTaskId]).filter(Boolean);
      }
    });
    return {
      groupKey: groupConfig.key,
      taskGroups,
      taskMap
    };
  }).catch(catchAndRethrowNetworkError);
}
export const fetchUserProgress = (groupKey = getQueryParams().groupKey) => {
  const singleGroupKey = Array.isArray(groupKey) ? groupKey[0] : groupKey;
  return getProgress(singleGroupKey || undefined);
};
export function getUserProgress(groupKey) {
  return getEarlyRequestByKey(getOnboardingProgressEarlyRequestKey(groupKey), () => fetchUserProgress(groupKey));
}
export const fetchUserProgressSummary = (groupKey = getQueryParams().groupKey) => {
  const singleGroupKey = Array.isArray(groupKey) ? groupKey[0] : groupKey;
  return getProgressSummary(singleGroupKey).catch(e => {
    captureNetworkError(e);
    return {
      percentage: -1,
      tasksCount: 0,
      completedTasksCount: 0
    };
  });
};
export function completeUserTask(taskKey) {
  return markActionComplete(taskKey);
}
export function changeGroupKey(groupKey = '', hubType, userId) {
  if (!groupKey) {
    return Promise.resolve();
  }
  return setGettingStartedGroupKey(groupKey, hubType, userId);
}