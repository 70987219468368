import { useContext } from 'react';
import { ReactReduxContext } from 'react-redux';
import { useAuthContext } from '../contexts/AuthContext';

// This isn't recommended and is a workaround to access the Redux store conditionally
// from within this widely-used library without having lots of handling code in each
// consumer or complex try / catch patterns within this codebase.
// Please see: https://react-redux.js.org/using-react-redux/accessing-store
export const useMaybeReduxAuthContext = () => {
  const {
    store
  } = useContext(ReactReduxContext) || {};
  const maybeState = store && store.getState && store.getState();
  return maybeState ? maybeState.auth : undefined;
};
export const useGates = () => useAuthContext().gates;
export const useIsUngated = gateName => {
  return useGates().includes(gateName);
};