import { DataSyncOptionApp } from './types';
export const CONNECT_APPS_OPTIONS = {
  [DataSyncOptionApp.Spreadsheet]: {},
  [DataSyncOptionApp.Gmail]: {},
  [DataSyncOptionApp.Outlook]: {},
  [DataSyncOptionApp.Mailchimp]: {},
  [DataSyncOptionApp.Zoho]: {
    dataSyncUrlSearchParams: [{
      param: 'preSelectedObjectTypeId',
      value: '0-1'
    }, {
      param: 'preSelectedRemoteObject',
      value: 'contacts'
    }]
  },
  [DataSyncOptionApp.Pipedrive]: {
    dataSyncUrlSearchParams: [{
      param: 'preSelectedObjectTypeId',
      value: '0-1'
    }, {
      param: 'tourId',
      value: 'data-sync-flow'
    }]
  }
};