import http from 'hub-http/clients/apiClient';
import { SEATS_ACCESS_QUICK_FETCH_KEY } from '../constants/seatsConstants';
import { getEarlyRequestByKey } from 'setup-guide-api/utils/earlyRequestUtils';
const getSeatAssignments = () => http.get('/app-users/v1/seat-assignments/self');
export const fetchSeatAssignments = () => {
  return getEarlyRequestByKey(SEATS_ACCESS_QUICK_FETCH_KEY, getSeatAssignments).then(resp => {
    if (resp) {
      return resp.map(seatItem => seatItem.seatName);
    }
    return [];
  });
};