import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getIsFreePortal, getIsPaidPortal, getIsStarterPortal, getIsFreeOrStarterPortal, getIsProPortal, getIsProPlusPortal, getIsEnterprisePortal } from './utils/overallTier';
import { getHasCrmSuiteStarter, getHasCrmSuitePro, getHasCrmSuiteEnterprise } from './utils/suite';
import { getHasCmsHubEnterprise, getHasCmsHubPro, getHasCmsHubStarter, getHasCmsHubFree, getHasMarketingHubEnterprise, getHasMarketingHubPro, getHasMarketingHubStarter, getHasMarketingHubFree, getHasOpsHubEnterprise, getHasOpsHubPro, getHasOpsHubStarter, getHasOpsHubFree, getHasSalesHubEnterprise, getHasSalesHubPro, getHasSalesHubStarter, getHasSalesHubFree, getHasServiceHubEnterprise, getHasServiceHubPro, getHasServiceHubStarter, getHasServiceHubFree } from './utils/individualHubs';
import { getHighestCmsHubEdition, getHighestMarketingHubEdition, getHighestOpsHubEdition, getHighestSalesHubEdition, getHighestServiceHubEdition, getHighestCommonSuiteEdition, getHighestHubEditions, getAllHighestHubEditions, getHighestEdition } from './utils/highestEdition';
export const useScopes = () => useSelector(state => {
  const scopes = state.auth && state.auth.user && state.auth.user.scopes;
  if (!scopes) {
    console.warn(`Onboarding Scopes: No Scopes present in state, this will cause unexpected behaviour in identifying Hubs.
        Is this hook used within a Redux context? Does the Redux context store scopes at 'auth.user.scopes'?`, {
      state
    });
    return [];
  }
  return scopes;
});

// Portal-level Hooks

export const useIsFreePortal = () => {
  return getIsFreePortal(useScopes());
};
export const useIsPaidPortal = () => {
  return getIsPaidPortal(useScopes());
};
export const useIsStarterPortal = () => {
  return getIsStarterPortal(useScopes());
};
export const useIsFreeOrStarterPortal = () => {
  return getIsFreeOrStarterPortal(useScopes());
};
export const useIsProPortal = () => {
  return getIsProPortal(useScopes());
};
export const useIsProPlusPortal = () => {
  return getIsProPlusPortal(useScopes());
};
export const useIsEnterprisePortal = () => {
  return getIsEnterprisePortal(useScopes());
};

// Suite-level Hooks

export const useHasCrmSuiteStarter = () => {
  return getHasCrmSuiteStarter(useScopes());
};
export const useHasCrmSuitePro = () => {
  return getHasCrmSuitePro(useScopes());
};
export const useHasCrmSuiteEnterprise = () => {
  return getHasCrmSuiteEnterprise(useScopes());
};

// Hub-level Hooks

export const useHasCmsHubEnterprise = () => getHasCmsHubEnterprise(useScopes());
export const useHasCmsHubPro = () => getHasCmsHubPro(useScopes());
export const useHasCmsHubStarter = () => getHasCmsHubStarter(useScopes());
export const useHasCmsHubFree = () => getHasCmsHubFree(useScopes());
export const useHasMarketingHubEnterprise = () => getHasMarketingHubEnterprise(useScopes());
export const useHasMarketingHubPro = () => getHasMarketingHubPro(useScopes());
export const useHasMarketingHubStarter = () => getHasMarketingHubStarter(useScopes());
export const useHasMarketingHubFree = () => getHasMarketingHubFree(useScopes());
export const useHasOpsHubEnterprise = () => getHasOpsHubEnterprise(useScopes());
export const useHasOpsHubPro = () => getHasOpsHubPro(useScopes());
export const useHasOpsHubStarter = () => getHasOpsHubStarter(useScopes());
export const useHasOpsHubFree = () => getHasOpsHubFree(useScopes());
export const useHasSalesHubEnterprise = () => getHasSalesHubEnterprise(useScopes());
export const useHasSalesHubPro = () => getHasSalesHubPro(useScopes());
export const useHasSalesHubStarter = () => getHasSalesHubStarter(useScopes());
export const useHasSalesHubFree = () => getHasSalesHubFree(useScopes());
export const useHasServiceHubEnterprise = () => getHasServiceHubEnterprise(useScopes());
export const useHasServiceHubPro = () => getHasServiceHubPro(useScopes());
export const useHasServiceHubStarter = () => getHasServiceHubStarter(useScopes());
export const useHasServiceHubFree = () => getHasServiceHubFree(useScopes());
export const useHighestCmsHubEdition = () => getHighestCmsHubEdition(useScopes());
export const useHighestMarketingHubEdition = () => getHighestMarketingHubEdition(useScopes());
export const useHighestOpsHubEdition = () => getHighestOpsHubEdition(useScopes());
export const useHighestSalesHubEdition = () => getHighestSalesHubEdition(useScopes());
export const useHighestServiceHubEdition = () => getHighestServiceHubEdition(useScopes());
export const useHighestCommonSuiteEdition = () => getHighestCommonSuiteEdition(useScopes());
export const useHighestHubEditions = hubs => {
  const scopes = useScopes();
  return useMemo(() => getHighestHubEditions(scopes, hubs), [scopes, hubs]);
};
export const useAllHighestHubEditions = () => {
  const scopes = useScopes();
  return useMemo(() => getAllHighestHubEditions(scopes), [scopes]);
};
export const useHighestEdition = () => {
  const scopes = useScopes();
  return useMemo(() => getHighestEdition(scopes), [scopes]);
};