import { getQueryParams } from './urls';
export const getGroupKeyOverride = onboardingSettings => {
  if (!onboardingSettings) {
    return undefined;
  }
  const {
    groupKey,
    activeTab,
    activeView = activeTab
  } = getQueryParams();
  if (groupKey) {
    return groupKey;
  }
  if (onboardingSettings && onboardingSettings.views && onboardingSettings.views[activeView]) {
    return onboardingSettings.views[activeView].groupKey;
  }
  return undefined;
};