'use es6';

const Levels = {
  WARNING: 'warning',
  ERROR: 'error'
};
function reportToSentry(level, ...args) {
  if (!(window.Raven && window.Raven.captureMessage)) {
    console.warn('[wootric-nps]', 'Tried to send error to Sentry but Raven isnt on the document.');
    return;
  }
  const message = args.map(arg => arg.toString()).join(' ');
  window.Raven.captureMessage(message, {
    level
  });
}
export function log(...message) {
  console.log('[wootric-nps]', ...message);
}
export function warn(...message) {
  console.warn('[wootric-nps]', ...message);
  reportToSentry(Levels.WARNING, '[wootric-nps]', ...message);
}
export function error(...message) {
  console.error('[wootric-nps]', ...message);
  reportToSentry(Levels.ERROR, '[wootric-nps]', ...message);
}
export function createException(...message) {
  reportToSentry(Levels.ERROR, '[wootric-nps]', ...message);
  return new Error(message.join(' '));
}