import Loadable from 'UIComponents/decorators/Loadable';
import { addDataModal, addTrackingCode, connectInbox, connectInboxAndCalendar, installExtension, integrateAppsWithHubspot } from 'getting-started-shared-tasks/manualDetectedTasks';
import LoadingModal from '../../modal/base/LoadingModal';
import { ConnectInboxModalContainer } from '../../modal/inbox';
export const InstallTrackingModal = Loadable({
  loader: () => import( /* webpackPrefetch: true, webpackChunkName: "InstallTrackingModal" */'../../modal/InstallTrackingModal')
});
export const InstallWizardModal = Loadable({
  LoadingComponent: LoadingModal,
  loader: () => import( /* webpackPrefetch: true, webpackChunkName: "InstallExtensionModal" */'../../modal/InstallExtensionModal')
});
export const DataSyncTaskModalContainer = Loadable({
  LoadingComponent: LoadingModal,
  loader: () => import( /* webpackPrefetch: true, webpackChunkName: "DataSyncModal" */'../../modal/dataSync/DataSyncTaskModalContainer')
});
export const MarketplaceModalContainer = Loadable({
  LoadingComponent: LoadingModal,
  loader: () => import( /* webpackPrefetch: true, webpackChunkName: "MarketplaceModal" */'../../modal/MarketplaceModal')
});
export const ManualDetectedTaskComponents = {
  [addTrackingCode]: InstallTrackingModal,
  [connectInbox]: ConnectInboxModalContainer,
  [connectInboxAndCalendar]: ConnectInboxModalContainer,
  [installExtension]: InstallWizardModal,
  [addDataModal]: DataSyncTaskModalContainer,
  [integrateAppsWithHubspot]: MarketplaceModalContainer
};