// When we should automatically shutdown Session Replay when there's no `usage-tracker` activity
export const DEFAULT_SHUTDOWN_TIMEOUT = 1000 * 60 * 2; // 2 minutes

// The default sample rate for Session Replay is 1% of the traffic
export const DEFAULT_SAMPLE_RATE = 0.01; // 1% of traffic will be recorded

// Amplitude API Keys from our supported Amplitude Projects
export const AMPLITUDE_API_KEYS = {
  // This is a public API key (non-sensitive) for HubSpot Product Project on Amplitude
  // @see https://app.amplitude.com/analytics/hubspot/settings/projects/161755/general
  HUBSPOT_PRODUCT: 'f62456b4bedefd5d91d03b843f02a857',
  // This is a public API key (non-sensitive) for HubSpot Product QA Project on Amplitude
  // @see https://app.amplitude.com/analytics/hubspot/settings/projects/153458/general
  HUBSPOT_PRODUCT_QA: 'cb64369a332d0096afdfc85a72910b20'
};
export const DEFAULT_PII_SELECTORS = {
  BLOCK: [
  // For retro compatibility with Full Story we add `.fs-exclude` to the Block Selectors
  // @see https://help.fullstory.com/hc/en-us/articles/360020623574-How-do-I-protect-my-users-privacy-in-Fullstory
  '.fs-exclude'],
  MASK: [
  // Masks the global navigation (V3/V4) menu entry that contains your profile information
  // since this is a global element, we want to block it by default
  '#accounts-menu-content > li:first-child',
  // Masks the global navigation (V3/V4) switch menu button that includes your profile picture
  '#hs-global-toolbar-accounts',
  // For retro compatibility with Full Story we add `.fs-mask` to the Mask Selectors
  // @see https://help.fullstory.com/hc/en-us/articles/360020623574-How-do-I-protect-my-users-privacy-in-Fullstory
  '.fs-mask',
  // Masks the UISelect Popover Menu that contains all the options of the UISelect, effectively
  // masking all the text under the UISelect's Dropdown menus. This should work with all variants of UISelect
  '.Select-menu-outer'],
  UNMASK: [
  // For retro compatibility with Full Story we add `.fs-unmask` to the Unmask Selectors
  // @see https://help.fullstory.com/hc/en-us/articles/360020623574-How-do-I-protect-my-users-privacy-in-Fullstory
  '.fs-unmask']
};