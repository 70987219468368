import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
const _excluded = ["tourId"];
import { parseJSON, sessionStorage } from './storageUtil';
const ONBOARDING_NEXT_TOUR_KEY = 'ONBOARDING_NEXT_TOUR';
const DEFAULT_EXPIRES = 1800000; // 30 mins

export const cleanupLinkedTour = () => {
  sessionStorage.removeItem(ONBOARDING_NEXT_TOUR_KEY);
};
export const enableLinkedTour = state => {
  const {
    expires = Date.now() + DEFAULT_EXPIRES
  } = state;
  sessionStorage.setItem(ONBOARDING_NEXT_TOUR_KEY, JSON.stringify(Object.assign({}, state, {
    expires
  })));
};
const isStateExpired = expires => {
  return expires !== undefined && expires < Date.now();
};
const getLinkedTourData = () => {
  const linkedTourStorage = sessionStorage.getItem(ONBOARDING_NEXT_TOUR_KEY);
  const data = parseJSON(linkedTourStorage);
  if (isStateExpired(data === null || data === void 0 ? void 0 : data.expires)) {
    cleanupLinkedTour();
    return null;
  }
  return data;
};
export const getLinkedTourId = () => {
  var _getLinkedTourData;
  const tourId = (_getLinkedTourData = getLinkedTourData()) === null || _getLinkedTourData === void 0 ? void 0 : _getLinkedTourData.tourId;
  return (tourId === null || tourId === void 0 ? void 0 : tourId.split(':')[0]) || null;
};
export const getLinkedTourState = () => {
  const linkedTourData = getLinkedTourData();
  if (!linkedTourData) {
    cleanupLinkedTour();
    return null;
  }
  const {
      tourId: tourIdAlias
    } = linkedTourData,
    linkedTourState = _objectWithoutPropertiesLoose(linkedTourData, _excluded);
  const [tourId, targetPageRegex] = tourIdAlias.split(':');
  if (targetPageRegex && !document.location.pathname.match(targetPageRegex)) {
    // Not remove tour storage if current page is not matched target page
    return null;
  }

  /**
   * To reduce impact on next tour session storage,
   * minimize the storage lifecycle by removing it as soon as the next tour is loaded.
   * The tour data has been loaded into OnboardingTour state, so it's safe to remove.
   */
  cleanupLinkedTour();
  return Object.assign({
    tourId
  }, linkedTourState);
};