import { closeBanner, loadOnboardingTourBanner } from 'onboarding-tours/util/banner';
export default {
  closeHandholdingBanner: closeBanner,
  loadHandholdingBanner: (taskId, groupKey) => {
    loadOnboardingTourBanner({
      ignoreNavBar: true,
      taskId,
      groupKey
    });
  }
};