import { DataSyncOptionAppsConfigs } from '../../constants/dataSyncConstants';
import { GOSU004CopyPasteDataSyncOptions, GOSU004CopyPasteAppConfigs } from '../../constants/gosu004Constants';
import { prefetchDataImportPage } from './prefetchDataImportPage';
export const useGOSU004DataSyncOptions = () => {
  prefetchDataImportPage();
  // Add config to render data tile for Copy and Paste option
  Object.assign(DataSyncOptionAppsConfigs, GOSU004CopyPasteAppConfigs);
  return {
    isLoadingGOSU004: false,
    dataSyncOptions: GOSU004CopyPasteDataSyncOptions
  };
};