export function getTaskCompletion(tasks, tasksCompleted, tasksSkipped) {
  return tasks.reduce((progress, task) => {
    const taskKey = task.key;
    const isCompleted = tasksCompleted.includes(taskKey);
    const isSkipped = tasksSkipped.includes(taskKey);
    return {
      completedTasks: progress.completedTasks.concat(isCompleted ? task : []),
      skippedTasks: progress.skippedTasks.concat(isSkipped ? task : []),
      uncompletedTasks: progress.uncompletedTasks.concat(!isCompleted && !isSkipped ? task : [])
    };
  }, {
    completedTasks: [],
    skippedTasks: [],
    uncompletedTasks: []
  });
}
export function getTaskGroupCompletion(taskGroups, tasksCompleted, tasksSkipped) {
  return taskGroups.reduce(({
    completedTaskGroups,
    uncompletedTaskGroups
  }, taskGroup) => {
    const isCompletedGroup = getTaskCompletion(taskGroup.tasks, tasksCompleted, tasksSkipped).uncompletedTasks.length === 0;
    return {
      completedTaskGroups: isCompletedGroup ? [...completedTaskGroups, taskGroup] : completedTaskGroups,
      uncompletedTaskGroups: isCompletedGroup ? uncompletedTaskGroups : [...uncompletedTaskGroups, taskGroup]
    };
  }, {
    completedTaskGroups: [],
    uncompletedTaskGroups: []
  });
}
export function getTasksRemainingProgress({
  completedTasks,
  uncompletedTasks,
  skippedTasks
}) {
  const tasksCompleted = completedTasks.length + skippedTasks.length;
  const totalTasks = tasksCompleted + uncompletedTasks.length;
  const percentageComplete = Math.floor(tasksCompleted / totalTasks * 100);
  const remainingDuration = uncompletedTasks.reduce((duration, task) => duration + (task && task.durationInMinutes ? task.durationInMinutes : 0), 0);
  return {
    percentageComplete,
    remainingDuration,
    tasksCompleted,
    totalTasks
  };
}