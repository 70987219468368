import DismissTaskCardButton from '../components/card/tasks/DismissTaskCardButton';
import FallbackTaskCardButton from '../components/card/tasks/FallbackTaskCardButton';
import TabNavigationButton from '../components/card/tasks/TabNavigationButton';
export const TASK_CTA_COMPONENTS = {
  'completed-marketing-cta': TabNavigationButton,
  'completed-sales-cta': TabNavigationButton,
  'completed-service-cta': TabNavigationButton,
  'completed-cms-cta': TabNavigationButton,
  'completed-crm-essentials-marketing-cta': DismissTaskCardButton,
  'completed-crm-essentials-sales-cta': DismissTaskCardButton,
  'completed-crm-essentials-service-cta': DismissTaskCardButton,
  'completed-crm-essentials-cms-cta': DismissTaskCardButton
};
export const useCtaComponent = ctaId => {
  return TASK_CTA_COMPONENTS[ctaId] || FallbackTaskCardButton;
};