import http from 'hub-http/clients/apiClient';
import { getHubletUrl } from '../utils/urlUtils';
import { ONBOARDING_SETTINGS_ENROLLMENT_URL } from '../constants/apiUrls';
export function isEnrolledInUserGuide(options) {
  return http.get(getHubletUrl(ONBOARDING_SETTINGS_ENROLLMENT_URL, options)).then(({
    enrolledInUserGuide
  }) => enrolledInUserGuide);
}
export function changeEnrolledInUserGuide({
  isEnrolled,
  options
}) {
  const enrolledInUserGuideRequest = {
    enrolledInUserGuide: isEnrolled
  };
  return http.put(getHubletUrl(ONBOARDING_SETTINGS_ENROLLMENT_URL, options), {
    data: enrolledInUserGuideRequest
  });
}
export function deleteEnrolledInUserGuide(options) {
  return http.delete(getHubletUrl(ONBOARDING_SETTINGS_ENROLLMENT_URL, options));
}