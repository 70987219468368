import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { captureNetworkError } from 'growth-onboarding-reliability/utils/raven';
import { useHighestProductLevelEditions } from 'setup-guide-api/hooks/productLevels';
import { inferFreeOrStarterTierFromGroupKey } from '../utils/productLevels';
import { MANUAL_DETECTED_TASKS_PROGRESS_API, checkManualDetectedTaskProgress } from '../api/manualDetectedTaskApi';
import { fetchUserProgress } from '../api/taskApi';
import { completeTask as completeTaskAction, resetTaskErrorStatus, skipTask as skipTaskAction, undoSkipTask as undoSkipTaskAction } from '../actions/TaskActions';
const wrapCallback = callback => {
  callback().catch(captureNetworkError);
};
export const useTaskApi = () => {
  const dispatch = useDispatch();
  const checkAndCompleteTask = useCallback((taskKey, onComplete = wrapCallback) => {
    if (MANUAL_DETECTED_TASKS_PROGRESS_API[taskKey]) {
      return checkManualDetectedTaskProgress(taskKey).then(manualDetectedTaskProgress => {
        if (manualDetectedTaskProgress.completed) {
          onComplete(() => dispatch(completeTaskAction(taskKey)));
        }
      }).catch(captureNetworkError);
    }
    return fetchUserProgress().then(({
      completedTaskKeys = []
    }) => {
      if (completedTaskKeys.includes(taskKey)) {
        onComplete(() => dispatch(completeTaskAction(taskKey)));
      }
    }).catch(captureNetworkError);
  }, [dispatch]);
  const completeTask = useCallback(taskKey => dispatch(completeTaskAction(taskKey)), [dispatch]);
  const skipTask = useCallback(taskKey => dispatch(skipTaskAction(taskKey)), [dispatch]);
  const undoSkipTask = useCallback(taskKey => dispatch(undoSkipTaskAction(taskKey)), [dispatch]);
  return useMemo(() => ({
    checkAndCompleteTask,
    completeTask,
    skipTask,
    undoSkipTask
  }), [checkAndCompleteTask, completeTask, skipTask, undoSkipTask]);
};
export const useTaskLoading = () => {
  return useSelector(state => {
    return state.task.isFetching || state.taskProgress.isFetching;
  });
};
export const useHasTaskErrored = () => {
  return useSelector(state => {
    const taskLoadingError = !state.task.hasFetched && !state.task.isFetching;
    const taskProgressError = !state.taskProgress.hasFetched && !state.taskProgress.isFetching;
    return taskLoadingError || taskProgressError;
  });
};
export const useRawTaskData = () => {
  const {
    groupKey,
    taskGroups,
    taskMap
  } = useSelector(state => state.task);
  return {
    groupKey,
    taskGroups,
    taskMap
  };
};
export const useTaskErrorStatus = () => {
  const dispatch = useDispatch();
  const taskErrorStatus = useSelector(state => state.taskProgress.taskErrorStatus);
  const resetStatus = () => resetTaskErrorStatus(dispatch);
  return {
    taskErrorStatus,
    resetStatus
  };
};
export const useTaskProgress = () => {
  const taskProgress = useSelector(state => state.taskProgress);
  const taskGroups = useSelector(state => state.task.taskGroups);
  const {
    tasksCompleted: tasksCompletedRaw
  } = taskProgress;
  const {
    tasksCompleted,
    tasksSkipped
  } = taskProgress;
  const tasksUncompleted = useMemo(() => {
    if (!taskGroups) {
      return [];
    }
    const newTasksUncompleted = [];
    const flattenedTaskGroups = [...taskGroups, ...taskGroups.flatMap(({
      childGroups
    }) => {
      return childGroups.map(childGroup => Object.assign({}, childGroup, {
        taskKeys: childGroup.tasks.map(({
          key
        }) => key)
      }));
    })];
    flattenedTaskGroups.forEach(({
      taskKeys
    }) => newTasksUncompleted.push(...taskKeys.filter(taskKey => !tasksCompleted.includes(taskKey) && !tasksSkipped.includes(taskKey) && !newTasksUncompleted.includes(taskKey))));
    return newTasksUncompleted;
  }, [taskGroups, tasksCompleted, tasksSkipped]);
  return {
    tasksCompleted,
    tasksSkipped,
    tasksUncompleted,
    tasksCompletedRaw
  };
};
export const useTaskData = ({
  selectedView
} = {}) => {
  const isLoading = useTaskLoading();
  const hasErrored = useHasTaskErrored();
  const {
    groupKey,
    taskGroups,
    taskMap
  } = useRawTaskData();
  const taskProgress = useTaskProgress();
  const productEditions = useHighestProductLevelEditions();
  // TODO - remove this when useCase is removed from the OnboardingViews
  // https://git.hubteam.com/hubspot/growthonboardingplanningsetup/issues/3953
  const tier = selectedView ? productEditions[selectedView] : inferFreeOrStarterTierFromGroupKey(groupKey);
  return useMemo(() => ({
    isLoading,
    groupKey,
    taskGroups,
    taskProgress,
    taskMap,
    tier,
    hasErrored
  }), [isLoading, groupKey, taskGroups, taskProgress, taskMap, tier, hasErrored]);
};