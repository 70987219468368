import http from 'hub-http/clients/apiClient';
import { getHubletUrl } from '../utils/apiUtils';
const ONBOARDING_SETTINGS_URI = `onboarding-settings/v1/user-guide-enrollment`;
export function changeEnrollmentOnboardingSettings(isEnrolled, options = {}) {
  const {
    hubletOriginOverride
  } = options;
  return http.put(getHubletUrl(ONBOARDING_SETTINGS_URI, hubletOriginOverride), {
    data: {
      enrolledInUserGuide: isEnrolled
    }
  });
}