import { useContext, useEffect, useState } from 'react';
import Raven from 'raven-js';
import http from 'hub-http/clients/apiClient';
import { useIsPaidPortal } from 'onboarding-scopes';
import PortalLimitsContext from '../contexts/PortalContactsContext';
const FREE_PORTAL_CRM_CONTACTS_LIMIT = 1000;
const CONTACT_OBJECT_TYPE_ID = '0-1';
export const fetchPortalContactsCount = () => http.post('crm-search/search', {
  data: {
    objectTypeId: CONTACT_OBJECT_TYPE_ID
  }
});
export const usePortalContactsCount = () => {
  const [contactsCount, setContactsCount] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  useEffect(() => {
    setIsLoading(true);
    setHasError(false);
    fetchPortalContactsCount().then(({
      total
    }) => {
      setIsLoading(false);
      setContactsCount(total);
    }).catch(error => {
      setContactsCount(null);
      setIsLoading(false);
      setHasError(true);
      error.message = 'Error fetching contacts count';
      Raven.captureException(error);
    });
  }, []);
  return {
    contactsCount,
    hasError,
    isLoading
  };
};
export const usePortalContactsState = () => {
  return useContext(PortalLimitsContext);
};
export const usePortalContactsLimit = () => {
  const portalLimitsState = usePortalContactsState();
  const isPaidPortal = useIsPaidPortal();
  if (
  // This limit doesn't apply to paid portals
  isPaidPortal || !portalLimitsState || portalLimitsState.isLoading || portalLimitsState.hasError || portalLimitsState.contactsCount === null) {
    return {
      numberOfContacts: undefined,
      contactsLimit: undefined,
      isWithinLimit: true
    };
  }
  return {
    numberOfContacts: portalLimitsState.contactsCount,
    contactsLimit: FREE_PORTAL_CRM_CONTACTS_LIMIT,
    isWithinLimit: portalLimitsState.contactsCount < FREE_PORTAL_CRM_CONTACTS_LIMIT
  };
};