'use es6';

import { useContext } from 'react';
import { FeedbackLoaderContext } from 'feedback-loader/FeedbackLoaderProvider';
export const useFeedbackLoader = () => {
  const context = useContext(FeedbackLoaderContext);
  if (context === undefined) {
    throw new Error('useFeedbackLoader must be used within a FeedbackLoader Provider');
  }
  return context;
};