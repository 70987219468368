import { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { parse, stringify } from 'hub-http/helpers/params';
export const useQueryParams = (search = window.location.search) => {
  return useMemo(() => {
    const queryString = search.slice(1);
    return parse(queryString);
  }, [search]);
};
export const useAddQueryParams = () => {
  const history = useHistory();
  const queryParams = useQueryParams();
  return useCallback((addedParams = {}) => {
    history.replace({
      pathname: history.location.pathname,
      search: stringify(Object.assign({}, queryParams, addedParams))
    });
  }, [history, queryParams]);
};
export const useRemoveQueryParams = () => {
  const history = useHistory();
  const queryParams = useQueryParams();
  return useCallback(paramsToRemove => {
    for (const paramName of paramsToRemove) {
      delete queryParams[paramName];
    }
    history.replace({
      pathname: history.location.pathname,
      search: stringify(queryParams)
    });
  }, [history, queryParams]);
};