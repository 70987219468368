import { isInOnboardingSession, delayUntilIdle, insertOnboardingToursBanner, hasNavBar } from './bannerFrame';
export const BANNER_TYPES = {
  TASK: 'task'
};
export const getBannerContainer = () => {
  return document.getElementById('onboarding-tours-banner');
};
export const getBannerFrame = () => {
  return document.getElementById('onboarding-tours-banner-frame');
};
export const getOnboardingGuide = () => {
  try {
    const onboardingGuideValue = window.sessionStorage.getItem('ONBOARDING_TOURS_GUIDE');
    if (!onboardingGuideValue) {
      return null;
    }
    return JSON.parse(onboardingGuideValue);
  } catch (e) {
    return null;
  }
};
export const postOnboardingGuide = config => {
  const iframe = getBannerFrame();
  if (!iframe || !iframe.contentWindow) {
    return;
  }
  iframe.contentWindow.postMessage({
    key: 'ONBOARDING_TOURS_GUIDE',
    value: config || null
  }, '*');
};
export const enableBanner = config => {
  postOnboardingGuide(config);
};
export const closeBanner = () => {
  postOnboardingGuide(null);
};
export const isBannerEnabled = () => {
  return document.querySelector('.onboarding-tours-banner') !== null;
};
export const openTaskCompletionModal = taskKey => {
  const config = getOnboardingGuide() || {};
  if (config && config.type === BANNER_TYPES.TASK && config.key === taskKey) {
    postOnboardingGuide(Object.assign({}, config, {
      completed: true
    }));
  }
};
export const closeTaskCompletionModal = taskKey => {
  const config = getOnboardingGuide() || {};
  if (config && config.type === BANNER_TYPES.TASK && config.key === taskKey) {
    postOnboardingGuide(Object.assign({}, config, {
      completed: false
    }));
  }
};
export const closeOnboardingTour = (tourWindow = window, origin = '*') => {
  tourWindow.postMessage({
    key: 'ONBOARDING_TOURS',
    action: 'close'
  }, origin);
};
export function loadOnboardingTourBanner(options = {}) {
  const {
    ignoreNavBar,
    taskId,
    groupKey
  } = options;
  if (taskId || isInOnboardingSession()) {
    delayUntilIdle(() => {
      if (ignoreNavBar === true || !hasNavBar()) {
        insertOnboardingToursBanner(taskId, groupKey);
      }
    });
  }
}