module.exports = {
  "/": {
    "success": {
      "view only seat screen": [
        "VIEW_ONLY_SEAT_ERROR_SCREEN"
      ],
      "invited user access screen": [
        "INVITED_USER_ACCESS_ERROR_SCREEN"
      ],
      "user guide overview": [
        "APP_LOAD",
        "USER_GUIDE_OVERVIEW_LOADED",
        "USER_GUIDE_TASK_GROUPS_LOAD_SUCCESS"
      ]
    },
    "error": [
      "APP_LOAD_ERROR",
      "USER_GUIDE_LOAD_ERROR",
      "USER_GUIDE_TASK_GROUPS_LOAD_ERROR"
    ]
  },
  "/quick-invite": {
    "name": "quickInvite",
    "success": {
      "quick invite modal shown": [
        "APP_LOAD",
        "QUICK_INVITE_MODAL_SHOWN"
      ]
    },
    "error": [
      "APP_LOAD_ERROR",
      "USER_GUIDE_LOAD_ERROR",
      "USER_GUIDE_TASK_GROUPS_LOAD_ERROR"
    ]
  },
  "/data-modal": {
    "name": "dataSyncModal",
    "success": {
      "data sync modal shown": [
        "APP_LOAD",
        "DATA_SYNC_MODAL_SHOWN"
      ]
    },
    "error": [
      "APP_LOAD_ERROR",
      "USER_GUIDE_LOAD_ERROR",
      "USER_GUIDE_TASK_GROUPS_LOAD_ERROR"
    ]
  },
  "/change-homepage": {
    "name": "changeHomepage",
    "success": {
      "change homepage modal shown": [
        "CHANGE_HOMEPAGE_MODAL_SHOWN"
      ]
    },
    "error": [
      "APP_LOAD_ERROR",
      "USER_GUIDE_LOAD_ERROR",
      "USER_GUIDE_TASK_GROUPS_LOAD_ERROR"
    ]
  },
  "/:onboardingKey": {
    "name": "DeprecatedOnboardingKeyRoute"
  }
};