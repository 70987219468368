const primaryTrackers = new Map();
export const getPrimaryTracker = (trackerName = 'default') => {
  if (!primaryTrackers.has(trackerName)) {
    throw new Error('usage-tracker-container error. "getPrimaryTracker" was be called before "setPrimaryTracker".');
  }
  return primaryTrackers.get(trackerName);
};
export const setPrimaryTracker = (tracker, trackerName = 'default') => {
  if (primaryTrackers.has(trackerName)) {
    throw new Error('usage-tracker-container error. Primary tracker has already been set. You can only set one primary tracker per runtime.');
  }
  primaryTrackers.set(trackerName, tracker);
};