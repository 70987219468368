import { useIsFreePortal, useHasCrmSuiteStarter } from 'onboarding-scopes';
import { useHasAnyScope } from '../../../hooks/auth';
import { useShouldSeeProTrialsBanner } from '../../../hooks/trials/useShouldSeeProTrialsBanner';
import { useSelectedView } from '../../../hooks/config';
import { ONBOARDING_VIEWS } from 'onboarding-settings-client/constants/onboardingViews';
import { useIsGPRO0002 } from '../../../hooks/growthProPlus/gpro0002';
export const BILLING_READ_SCOPES = ['billing-read', 'billing-read-restricted'];
export const useNavFooterItems = () => {
  // Invite Link Item
  const showInviteLink = useHasAnyScope(['users-write']);

  // Product Item
  const hasBillingPermissions = useHasAnyScope(BILLING_READ_SCOPES);
  const {
    isLoading: isLoadingTrials,
    shouldSeeProTrialsBanner
  } = useShouldSeeProTrialsBanner();
  const shouldSeeProductLink = hasBillingPermissions && !isLoadingTrials;

  // Demo Link Item
  const selectedView = useSelectedView();
  const isFreePortal = useIsFreePortal();
  const hasCrmSuiteStarter = useHasCrmSuiteStarter();
  const viewsWithDemoLink = [ONBOARDING_VIEWS.CMS, ONBOARDING_VIEWS.MARKETING, ONBOARDING_VIEWS.SALES, ONBOARDING_VIEWS.SERVICE];
  const showDemoLink = Boolean((isFreePortal || hasCrmSuiteStarter) && selectedView && viewsWithDemoLink.includes(selectedView));

  // GPRO0002 Assigned Tasks Item
  const showAssignedTasksLink = useIsGPRO0002();
  return {
    shouldSeeNavFooter: showInviteLink || shouldSeeProductLink || showDemoLink || showAssignedTasksLink,
    showInviteLink,
    hasBillingPermissions,
    showTrialPickerLink: shouldSeeProTrialsBanner,
    isLoadingTrials,
    showDemoLink,
    showAssignedTasksLink
  };
};