import { useCallback, useEffect, useState } from 'react';
import { useHasAnyScope } from './auth';
import { deleteSuperstoreValue, getSuperstoreValue, setSuperstoreValue, reportSuperstoreError } from '../utils/superstore';
const BANNER_CLOSE_TIME_KEY = 'InviteReminderBannerCloseTime';
const hasBannerStorageExpired = bannerCloseTime => {
  const oneDayInMillis = 1000 * 60 * 60 * 24;
  return Date.now() - bannerCloseTime > oneDayInMillis;
};
export const useInviteReminderBannerState = () => {
  const [shouldShowBanner, setShouldShowBanner] = useState(false);
  const hasUserWriteScope = useHasAnyScope(['users-write']);
  useEffect(() => {
    if (hasUserWriteScope) {
      getSuperstoreValue(BANNER_CLOSE_TIME_KEY).then(bannerCloseTime => {
        // Ignore banner close state 1 day later for showing it again if there are still pending invites.
        if (!bannerCloseTime) {
          setShouldShowBanner(true);
        } else if (hasBannerStorageExpired(bannerCloseTime)) {
          setShouldShowBanner(true);
          deleteSuperstoreValue(BANNER_CLOSE_TIME_KEY).catch(error => reportSuperstoreError(BANNER_CLOSE_TIME_KEY, 'delete', error));
        }
      }).catch(error => {
        reportSuperstoreError(BANNER_CLOSE_TIME_KEY, 'get', error);
      });
    }
  }, [hasUserWriteScope]);
  return [shouldShowBanner, useCallback(newShouldShowBanner => {
    setShouldShowBanner(newShouldShowBanner);
    if (newShouldShowBanner === false) {
      setSuperstoreValue(BANNER_CLOSE_TIME_KEY, Date.now()).catch(error => {
        reportSuperstoreError(BANNER_CLOSE_TIME_KEY, 'set', error);
      });
    }
  }, [])];
};