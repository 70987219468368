import Loadable from 'UIComponents/decorators/Loadable';
const DELAY_TIMEOUT = 5000;
const SELENIUM_DISABLE_TRIAL_MODAL_KEY = 'selenium.disable.trial-modal';
const _delayUntilIdle = () => {
  return new Promise(resolve => {
    if (window.requestIdleCallback) {
      return window.requestIdleCallback(resolve, {
        timeout: DELAY_TIMEOUT
      });
    }
    return window.setTimeout(resolve, 0);
  });
};
const loadTrialModalContainer = () => _delayUntilIdle().then(() => import( /* webpackChunkName: "GrowthDashboardModalContainer" */'./GrowthDashboardModalContainerWrapper'));
const isTrialModalDisabled = () => {
  try {
    return window.localStorage.getItem(SELENIUM_DISABLE_TRIAL_MODAL_KEY) === 'true';
  } catch (error) {
    return false;
  }
};
export const AsyncGrowthDashboardModalContainer = isTrialModalDisabled() ? () => null : Loadable({
  loader: loadTrialModalContainer,
  LoadingComponent: () => null
});