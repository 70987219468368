import { useCallback } from 'react';
import http from 'hub-http/clients/apiClient';
import PortalIdParser from 'PortalIdParser';
import { DATA_SYNC_APP_IDS } from '../constants/dataSyncConstants';
const PORTAL_ID = PortalIdParser.get();
const CONTACT_OBJECT_TYPE = '0-1';
const createConnection = ({
  accountId,
  appId
}) => {
  return http.post(`sync-bridge/v3/accounts/${accountId}/connection`, {
    query: {
      portalId: PORTAL_ID,
      appId
    },
    data: {
      objectTypes: {
        hubSpotObjectTypeId: CONTACT_OBJECT_TYPE,
        remote: 'contacts'
      }
    }
  });
};

// Save the connection with the appropriate settings:
// No filters
// One-way sync to HubSpot (INBOUND)
const saveConnection = ({
  appId,
  connectionId
}) => {
  return http.put(`sync-bridge/v3/connections/${connectionId}`, {
    query: {
      portalId: PORTAL_ID,
      appId
    },
    data: {
      conflictResolution: 'DEFAULT_TO_HUBSPOT',
      rules: {
        direction: 'INBOUND',
        filters: {},
        updateOnlyDirection: 'NONE'
      },
      matchingFilter: {
        name: 'email_only',
        value: true
      },
      defaultValueDatasetParameters: []
    }
  });
};

// Enable sync for the connection
const activateSync = ({
  appId,
  connectionId
}) => {
  return http.post(`sync-bridge/v3/connections/${connectionId}/activate`, {
    query: {
      portalId: PORTAL_ID,
      appId
    }
  });
};
export const useOneStepDataSync = ({
  app,
  onComplete
}) => {
  const appId = DATA_SYNC_APP_IDS[app];
  return useCallback(async accountId => {
    // This is a sign of misconfiguration
    if (appId === undefined) {
      throw new Error(`Data Sync app ID not found for ${app}`);
    }
    const {
      id: connectionId
    } = await createConnection({
      accountId,
      appId
    });
    await saveConnection({
      appId,
      connectionId
    });
    await activateSync({
      appId,
      connectionId
    });
    onComplete();
  }, [appId, app, onComplete]);
};