/**
 * This code is inspired by {@link https://git.hubteam.com/HubSpot/payments-enrollment-embed/blob/master/payments-enrollment-embed-lib/static/js/utils/window.ts}
 */
export const waitFor = (callback, timeout = 100, maxTries = 10) => {
  let tries = 0;
  const wait = (resolve, reject) => {
    if (tries >= maxTries) {
      reject();
    } else if (callback()) {
      resolve();
    } else {
      tries++;
      setTimeout(wait.bind(this, resolve, reject), timeout);
    }
  };
  return new Promise(wait);
};