import Raven from 'raven-js';
const ELEMENT_LOAD_TIMEOUT = 'Element load timed out';
const LOAD_TOUR_ERROR = 'Error loading tour';
const ONBOARDING_TOUR_BUTTON_ERROR = 'OnboardingTourButton error';
const SentryManager = {
  getError(name, message) {
    const error = new Error(message);
    error.name = name;
    return error;
  },
  reportElementLoadTimeout(tourId, stepId) {
    return this.reportSentry(ELEMENT_LOAD_TIMEOUT, `${ELEMENT_LOAD_TIMEOUT}: tour id: ${tourId} step id: ${stepId}`, {
      tourId,
      stepId
    });
  },
  reportLoadTourError(error, tourId) {
    return this.reportSentry(LOAD_TOUR_ERROR, `${tourId}: failed at ${error.message} when attempting to load tour with id ${tourId}`, {
      tourId
    });
  },
  reportOnboardingTourButtonButtonError(error, tourId, stepId) {
    return this.reportSentry(ONBOARDING_TOUR_BUTTON_ERROR, `${tourId}: error on step ${stepId}. Error: ${error.message}`, {
      tourId,
      stepId
    });
  },
  reportSentry(name, message, extra) {
    const sentryError = this.getError(name, message);
    const sentryErrorOptions = {
      extra
    };
    Raven.captureException(sentryError, sentryErrorOptions);
    return [sentryError, sentryErrorOptions];
  }
};
export default SentryManager;