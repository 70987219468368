/**
 * Returns a window with 1 of two conditions
 * 1. It has a copilot channel created on it
 * 2. It is the highest level same-domain window (i.e. the highest level window
 * with the (app.hubspot.com) domain. This is the default highest layer for a
 * copilot channel.
 * @param win The window to start looking for the nearest Copilot Layer
 * @returns The highest level window in a copilot channel layer.
 */
export const findNearestWindowWithCopilotChannel = win => {
  let top = win;
  if (win.copilotChannel) {
    return win;
  }
  while (win.parent !== win) {
    try {
      // Will throw when the parent window is from a different domain
      const __sameOriginTest = win.parent.document;
      if (win.parent.copilotChannel) {
        return win.parent;
      }
      top = win.parent;
    } catch (e) {
      // Skip different origin frame
    }
    win = win.parent;
  }
  return top;
};