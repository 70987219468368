import http from 'hub-http/clients/apiClient';
import { getUrl } from '../utils/urlUtils';
const ONBOARDING_SETTINGS_PATH = `onboarding-settings/v1/settings`;
const getUserInfoQuery = (userId, portalId) => {
  const query = {};
  if (userId) {
    query.userId = userId;
  }
  if (portalId) {
    query.portalId = portalId;
  }
  return query;
};
export function fetchOnboardingSettings(userId, portalId) {
  const query = getUserInfoQuery(userId, portalId);
  return http.get(ONBOARDING_SETTINGS_PATH, {
    query
  });
}
export function patchOnboardingSettings(onboardingSettings, userId, portalId, options) {
  const query = getUserInfoQuery(userId, portalId);
  const onboardingSettingsRequestUrl = getUrl(ONBOARDING_SETTINGS_PATH, options);
  return http.patch(onboardingSettingsRequestUrl, {
    query,
    data: onboardingSettings
  });
}
export function removeOnboardingSettings(view, key) {
  let removeSettingsUrl = `${ONBOARDING_SETTINGS_PATH}/${view}/${key}`;
  if (!key) {
    key = view;
    removeSettingsUrl = `${ONBOARDING_SETTINGS_PATH}/${key}`;
  }
  return http.delete(removeSettingsUrl);
}