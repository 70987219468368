'use es6';

import enviro from 'enviro';
import { isHubSpotter, isForceOpen } from './utils';

// powered by https://git.hubteam.com/HubSpot/hs-selenium, see configure-local-storage.ts in hubspot-selenium-js
const isAcceptanceTest = () => {
  try {
    return window.localStorage.getItem('selenium.disable.feedback-surveys') === 'true';
  } catch (e) {
    console.log(e);
    return false;
  }
};
export const earlyCheck = () => {
  if (isForceOpen()) {
    return true;
  }
  if (isAcceptanceTest()) {
    return false;
  }
  if (enviro.getShort() === 'qa') {
    return false;
  }
  return true;
};
export const fullCheck = userData => {
  const {
    userEmail = ''
  } = userData || {};
  const isProd = enviro.getShort() === 'prod';
  if (isForceOpen()) {
    return true;
  }
  if (isAcceptanceTest()) {
    return false;
  }
  if (isProd) {
    if (isHubSpotter(userEmail)) {
      return false;
    }
    return true;
  }
  return false;
};