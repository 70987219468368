'use es6';

const availableTrialsAdapter = availableTrials => {
  return availableTrials.map(trial => {
    const firstTrialParcel = trial.parcels && trial.parcels.length ? trial.parcels[0] : null;
    return {
      name: trial.name,
      trialId: trial.id,
      apiName: firstTrialParcel && firstTrialParcel.productApiName || null
    };
  });
};
export default availableTrialsAdapter;