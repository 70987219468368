module.exports = {
  "pageView": {
    "name": "pageview",
    "class": "view",
    "properties": {
      "screen": {
        "type": "string"
      },
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "app": {
        "type": "string"
      }
    },
    "namespace": "onboarding-data-modal",
    "version": "3"
  },
  "userInteraction": {
    "name": "user interaction",
    "class": "interaction",
    "properties": {
      "action": {
        "type": "string"
      },
      "value": {
        "type": "string",
        "isOptional": true
      },
      "app": {
        "type": "string"
      }
    },
    "namespace": "onboarding-data-modal",
    "version": "3"
  }
};