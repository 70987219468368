import usePrevious from 'react-utils/hooks/usePrevious';
import { useDismissedTaskCards } from 'setup-guide-components/contexts/TaskCardsContext';
import { useTaskData } from './task';
export const useTaskViews = showDismissed => {
  const {
    taskGroups: taskViews = []
  } = useTaskData();
  const viewKeys = taskViews.map(({
    key
  }) => key);
  const dismissedTaskCards = useDismissedTaskCards();
  const filteredViewKeys = viewKeys.filter(viewKey => dismissedTaskCards.includes(viewKey) === showDismissed);
  const previousViewKeys = usePrevious(filteredViewKeys) || [];
  const recentlyRemovedViewKeys = previousViewKeys.filter(viewKey => !filteredViewKeys.includes(viewKey));
  const viewKeysToRender = recentlyRemovedViewKeys.length === 0 ? filteredViewKeys : previousViewKeys;
  return viewKeysToRender.map(viewKey => ({
    viewKey,
    isRecentlyRemoved: recentlyRemovedViewKeys.includes(viewKey)
  }));
};