import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ONBOARDING_DEFAULT } from 'getting-started-shared-tasks/onboardingKeys';
import { useRemoveQueryParams } from 'setup-guide-components/hooks/history';
import { ONBOARDING_VIEWS } from 'onboarding-settings-client/constants/onboardingViews';
import { switchView } from '../actions/AppActions';
import { VIEWS_DEFAULT_ORDER } from '../constants/viewsConstants';
import { applyOnboardingSettingsOverrides } from '../utils/onboardingSettingsUtils';
import { getQueryParams } from '../utils/urls';
import { SUITE_OVERVIEW_ONBOARDING_KEYS } from 'setup-guide-api/constants/suiteOverviewConstants';
import { Metrics } from '../utils/metrics';
import { captureNetworkError } from 'growth-onboarding-reliability/utils/raven';
import { receiveOnboardingKey } from '../actions/OnboardingActions';
import { removeSettings } from 'onboarding-settings-client/api/settings';
// Maps local view names to the name used on the backend
const VIEWS_MAP = {
  [ONBOARDING_VIEWS.MARKETING]: 'MARKETING',
  [ONBOARDING_VIEWS.SALES]: 'SALES',
  [ONBOARDING_VIEWS.SERVICE]: 'SERVICE',
  [ONBOARDING_VIEWS.OPS]: 'OPS',
  [ONBOARDING_VIEWS.CMS]: 'CMS',
  [ONBOARDING_VIEWS.COMMERCE]: 'COMMERCE',
  [ONBOARDING_VIEWS.SUITE]: 'SUITE',
  [ONBOARDING_VIEWS.BUSINESS_BOX]: 'BUSINESS_BOX',
  [ONBOARDING_VIEWS.USE_CASE]: 'USE_CASE'
};
const parseActiveOnboardingKey = onboardingSettings => {
  const {
    selectedView,
    views
  } = onboardingSettings || {};
  if (selectedView && views) {
    const activeView = views[selectedView];
    if (activeView) {
      const {
        onboardingKey = ONBOARDING_DEFAULT
      } = activeView;
      return onboardingKey;
    }
  }
  return ONBOARDING_DEFAULT;
};
export const useMaybeRemoveView = ({
  view
}) => {
  const removeQueryParams = useRemoveQueryParams();
  const dispatch = useDispatch();
  return useCallback(onboardingSettings => {
    if (!onboardingSettings) {
      return onboardingSettings;
    }

    // Attempt to remove view from the user's setting
    let settings = (() => {
      const {
        views
      } = onboardingSettings;
      if (!views || !views[view]) {
        return onboardingSettings;
      }
      const newViews = Object.assign({}, views);
      delete newViews[view];

      // Remove the view from the user's settings in the background
      // TODO: switch this to use the dedicated removeOnboardingView endpoint when it's available
      removeSettings({
        view: 'view',
        key: VIEWS_MAP[view]
      }).catch(captureNetworkError);
      Metrics.counter('remove-view-from-onboarding-settings', {
        view
      }).increment();
      return Object.assign({}, onboardingSettings, {
        views: newViews
      });
    })();

    // Attempt to remove the view from the user's selected view
    settings = (() => {
      const {
        selectedView
      } = settings;
      if (!selectedView || selectedView !== view) {
        return settings;
      }

      // Intentionally duplicated from useSetSelectedView, as we can't use useSetSelectedView here (it would create circular dependencies)
      removeQueryParams(['groupKey', 'onboardingKey', 'activeTab', 'activeView']);
      const salesSettings = settings.views && settings.views[ONBOARDING_VIEWS.SALES];
      const userSalesGroupKey = salesSettings && salesSettings.groupKey;
      dispatch(switchView(userSalesGroupKey, ONBOARDING_VIEWS.SALES));
      Metrics.counter('switch-selected-view', {
        from: view,
        to: ONBOARDING_VIEWS.SALES
      }).increment();
      return Object.assign({}, settings, {
        selectedView: ONBOARDING_VIEWS.SALES
      });
    })();
    return settings;
  }, [dispatch, removeQueryParams, view]);
};
export const useOnboardingSettings = () => {
  const dispatch = useDispatch();
  const onboardingSettings = useSelector(state => state.onboarding.onboardingSettings);
  const maybeRemoveSuiteOverview = useMaybeRemoveView({
    view: ONBOARDING_VIEWS.SUITE
  });
  const maybeRemoveUseCase = useMaybeRemoveView({
    view: ONBOARDING_VIEWS.USE_CASE
  });
  const maybeRemoveOps = useMaybeRemoveView({
    view: ONBOARDING_VIEWS.OPS
  });
  if (!onboardingSettings) {
    return null;
  }
  const {
    onboardingKey,
    activeTab,
    activeView = activeTab
  } = getQueryParams();
  let settingsToReturn = onboardingSettings;
  settingsToReturn = maybeRemoveSuiteOverview(settingsToReturn);
  settingsToReturn = maybeRemoveUseCase(settingsToReturn);
  settingsToReturn = maybeRemoveOps(settingsToReturn);

  // If the settings have changed, persist them in the store
  if (settingsToReturn && settingsToReturn !== onboardingSettings) {
    dispatch(receiveOnboardingKey(settingsToReturn));
  }
  if (onboardingKey || activeView) {
    settingsToReturn = applyOnboardingSettingsOverrides(onboardingSettings, activeView, onboardingKey);
  }
  return settingsToReturn;
};
export const useOnboardingSettingsError = () => {
  return useSelector(state => state.onboarding.onboardingSettingsError);
};
export const useOnboardingKey = () => {
  const onboardingSettings = useOnboardingSettings();
  const onboardingKeyFromSettings = parseActiveOnboardingKey(onboardingSettings);
  return onboardingKeyFromSettings;
};
export const useAllOnboardingKeys = () => {
  const {
    views = {}
  } = useOnboardingSettings() || {};
  return Object.values(views || {}).reduce((allOnboardingKeys, {
    onboardingKey
  }) => {
    return onboardingKey ? /* eslint-disable-next-line hubspot-dev/no-reduce-accumulator-copy */
    allOnboardingKeys.concat(onboardingKey) : allOnboardingKeys;
  }, []);
};
export const useIsSuiteOverviewFreeOnboardingKey = () => {
  const onboardingKey = useOnboardingKey();
  return onboardingKey === SUITE_OVERVIEW_ONBOARDING_KEYS.FREE;
};
export const useViewsOrder = views => {
  return useMemo(() => VIEWS_DEFAULT_ORDER.filter(name => views && views[name]), [views]);
};
export const useViews = () => {
  const onboardingSettings = useOnboardingSettings();
  if (!onboardingSettings || !onboardingSettings.selectedView) {
    return null;
  }
  const {
    selectedView,
    views = {}
  } = onboardingSettings;
  if (!views || Object.keys(views).length <= 1 || !views[selectedView]) {
    return null;
  }
  return {
    views,
    selectedView
  };
};
export const useTabViews = () => {
  const viewSettings = useViews();
  if (!viewSettings) {
    return viewSettings;
  }

  // Remove the Ops tab for all users
  if (Object.keys(viewSettings.views).length > 1 && viewSettings.views && viewSettings.views[ONBOARDING_VIEWS.OPS]) {
    const filteredViews = Object.assign({}, viewSettings.views);
    delete filteredViews[ONBOARDING_VIEWS.OPS];
    return {
      views: filteredViews,
      selectedView: viewSettings.selectedView === ONBOARDING_VIEWS.OPS ? ONBOARDING_VIEWS.MARKETING : viewSettings.selectedView
    };
  }
  return viewSettings;
};
export const useSelectedView = () => {
  const onboardingSettings = useOnboardingSettings();
  return onboardingSettings ? onboardingSettings.selectedView : undefined;
};
export const useSelectedViewSettings = () => {
  const {
    selectedView,
    views
  } = useOnboardingSettings() || {};
  const selectedViewSetting = selectedView && views && views[selectedView];
  return selectedViewSetting;
};
export const useSetSelectedView = () => {
  const dispatch = useDispatch();
  const selectedView = useSelectedView();
  const removeQueryParams = useRemoveQueryParams();
  const switchedView = useRef();
  const setSelectedView = useCallback((viewGroupKey, hubType) => {
    if (switchedView.current === hubType) {
      return;
    }
    removeQueryParams(['groupKey', 'onboardingKey', 'activeTab', 'activeView']);
    if (hubType !== selectedView) {
      switchedView.current = hubType;
      dispatch(switchView(viewGroupKey, hubType));
    }
  }, [selectedView, removeQueryParams, dispatch]);
  useEffect(() => {
    // sync switchedView if view is changed from other components
    switchedView.current = selectedView;
  }, [selectedView]);
  return setSelectedView;
};
export const useSetSelectedTabView = () => {
  const setSelectedView = useSetSelectedView();
  const {
    views = {}
  } = useTabViews() || {};
  return targetHub => {
    const {
      groupKey
    } = views[targetHub] || {};
    setSelectedView(groupKey, targetHub);
  };
};