import { registerQuery } from 'data-fetching-client';
import { recentPostPurchase } from '../api/recentPostPurchaseApi';
export const QUERY_RECENT_POST_PURCHASE = registerQuery({
  fieldName: 'recentPostPurchase',
  args: ['portalId'],
  fetcher({
    portalId
  }) {
    return recentPostPurchase(portalId);
  }
});