import { ONBOARDING_STARTER_SCOPES, ONBOARDING_PRO_SCOPES, ONBOARDING_ENTERPRISE_SCOPES, PRO_PLUS_ONBOARDING_SCOPES } from '../scopeConstants';
import { includesAll, excludesAll } from './arrayUtils';
export const getHasCrmSuiteStarter = scopes => {
  return includesAll(scopes, ONBOARDING_STARTER_SCOPES) && excludesAll(scopes, PRO_PLUS_ONBOARDING_SCOPES);
};
export const getHasCrmSuitePro = scopes => {
  return includesAll(scopes, ONBOARDING_PRO_SCOPES) && excludesAll(scopes, ONBOARDING_ENTERPRISE_SCOPES);
};
export const getHasCrmSuiteEnterprise = scopes => {
  return includesAll(scopes, ONBOARDING_ENTERPRISE_SCOPES);
};