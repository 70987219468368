import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
const _excluded = ["data"];
import { registerQuery, useQuery } from 'data-fetching-client';
import { getLatestInvites } from '../../api/inviteApi';
export const LATEST_INVITES_QUERY = registerQuery({
  fieldName: 'latestInvites',
  fetcher: () => getLatestInvites().then(res => Object.values(res.invitesPerUser))
});
export const useLatestInvites = options => {
  const _useQuery = useQuery(LATEST_INVITES_QUERY, options),
    {
      data
    } = _useQuery,
    rest = _objectWithoutPropertiesLoose(_useQuery, _excluded);
  if (!data) {
    return Object.assign({
      data
    }, rest);
  }
  return Object.assign({
    data: data.latestInvites
  }, rest);
};