import Raven from 'raven-js';
// @ts-expect-error ts-migrate(7016)
import { setupCes } from 'wootric-nps/ces/ces-setup';
// @ts-expect-error ts-migrate(7016)
import { setupNps } from 'wootric-nps/nps/nps-setup';
const SELENIUM_DISABLE_WOOTRIC = {
  CES: 'selenium.disable.wootric.ces',
  NPS: 'selenium.disable.wootric.nps'
};
function isWootricSurveyDisabled(surveyType) {
  try {
    const seleniumKey = SELENIUM_DISABLE_WOOTRIC[surveyType];
    return window.localStorage.getItem(seleniumKey) === 'true';
  } catch (err) {
    return false;
  }
}
export const setupWootricSurvey = (type, surveyKey) => {
  try {
    if (!isWootricSurveyDisabled(type)) {
      if (type === 'CES') {
        setupCes(surveyKey);
      } else if (type === 'NPS') {
        setupNps();
      }
    }
  } catch (error) {
    Raven.captureException(error, {
      extra: {
        from: `setupWootricSurvey:${type}`
      }
    });
  }
};