import { SALES_PROFESSIONAL, SALES_ENTERPRISE, MARKETING_PRO, MARKETING_ENTERPRISE, SERVICE_PROFESSIONAL, SERVICE_ENTERPRISE, CMS_ENTERPRISE, CMS_PROFESSIONAL, OPERATIONS_PROFESSIONAL, OPERATIONS_ENTERPRISE, MARKETING_PLUS_PROFESSIONAL, MARKETING_PLUS_ENTERPRISE } from './UpgradeProducts';
import enviro from 'enviro';
import { CMS_HUB_ENTERPRISE, CMS_HUB_PROFESSIONAL, MARKETING_HUB_ENTERPRISE, MARKETING_HUB_PROFESSIONAL, OPERATIONS_HUB_PROFESSIONAL, OPERATIONS_HUB_ENTERPRISE, SALES_HUB_ENTERPRISE, SALES_HUB_PROFESSIONAL, SERVICE_HUB_ENTERPRISE, SERVICE_HUB_PROFESSIONAL, MARKETING_PLUS_PROFESSIONAL_BUNDLE, MARKETING_PLUS_ENTERPRISE_BUNDLE } from './ApiNames';
export const SALES_ENTERPRISE_TRIAL_ID = 1;
export const MARKETING_ENTERPRISE_TRIAL_ID = 2;
export const SERVICE_ENTERPRISE_TRIAL_ID = 3;
export const MARKETING_PRO_TRIAL_ID = 4;
export const CMS_PROFESSIONAL_TRIAL_ID = 7;
export const SALES_PROFESSIONAL_TRIAL_ID = 8;
export const SERVICE_PROFESSIONAL_TRIAL_ID = 9;
export const CMS_ENTERPRISE_TRIAL_ID = enviro.isProd() ? 18 : 17;
export const OPERATIONS_PROFESSIONAL_TRIAL_ID = enviro.isProd() ? 35096 : 43098;
export const OPERATIONS_ENTERPRISE_TRIAL_ID = enviro.isProd() ? 52399 : 70192;
export const MARKETING_PLUS_PROFESSIONAL_BUNDLE_TRIAL_ID = enviro.isProd() ? 200098 : 270355;
export const MARKETING_PLUS_ENTERPRISE_BUNDLE_TRIAL_ID = enviro.isProd() ? 200097 : 270725;
export const validTrialIds = [SALES_ENTERPRISE_TRIAL_ID, MARKETING_ENTERPRISE_TRIAL_ID, SERVICE_ENTERPRISE_TRIAL_ID, MARKETING_PRO_TRIAL_ID, CMS_PROFESSIONAL_TRIAL_ID, SALES_PROFESSIONAL_TRIAL_ID, SERVICE_PROFESSIONAL_TRIAL_ID, CMS_ENTERPRISE_TRIAL_ID, OPERATIONS_PROFESSIONAL_TRIAL_ID, OPERATIONS_ENTERPRISE_TRIAL_ID, MARKETING_PLUS_PROFESSIONAL_BUNDLE_TRIAL_ID, MARKETING_PLUS_ENTERPRISE_BUNDLE_TRIAL_ID];
export const trialIdsWithTrialGuide = [MARKETING_PRO_TRIAL_ID, SALES_PROFESSIONAL_TRIAL_ID, SERVICE_PROFESSIONAL_TRIAL_ID, OPERATIONS_PROFESSIONAL_TRIAL_ID, CMS_PROFESSIONAL_TRIAL_ID, MARKETING_PLUS_PROFESSIONAL_BUNDLE_TRIAL_ID];
export const apiNameToTrialIds = {
  [SALES_HUB_ENTERPRISE]: SALES_ENTERPRISE_TRIAL_ID,
  [MARKETING_HUB_ENTERPRISE]: MARKETING_ENTERPRISE_TRIAL_ID,
  [SERVICE_HUB_ENTERPRISE]: SERVICE_ENTERPRISE_TRIAL_ID,
  [MARKETING_HUB_PROFESSIONAL]: MARKETING_PRO_TRIAL_ID,
  [CMS_HUB_PROFESSIONAL]: CMS_PROFESSIONAL_TRIAL_ID,
  [SALES_HUB_PROFESSIONAL]: SALES_PROFESSIONAL_TRIAL_ID,
  [SERVICE_HUB_PROFESSIONAL]: SERVICE_PROFESSIONAL_TRIAL_ID,
  [CMS_HUB_ENTERPRISE]: CMS_ENTERPRISE_TRIAL_ID,
  [OPERATIONS_HUB_PROFESSIONAL]: OPERATIONS_PROFESSIONAL_TRIAL_ID,
  [OPERATIONS_HUB_ENTERPRISE]: OPERATIONS_ENTERPRISE_TRIAL_ID,
  [MARKETING_PLUS_PROFESSIONAL_BUNDLE]: MARKETING_PLUS_PROFESSIONAL_BUNDLE_TRIAL_ID,
  [MARKETING_PLUS_ENTERPRISE_BUNDLE]: MARKETING_PLUS_ENTERPRISE_BUNDLE_TRIAL_ID
};
export const trialIdToApiName = {
  [SALES_ENTERPRISE_TRIAL_ID]: SALES_HUB_ENTERPRISE,
  [MARKETING_ENTERPRISE_TRIAL_ID]: MARKETING_HUB_ENTERPRISE,
  [SERVICE_ENTERPRISE_TRIAL_ID]: SERVICE_HUB_ENTERPRISE,
  [MARKETING_PRO_TRIAL_ID]: MARKETING_HUB_PROFESSIONAL,
  [CMS_PROFESSIONAL_TRIAL_ID]: CMS_HUB_PROFESSIONAL,
  [SALES_PROFESSIONAL_TRIAL_ID]: SALES_HUB_PROFESSIONAL,
  [SERVICE_PROFESSIONAL_TRIAL_ID]: SERVICE_HUB_PROFESSIONAL,
  [CMS_ENTERPRISE_TRIAL_ID]: CMS_HUB_ENTERPRISE,
  [OPERATIONS_PROFESSIONAL_TRIAL_ID]: OPERATIONS_HUB_PROFESSIONAL,
  [OPERATIONS_ENTERPRISE_TRIAL_ID]: OPERATIONS_HUB_ENTERPRISE,
  [MARKETING_PLUS_PROFESSIONAL_BUNDLE_TRIAL_ID]: MARKETING_PLUS_PROFESSIONAL_BUNDLE,
  [MARKETING_PLUS_ENTERPRISE_BUNDLE_TRIAL_ID]: MARKETING_PLUS_ENTERPRISE_BUNDLE
};
export const upgradeProductToTrialIds = {
  [SALES_ENTERPRISE]: SALES_ENTERPRISE_TRIAL_ID,
  [MARKETING_ENTERPRISE]: MARKETING_ENTERPRISE_TRIAL_ID,
  [SERVICE_ENTERPRISE]: SERVICE_ENTERPRISE_TRIAL_ID,
  [MARKETING_PRO]: MARKETING_PRO_TRIAL_ID,
  [CMS_PROFESSIONAL]: CMS_PROFESSIONAL_TRIAL_ID,
  [SALES_PROFESSIONAL]: SALES_PROFESSIONAL_TRIAL_ID,
  [SERVICE_PROFESSIONAL]: SERVICE_PROFESSIONAL_TRIAL_ID,
  [CMS_ENTERPRISE]: CMS_ENTERPRISE_TRIAL_ID,
  [OPERATIONS_PROFESSIONAL]: OPERATIONS_PROFESSIONAL_TRIAL_ID,
  [OPERATIONS_ENTERPRISE]: OPERATIONS_ENTERPRISE_TRIAL_ID,
  [MARKETING_PLUS_PROFESSIONAL]: MARKETING_PLUS_PROFESSIONAL_BUNDLE_TRIAL_ID,
  [MARKETING_PLUS_ENTERPRISE]: MARKETING_PLUS_ENTERPRISE_BUNDLE_TRIAL_ID
};
export const trialIdToUpgradeProducts = {
  [SALES_ENTERPRISE_TRIAL_ID]: SALES_ENTERPRISE,
  [MARKETING_ENTERPRISE_TRIAL_ID]: MARKETING_ENTERPRISE,
  [SERVICE_ENTERPRISE_TRIAL_ID]: SERVICE_ENTERPRISE,
  [MARKETING_PRO_TRIAL_ID]: MARKETING_PRO,
  [CMS_PROFESSIONAL_TRIAL_ID]: CMS_PROFESSIONAL,
  [SALES_PROFESSIONAL_TRIAL_ID]: SALES_PROFESSIONAL,
  [SERVICE_PROFESSIONAL_TRIAL_ID]: SERVICE_PROFESSIONAL,
  [CMS_ENTERPRISE_TRIAL_ID]: CMS_ENTERPRISE,
  [OPERATIONS_PROFESSIONAL_TRIAL_ID]: OPERATIONS_PROFESSIONAL,
  [OPERATIONS_ENTERPRISE_TRIAL_ID]: OPERATIONS_ENTERPRISE,
  [MARKETING_PLUS_PROFESSIONAL_BUNDLE_TRIAL_ID]: MARKETING_PLUS_PROFESSIONAL,
  [MARKETING_PLUS_ENTERPRISE_BUNDLE_TRIAL_ID]: MARKETING_PLUS_ENTERPRISE
};
export const trialIdToTrialNames = {
  [SALES_ENTERPRISE_TRIAL_ID]: 'Sales Hub Enterprise',
  [MARKETING_ENTERPRISE_TRIAL_ID]: 'Marketing Hub Enterprise',
  [SERVICE_ENTERPRISE_TRIAL_ID]: 'Service Hub Enterprise',
  [MARKETING_PRO_TRIAL_ID]: 'Marketing Hub Professional',
  [CMS_PROFESSIONAL_TRIAL_ID]: 'CMS Hub Professional',
  [SALES_PROFESSIONAL_TRIAL_ID]: 'Sales Hub Professional',
  [SERVICE_PROFESSIONAL_TRIAL_ID]: 'Service Hub Professional',
  [CMS_ENTERPRISE_TRIAL_ID]: 'CMS Hub Enterprise',
  [OPERATIONS_PROFESSIONAL_TRIAL_ID]: 'Operations Hub Professional',
  [OPERATIONS_ENTERPRISE_TRIAL_ID]: 'Operations Hub Enterprise',
  [MARKETING_PLUS_PROFESSIONAL_BUNDLE_TRIAL_ID]: 'Marketing+ Professional',
  [MARKETING_PLUS_ENTERPRISE_BUNDLE_TRIAL_ID]: 'Marketing+ Enterprise',
  [100]: 'test-trial' // Only used in ATs for testing the error state
};