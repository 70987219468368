import { useEffect, useMemo, useRef } from 'react';
import enviro from 'enviro';
import { TASK_BANNER_CONFIGS } from 'onboarding-tours-guide-config/tasksConfig';
import { useTaskData } from 'setup-guide-api/hooks/task';
import { useProgress } from 'setup-guide-components/hooks/task';
import { useTaskViewConfig } from 'setup-guide-components/hooks/viewConfig';
import { inviteYourTeam } from 'getting-started-shared-tasks/taskNames';
import { useSelectedView, useOnboardingKey } from './config';
export const applyOverrides = (taskMap, {
  taskGroupOverrides
}) => {
  if (!taskGroupOverrides || Object.keys(taskGroupOverrides).length === 0) {
    return taskMap;
  }
  return Object.keys(taskMap).reduce((overriddenTaskMap, taskKey) => {
    const task = taskMap[taskKey];
    const {
      name,
      description,
      illustration
    } = taskGroupOverrides[taskKey] || {};
    return Object.assign({}, overriddenTaskMap, {
      [taskKey]: Object.assign({}, task, name && {
        name
      }, description && {
        description
      }, illustration && {
        illustration
      })
    });
  }, {});
};
const applyHandholdingTaskOverrides = (task, source, groupKeyOverride) => {
  const taskBannerKey = task.key;
  task.handholdingEnabled = Boolean(TASK_BANNER_CONFIGS[taskBannerKey]);
  task.handholdingGroupKeyOverride = groupKeyOverride;
  task.handholdingSource = source;
};
const applyHandholdingOverrides = taskGroups => {
  taskGroups.forEach(({
    childGroups = [],
    key,
    tasks = []
  }) => {
    tasks.forEach(task => applyHandholdingTaskOverrides(task, 'consolidated_user_guide', key));
    applyHandholdingOverrides(childGroups);
  });
};
export const useTaskDataInUserGuide = () => {
  const taskData = useTaskData();
  const onboardingKey = useOnboardingKey();
  const taskViewConfig = useTaskViewConfig(onboardingKey);
  return useMemo(() => {
    if (!taskData || !taskData.taskMap || !taskData.taskGroups) {
      return taskData;
    }

    // This applies hand holding config to all tasks from all groups/subgroups
    // This is needed as the consolidated user guide has its own taskmap implementation
    // Note that this will also apply overrides to the Tasks in taskMap as these refer
    // to the same Task objects.
    applyHandholdingOverrides(taskData.taskGroups);
    const taskMap = taskData.taskMap && taskViewConfig ? applyOverrides(taskData.taskMap, taskViewConfig) : taskData.taskMap;
    return Object.assign({}, taskData, {
      taskMap
    });
  }, [taskData, taskViewConfig]);
};
export const useSyncProgressInTopNav = () => {
  const {
    groupKey
  } = useTaskData();
  const selectedView = useSelectedView();
  const {
    progressRate: progress
  } = useProgress();
  useEffect(() => {
    window.postMessage({
      type: 'onboarding-progress',
      payload: {
        progress,
        groupKey,
        selectedView
      }
    }, '*');
    if (!progress) {
      return;
    }
    // From unified-navigation-ui/onboardingUtils::setupGettingStartedProgress
    const progressHook = document.getElementById('getting-started-progress-bar--progress-hook');
    if (progressHook) {
      progressHook.style.width = `${progress}%`;
    }
    const progressText = document.getElementById('getting-started-progress-bar--progress-text');
    if (progressText) {
      progressText.innerText = `${progress}%`;
    }
  }, [progress, groupKey, selectedView]);
};
const DEFAULT_INVITE_YOUR_TEAM_TASK_ID = enviro.isQa() ? 27954 : 81;
export const useInviteYourTeamTaskIdFromList = () => {
  const prevTaskId = useRef();
  const {
    taskMap,
    isLoading: isLoadingTaskData
  } = useTaskData();
  const taskId = useMemo(() => {
    if (!taskMap) {
      return prevTaskId.current || DEFAULT_INVITE_YOUR_TEAM_TASK_ID;
    }
    const foundTasks = Object.keys(taskMap).find(taskKey => taskKey === inviteYourTeam);
    if (foundTasks) {
      prevTaskId.current = taskMap[foundTasks].id;
      return taskMap[foundTasks].id;
    } else {
      return prevTaskId.current || DEFAULT_INVITE_YOUR_TEAM_TASK_ID;
    }
  }, [taskMap]);
  return {
    taskId,
    isLoading: isLoadingTaskData && !prevTaskId
  };
};