import { ONBOARDING_STARTER_SCOPES, ONBOARDING_PRO_SCOPES, ONBOARDING_ENTERPRISE_SCOPES, HubEdition, Hub, ORDERED_HUB_EDITIONS, CMS_SCOPES_TO_EDITION, MARKETING_SCOPES_TO_EDITION, OPS_SCOPES_TO_EDITION, SALES_SCOPES_TO_EDITION, SERVICE_SCOPES_TO_EDITION } from '../scopeConstants';
import { includesAll } from './arrayUtils';
const getHighestHubEdition = (scopes, ScopesToEditionMap) => {
  return scopes.reduce((highestEdition, scope) => {
    const edition = ScopesToEditionMap[scope];
    if (!edition) {
      return highestEdition;
    }
    return ORDERED_HUB_EDITIONS.indexOf(edition) > ORDERED_HUB_EDITIONS.indexOf(highestEdition) ? edition : highestEdition;
  }, HubEdition.FREE);
};
export const getHighestCmsHubEdition = scopes => {
  return getHighestHubEdition(scopes, CMS_SCOPES_TO_EDITION);
};
export const getHighestMarketingHubEdition = scopes => {
  return getHighestHubEdition(scopes, MARKETING_SCOPES_TO_EDITION);
};
export const getHighestOpsHubEdition = scopes => {
  return getHighestHubEdition(scopes, OPS_SCOPES_TO_EDITION);
};
export const getHighestSalesHubEdition = scopes => {
  return getHighestHubEdition(scopes, SALES_SCOPES_TO_EDITION);
};
export const getHighestServiceHubEdition = scopes => {
  return getHighestHubEdition(scopes, SERVICE_SCOPES_TO_EDITION);
};
export const getHighestCommonSuiteEdition = scopes => {
  if (includesAll(scopes, ONBOARDING_ENTERPRISE_SCOPES)) {
    return HubEdition.ENTERPRISE;
  }
  if (includesAll(scopes, ONBOARDING_PRO_SCOPES)) {
    return HubEdition.PRO;
  }
  if (includesAll(scopes, ONBOARDING_STARTER_SCOPES)) {
    return HubEdition.STARTER;
  }
  return HubEdition.FREE;
};
export const getHighestHubEditions = (scopes, hubs) => {
  return Object.assign({}, hubs.includes(Hub.MARKETING) && {
    [Hub.MARKETING]: getHighestMarketingHubEdition(scopes)
  }, hubs.includes(Hub.SALES) && {
    [Hub.SALES]: getHighestSalesHubEdition(scopes)
  }, hubs.includes(Hub.SERVICE) && {
    [Hub.SERVICE]: getHighestServiceHubEdition(scopes)
  }, hubs.includes(Hub.CMS) && {
    [Hub.CMS]: getHighestCmsHubEdition(scopes)
  }, hubs.includes(Hub.OPERATIONS) && {
    [Hub.OPERATIONS]: getHighestOpsHubEdition(scopes)
  });
};
export const getAllHighestHubEditions = scopes => {
  return {
    [Hub.MARKETING]: getHighestMarketingHubEdition(scopes),
    [Hub.SALES]: getHighestSalesHubEdition(scopes),
    [Hub.SERVICE]: getHighestServiceHubEdition(scopes),
    [Hub.CMS]: getHighestCmsHubEdition(scopes),
    [Hub.OPERATIONS]: getHighestOpsHubEdition(scopes)
  };
};
export const getHighestEdition = scopes => {
  const highestHubEditions = getAllHighestHubEditions(scopes);
  return Object.values(highestHubEditions).reduce((highestEdition, currentEdition) => {
    return ORDERED_HUB_EDITIONS.indexOf(currentEdition) > ORDERED_HUB_EDITIONS.indexOf(highestEdition) ? currentEdition : highestEdition;
  }, HubEdition.FREE);
};