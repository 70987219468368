export const getHubletUrl = (path, options = {}) => {
  if (!options.hubletOriginOverride) {
    return path;
  }
  const originOverride = options.hubletOriginOverride.replace(/\/$/, '');
  return `${originOverride}/${path.replace(/^\//, '')}`;
};
export const getDeleteUrl = ({
  path,
  view,
  key
}) => `${path}/${view ? `${view}/` : ''}${key}`;