import { createTracker } from 'usage-tracker';
import events from '../../events.yaml';
const pageviewNamespace = events.pageView.namespace;
const eventNames = Object.keys(events).reduce((result, name) => {
  result[name] = name;
  return result;
}, {});
const tracker = createTracker({
  events
});
export const trackUserInteraction = (action, value, properties) => {
  return tracker.track(eventNames.userInteraction, Object.assign({
    action,
    value
  }, properties));
};
export const trackPageView = (screen, subscreen, properties) => {
  return tracker.track(eventNames.pageView, Object.assign({
    screen,
    subscreen
  }, properties));
};
export const trackSectionView = (section, value, properties) => {
  return tracker.track(eventNames.sectionView, Object.assign({
    section,
    value
  }, properties));
};
export const trackCreateUser = (isSuperAdmin, source) => {
  return tracker.track(eventNames.createUser, {
    userCount: 1,
    teamCount: 1,
    existing: false,
    'basePermissions.contacts': true,
    'basePermissions.marketing': false,
    'basePermissions.sales': false,
    'basePermissions.admin': false,
    roleNames: isSuperAdmin ? ['super-admin'] : ['contacts-base'],
    grantedSuperAdmin: isSuperAdmin,
    requiredCheckout: false,
    assignedToTeam: false,
    importFromCsv: false,
    emailSent: true,
    source
  });
};
export const useTracker = () => {
  return {
    namespace: pageviewNamespace,
    tracker,
    trackCreateUser,
    trackUserInteraction,
    trackPageView,
    trackSectionView
  };
};