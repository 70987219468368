import { EXPERIMENTS_REQUESTING, EXPERIMENTS_SUCCESSFUL, EXPERIMENTS_ERROR } from './ActionTypes';
import { getExperiments } from '../api/experimentsApis';
export const loadExperiments = () => dispatch => {
  dispatch({
    type: EXPERIMENTS_REQUESTING
  });
  return getExperiments().then(responseTreatments => {
    dispatch({
      type: EXPERIMENTS_SUCCESSFUL,
      payload: responseTreatments
    });
  }).catch(() => {
    dispatch({
      type: EXPERIMENTS_ERROR
    });
  });
};