import getLang from 'I18n/utils/getLang';
export const IMAGES_MODULES = require.context(`bender-url!../../images`, true, /^.*$/).keys();
export const createPrefetchLink = (url, options) => {
  const tag = document.createElement('link');
  tag.rel = 'prefetch';
  tag.href = url;
  tag.setAttribute('crossorigin', '');
  if (options) {
    const {
      as,
      onload
    } = options;
    if (as) {
      tag.as = as;
    }
    if (onload) {
      tag.onload = onload;
    }
  }
  return tag;
};
export const getImageSrc = (name, ext) => {
  const imgFile = [`${name}.${ext}`, `${name}/${getLang()}.${ext}`, `${name}/en.${ext}`].find(file => IMAGES_MODULES.includes(`./${file}`));
  return imgFile && require(`bender-url!../../images/${imgFile}`);
};
export const getResourceSrc = (name, type) => {
  if (type === 'svg') {
    return getImageSrc(name, 'svg');
  }
  return null;
};
export const prefetchResources = (resources, type) => {
  if (!resources || resources.length === 0) {
    return;
  }
  const documentFragment = document.createDocumentFragment();
  resources.forEach(name => {
    const src = getResourceSrc(name, type);
    if (src) {
      const tag = createPrefetchLink(src);
      documentFragment.appendChild(tag);
    }
  });
  document.head.appendChild(documentFragment);
};