import Raven from 'raven-js';
import { HubUserAttributesClient } from 'frontend-preferences-client';
import { getSuperstoreValue, reportSuperstoreError, setSuperstoreValue } from './superstore';
import { HAS_SEEN_USER_GUIDE_BEFORE_STORAGE_KEY } from '../constants/coac0002SurveyConstants';
const hubUserAttributesClient = HubUserAttributesClient.forCaller('setup-guide-ui');
const ACQ_0143_ATTRIBUTE_KEY = 'GrowthOnboardingSetup:ACQ0143NewSignupOnboardingFlowTreatment';
const COAC_0001_ATTRIBUTE_KEY = 'GrowthCoaching:COAC0001Treatment';
const COAC_0002_ATTRIBUTE_KEY = 'GrowthCoaching:COAC0002Treatment';

// If no value is set, it will default to 'control'
export const getIsEnrolledIntoGrowthPlan = async () => {
  try {
    const {
      [ACQ_0143_ATTRIBUTE_KEY]: acq0143Group,
      [COAC_0001_ATTRIBUTE_KEY]: coac001Group,
      [COAC_0002_ATTRIBUTE_KEY]: coac002Group
    } = await hubUserAttributesClient.batchFetch({
      [ACQ_0143_ATTRIBUTE_KEY]: 'control',
      [COAC_0001_ATTRIBUTE_KEY]: 'control',
      [COAC_0002_ATTRIBUTE_KEY]: 'control'
    }, 'hub-user-attributes-early-request-growth-plan');
    return acq0143Group === 'variant_a' || ['variant_a', 'variant_b'].includes(coac001Group) || ['variant_a', 'variant_b'].includes(coac002Group);
  } catch (error) {
    Raven.captureException(error);
    return false;
  }
};
export const getIsCoac0002Control = async () => {
  try {
    const {
      [COAC_0002_ATTRIBUTE_KEY]: coac0002Group
    } = await hubUserAttributesClient.batchFetch({
      [COAC_0002_ATTRIBUTE_KEY]: 'default'
    }, 'hub-user-attributes-early-request-growth-plan');
    return coac0002Group === 'control';
  } catch (error) {
    Raven.captureException(error);
    return false;
  }
};
export const setHasViewedUserGuide = () => {
  setSuperstoreValue(HAS_SEEN_USER_GUIDE_BEFORE_STORAGE_KEY, 'true').catch(error => reportSuperstoreError(HAS_SEEN_USER_GUIDE_BEFORE_STORAGE_KEY, 'set', error));
};
export const getHasViewedUserGuide = async () => {
  const hasSeenUserGuide = await getSuperstoreValue(HAS_SEEN_USER_GUIDE_BEFORE_STORAGE_KEY);
  return hasSeenUserGuide === 'true';
};