import { CONSOLIDATED_STARTER_GROUP_KEYS } from 'getting-started-shared-tasks/gettingStartedGroupKeys';

// Consolidated Keys
import { ONBOARDING_STARTER_SALES, ONBOARDING_STARTER_MARKETING, ONBOARDING_STARTER_SERVICE, ONBOARDING_STARTER_CMS } from 'getting-started-shared-tasks/onboardingKeys';
import { fromJS } from 'immutable';
import { batchEnrollMultiHubOnboarding } from 'onboarding-utils/onboardingSettingsStarterUtils';

// Leaving these here as they're needed to call batchEnrollMultiHubOnboarding

import { ONBOARDING_VIEWS } from 'onboarding-settings-client/constants/onboardingViews';
import { HAS_CLOSED_CHANGE_HOMEPAGE_MODAL } from '../constants/modalConstants';
import { ONBOARDING_PARAM_TO_VIEWS } from '../constants/onboardingKeys';
import { CMS_STARTER, MARKETING_STARTER, OPS_STARTER, SALES_STARTER, SERVICE_STARTER } from '../constants/urlConstants';
import { reportSuperstoreError, setSuperstoreValue } from './superstore';
const {
  starterMarketingView,
  starterSalesView,
  starterServiceView,
  starterContentView
} = CONSOLIDATED_STARTER_GROUP_KEYS;
const starterViewParamValues = [MARKETING_STARTER, SALES_STARTER, SERVICE_STARTER, CMS_STARTER, OPS_STARTER];

/*
  For applying the onboardingKey, group key and activeView (query param) overrides to onboardingSettings
*/
export function applyOnboardingSettingsOverrides(onboardingSettings, activeView, onboardingKey, groupKey) {
  if (!onboardingSettings || !activeView) {
    return onboardingSettings;
  }
  const newOnboardingSettings = fromJS(onboardingSettings).toJS();
  newOnboardingSettings.selectedView = activeView;
  newOnboardingSettings.views = newOnboardingSettings.views || {};
  const activeViewSettings = newOnboardingSettings.views[activeView] || {};
  newOnboardingSettings.views[activeView] = Object.assign({}, activeViewSettings, {
    groupKey: groupKey || activeViewSettings.groupKey,
    onboardingKey: onboardingKey || activeViewSettings.onboardingKey
  });
  return newOnboardingSettings;
}
export function setStarterMultiHubOnboardingSettings(onboardingSettings, newHubs, userId) {
  const newStarterHubs = newHubs.filter(hub => starterViewParamValues.includes(hub)).sort((firstHub, secondHub) => {
    return starterViewParamValues.indexOf(firstHub) - starterViewParamValues.indexOf(secondHub);
  }).map(hub => ONBOARDING_PARAM_TO_VIEWS[hub]);
  let newStarterOnboardingSettings = onboardingSettings;
  if (onboardingSettings && onboardingSettings.views && newStarterHubs.length > 0) {
    const selectedView = newStarterHubs[0];
    const newStarterViews = {};
    // Create Starter views for purchased hubs
    newStarterHubs.forEach(hub => {
      newStarterViews[hub] = getSelectedOnboardingSettings(hub);
    });
    return batchEnrollMultiHubOnboarding(userId, newStarterViews, selectedView).then(() => {
      // Update onboarding settings for redux store with Starter settings
      newStarterOnboardingSettings = {
        views: Object.assign({}, newStarterOnboardingSettings.views, newStarterViews),
        selectedView
      };
      return newStarterOnboardingSettings;
    });
  }
  return Promise.resolve(newStarterOnboardingSettings);
}
export function getSelectedOnboardingSettings(hub) {
  switch (hub) {
    case ONBOARDING_VIEWS.MARKETING:
      return {
        groupKey: starterMarketingView,
        onboardingKey: ONBOARDING_STARTER_MARKETING
      };
    case ONBOARDING_VIEWS.SALES:
      return {
        groupKey: starterSalesView,
        onboardingKey: ONBOARDING_STARTER_SALES
      };
    case ONBOARDING_VIEWS.SERVICE:
      return {
        groupKey: starterServiceView,
        onboardingKey: ONBOARDING_STARTER_SERVICE
      };
    case ONBOARDING_VIEWS.CMS:
      return {
        groupKey: starterContentView,
        onboardingKey: ONBOARDING_STARTER_CMS
      };
    default:
      return {};
  }
}
export function persistHomepageModalClosed() {
  setSuperstoreValue(HAS_CLOSED_CHANGE_HOMEPAGE_MODAL, 'true').catch(error => reportSuperstoreError(HAS_CLOSED_CHANGE_HOMEPAGE_MODAL, 'set', error));
}