module.exports = {
  "pageView": {
    "name": "pageview",
    "class": "view",
    "properties": {
      "screen": "string",
      "subscreen": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "growth-onboarding-components",
    "version": "3"
  },
  "sectionView": {
    "name": "section view",
    "class": "view",
    "properties": {
      "section": {
        "type": "string"
      },
      "value": {
        "type": "string"
      }
    },
    "namespace": "growth-onboarding-components",
    "version": "3"
  },
  "userInteraction": {
    "name": "user interaction",
    "class": "interaction",
    "properties": {
      "toggleStatus": {
        "type": "string",
        "isOptional": true
      },
      "invite_link": {
        "type": "string",
        "isOptional": true
      },
      "invitedUserId": {
        "type": "string",
        "isOptional": true
      },
      "action": {
        "type": "string"
      },
      "value": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "growth-onboarding-components",
    "version": "3"
  },
  "createUser": {
    "namespace": "settingsui",
    "name": "Create User",
    "class": "usage",
    "properties": {
      "userCount": "number",
      "teamCount": "number",
      "existing": "boolean",
      "basePermissions.contacts": "boolean",
      "basePermissions.marketing": "boolean",
      "basePermissions.sales": "boolean",
      "basePermissions.admin": "boolean",
      "roleNames": "array",
      "grantedSuperAdmin": "boolean",
      "requiredCheckout": "boolean",
      "assignedToTeam": "boolean",
      "importFromCsv": "boolean",
      "emailSent": "boolean",
      "source": "string"
    },
    "version": "3"
  }
};