import { useMemo } from 'react';
import PortalIdParser from 'PortalIdParser';
import { buildUrl, parseUrl } from 'hub-http/helpers/url';
export const usePortalId = () => {
  return useMemo(() => PortalIdParser.get(), []);
};
export const usePortalLink = link => {
  const portalId = usePortalId();
  return useMemo(() => link ? link.replace(/(%portalId%|%25portalId%25)/g, portalId.toString()) : '', [link, portalId]);
};
export const useTaskLink = (link, taskId, handholdingEnabled, handholdingGroupKeyOverride, handholdingSource) => {
  const portalLink = usePortalLink(link);
  return useMemo(() => {
    if (!portalLink || !handholdingEnabled) {
      return portalLink;
    }
    const url = parseUrl(portalLink);
    const maybeGroupKeyOverride = handholdingGroupKeyOverride ? `&onboarding_referrer_group=${handholdingGroupKeyOverride}` : '';
    const maybeSourceOverride = handholdingSource ? `&onboarding_referrer_source=${handholdingSource}` : '';
    const bannerParam = `onboarding_referrer_task=${taskId}${maybeGroupKeyOverride}${maybeSourceOverride}`;
    url.query = url.query ? `${url.query}&${bannerParam}` : bannerParam;
    return buildUrl(url);
  }, [handholdingEnabled, portalLink, handholdingGroupKeyOverride, handholdingSource, taskId]);
};