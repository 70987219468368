import http from 'hub-http/clients/apiClient';
import { reopenGrowthSidePanel, sendTaskExitMessage, sendTaskUpdateToSidePanel } from './utils/growthSidePanel';
import retryClient from './api/retryClient';
import { setGroupKey } from './api/userContextApi';
import { setOnboardingSettings } from './onboardingSettings';
export const USER_CONTEXT_ACTIONS_BASE_URL = 'usercontext/v1/actions';
export const USER_CONTEXT_APP_BASE_URL = 'usercontext-app/v1';
export const enableTaskCompletionModal = (actionName, options = {}) => {
  const {
    targetWindow = window
  } = options;
  const bannerFrame = targetWindow.document.getElementById('onboarding-tours-banner-frame');
  if (!window.sessionStorage || !bannerFrame || !bannerFrame.contentWindow) {
    return;
  }
  try {
    const configKey = 'ONBOARDING_TOURS_GUIDE';
    const configJson = window.sessionStorage.getItem(configKey);
    if (!configJson) {
      return;
    }
    const config = JSON.parse(configJson);
    if (config.type === 'task' && config.key === actionName) {
      config.completed = true;
      bannerFrame.contentWindow.postMessage({
        key: configKey,
        value: config
      }, '*');
    }
  } catch (e) {
    return;
  }
};

/**
 * Auto reopen Growth Side Panel when task is completed for the task which is started from the side panel
 * The `growth-side-panel-ongoing-tasks:${portalId}` is set in `growth-side-panel-content-ui` when the task cta link is clicked
 * @param taskKey task key
 * @param autoOpenSidePanel
 *   true: set localStorage and call zorse.openHelpWidget
 *   'onNextPage': only set localStorage
 */
export const openSidePanelOnAutoOpenTask = (taskKey, autoOpenSidePanel = true) => {
  reopenGrowthSidePanel({
    task: {
      key: taskKey
    },
    // check this task was started from Growth Plan or the Growth Side Panel
    disableURLParametersRequirement: true,
    disableDirectOpen: autoOpenSidePanel !== true
  });

  // send a message to trigger survey from growth side panel on task completion/exit
  sendTaskExitMessage({
    actionName: taskKey
  });
};

/**
 *  @param actionName task key
 *  @param options.autoOpenSidePanel
 *    false: ignore auto open side panel
 *    true: set localStorage and call zorse.openHelpWidget
 *    'onNextPage': only set localStorage
 *  @param options.showTaskCompletionModal show task completion modal from handholding banner
 */
export const markActionComplete = (actionName, options = {}) => {
  return retryClient.post(USER_CONTEXT_ACTIONS_BASE_URL, {
    data: {
      actionName
    }
  }).then(() => {
    const {
      autoOpenSidePanel = true,
      showTaskCompletionModal = true,
      targetMessageWindow = window,
      source
    } = options;
    if (autoOpenSidePanel) {
      openSidePanelOnAutoOpenTask(actionName, autoOpenSidePanel);
    }
    // send a message to the Growth Side Panel if present in the page
    sendTaskUpdateToSidePanel({
      actionName,
      targetWindow: targetMessageWindow
    });
    if (!showTaskCompletionModal) {
      return;
    }

    /**
     * Ignore task completion modal for avoiding breaking onboarding tour when:
     *   - Any onboarding tour is active in current page
     *   - markActionComplete is not triggered from onboarding-tours lib
     * */
    if (source !== 'onboarding-tours' && document.querySelector('.onboarding-tour--active')) {
      return;
    }
    enableTaskCompletionModal(actionName, {
      targetWindow: targetMessageWindow
    });
  });
};
export const getCompletedActions = () => http.get(USER_CONTEXT_ACTIONS_BASE_URL);
export const getIsActionCompleted = actionName => http.get(`${USER_CONTEXT_ACTIONS_BASE_URL}/${actionName}`);
export const getTasksGroup = () => {
  return http.get(`${USER_CONTEXT_APP_BASE_URL}/onboarding/tasks/group`, {
    headers: {
      accept: 'text/plain'
    }
  });
};
export const setDefaultGroupKey = (groupKey, options) => setGroupKey(groupKey, options);
export const setGettingStartedGroupKey = (groupKey, view, userId, portalId) => {
  const groupKeyRequests = [setDefaultGroupKey(groupKey)];
  if (view && userId) {
    groupKeyRequests.push(setOnboardingSettings({
      views: {
        [view]: {
          groupKey
        }
      },
      selectedView: view
    }, userId, portalId));
  }
  return Promise.all(groupKeyRequests);
};