import http from 'hub-http/clients/apiClient';
import { buildUrl } from 'hub-http/helpers/url';

/**
 * @deprecated {@link https://docs.google.com/document/d/10dKkHouJGDQmXp0T_FkgkgZMZbdPbjJCBDYYPBXza-8/edit#bookmark=id.olzxeaegmb2t more information}
 */
export const getDataSyncAuthConfigUrl = appId => `/api/integration-auth-flows/v1/apps/${appId}/config`;

/**
 * Fetches the configuration for authenticating the specified `authenticatorId`.
 * Also includes the `appName` for modal text translation purposes.
 * The definition of the app in the `appName` used here can be found from {@link https://paper.dropbox.com/doc/Apps-connectors-and-unified-auth--CI9UNFXvtY2vzlRmkTc5fjplAg-G6xU4hIMkkplwewO20otm this link}.
 * For example, Zoho CRM, Zoho Books, and Zoho Invoice will use the same authenticatorId `zoho`, but their the modal text will depend on the `appName` provided.
 */
export const getUnifiedAuthConfigUrl = (authenticatorId, appName) => `/api/integration-auth-flows/v1/authenticators/${authenticatorId}/config?appName=${appName}`;

/**
 * Creates or updates an account's credentials in the platform
 *
 * The params will contain all the required data as specified by the
 * authentication configuration.
 * This includes an OAuth code if the app authenticates via OAuth.
 */
export function postAccountCredentials(portalId, appId, accountId, params) {
  return http.post(accountId ? `/api/sync-bridge/v3/accounts/${accountId}/credentials` : '/api/sync-bridge/v3/install/finish', {
    data: params,
    query: {
      portalId,
      appId: appId.toString()
    }
  });
}
export function getAppInfoUrl(appId) {
  return buildUrl({
    path: `/api/sync-bridge/v5/apps/${appId}`
  });
}