const CMS_ENTERPRISE = 'onboarding-cms-enterprise';
const CMS_PRO = 'onboarding-cms-pro';
const CMS_STARTER = 'onboarding-cms-starter';
const MARKETING_ENTERPRISE = 'onboarding-marketing-enterprise';
const MARKETING_PRO = 'onboarding-marketing-pro';
const MARKETING_STARTER = 'onboarding-marketing-starter';
const OPS_ENTERPRISE = 'onboarding-ops-enterprise';
const OPS_PRO = 'onboarding-ops-pro';
const OPS_STARTER = 'onboarding-ops-starter';
const SALES_ENTERPRISE = 'onboarding-sales-enterprise';
const SALES_PRO = 'onboarding-sales-pro';
const SALES_STARTER = 'onboarding-sales-starter';
const SERVICE_ENTERPRISE = 'onboarding-service-enterprise';
const SERVICE_PRO = 'onboarding-service-pro';
const SERVICE_STARTER = 'onboarding-service-starter';
export let HubEdition;
(function (HubEdition) {
  HubEdition["FREE"] = "free";
  HubEdition["STARTER"] = "starter";
  HubEdition["PRO"] = "professional";
  HubEdition["ENTERPRISE"] = "enterprise";
})(HubEdition || (HubEdition = {}));
export let Hub;
(function (Hub) {
  Hub["MARKETING"] = "marketing";
  Hub["SALES"] = "sales";
  Hub["SERVICE"] = "service";
  Hub["CMS"] = "cms";
  Hub["OPERATIONS"] = "ops";
})(Hub || (Hub = {}));
export const ORDERED_HUB_EDITIONS = [HubEdition.FREE, HubEdition.STARTER, HubEdition.PRO, HubEdition.ENTERPRISE];
export const ONBOARDING_SCOPES = {
  CMS_ENTERPRISE,
  CMS_PRO,
  CMS_STARTER,
  MARKETING_ENTERPRISE,
  MARKETING_PRO,
  MARKETING_STARTER,
  OPS_ENTERPRISE,
  OPS_PRO,
  OPS_STARTER,
  SALES_ENTERPRISE,
  SALES_PRO,
  SALES_STARTER,
  SERVICE_ENTERPRISE,
  SERVICE_PRO,
  SERVICE_STARTER
};
export const ONBOARDING_STARTER_SCOPES = [CMS_STARTER, MARKETING_STARTER, OPS_STARTER, SALES_STARTER, SERVICE_STARTER];
export const ONBOARDING_PRO_SCOPES = [CMS_PRO, MARKETING_PRO, OPS_PRO, SALES_PRO, SERVICE_PRO];
export const ONBOARDING_ENTERPRISE_SCOPES = [CMS_ENTERPRISE, MARKETING_ENTERPRISE, OPS_ENTERPRISE, SALES_ENTERPRISE, SERVICE_ENTERPRISE];
export const ALL_ONBOARDING_PAID_SCOPES = [...ONBOARDING_STARTER_SCOPES, ...ONBOARDING_PRO_SCOPES, ...ONBOARDING_ENTERPRISE_SCOPES];
export const PRO_PLUS_ONBOARDING_SCOPES = [...ONBOARDING_PRO_SCOPES, ...ONBOARDING_ENTERPRISE_SCOPES];
export const CMS_SCOPES_TO_EDITION = {
  [CMS_STARTER]: HubEdition.STARTER,
  [CMS_PRO]: HubEdition.PRO,
  [CMS_ENTERPRISE]: HubEdition.ENTERPRISE
};
export const MARKETING_SCOPES_TO_EDITION = {
  [MARKETING_STARTER]: HubEdition.STARTER,
  [MARKETING_PRO]: HubEdition.PRO,
  [MARKETING_ENTERPRISE]: HubEdition.ENTERPRISE
};
export const OPS_SCOPES_TO_EDITION = {
  [OPS_STARTER]: HubEdition.STARTER,
  [OPS_PRO]: HubEdition.PRO,
  [OPS_ENTERPRISE]: HubEdition.ENTERPRISE
};
export const SALES_SCOPES_TO_EDITION = {
  [SALES_STARTER]: HubEdition.STARTER,
  [SALES_PRO]: HubEdition.PRO,
  [SALES_ENTERPRISE]: HubEdition.ENTERPRISE
};
export const SERVICE_SCOPES_TO_EDITION = {
  [SERVICE_STARTER]: HubEdition.STARTER,
  [SERVICE_PRO]: HubEdition.PRO,
  [SERVICE_ENTERPRISE]: HubEdition.ENTERPRISE
};