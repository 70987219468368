import { useLazyQuery, useQuery } from 'data-fetching-client';
import { QUERY_RECENT_POST_PURCHASE } from '../queries/recentPostPurchaseApi';
import { getPortalId } from '../utils/getPortalId';
import { isLastPurchaseSalesHubProfessional } from '../utils/isLastPurchaseSalesHubProfessional';
export const useLazyIsGPRO0002Audience = portalId => {
  const [fetchRecentPostPurchase, {
    data,
    loading,
    error
  }] = useLazyQuery(QUERY_RECENT_POST_PURCHASE, {
    variables: {
      portalId: portalId || getPortalId()
    }
  });
  if (loading || error) {
    return {
      fetchRecentPostPurchase,
      loading,
      isGPRO0002Audience: undefined,
      error
    };
  }
  if (!data || !data.recentPostPurchase || !data.recentPostPurchase.length) {
    return {
      fetchRecentPostPurchase,
      loading,
      isGPRO0002Audience: false,
      error
    };
  }
  if (data && data.recentPostPurchase && data.recentPostPurchase.length) {
    const found = isLastPurchaseSalesHubProfessional(data.recentPostPurchase);
    if (found) {
      return {
        fetchRecentPostPurchase,
        loading,
        isGPRO0002Audience: true,
        error
      };
    }
    return {
      fetchRecentPostPurchase,
      loading,
      isGPRO0002Audience: false,
      error
    };
  }
  return {
    fetchRecentPostPurchase,
    loading,
    isGPRO0002Audience: undefined,
    error
  };
};
export const useIsGPRO0002Audience = portalId => {
  const {
    data,
    loading,
    error
  } = useQuery(QUERY_RECENT_POST_PURCHASE, {
    variables: {
      portalId: portalId || getPortalId()
    }
  });
  if (loading || error) {
    return {
      loading,
      isGPRO0002Audience: undefined,
      error
    };
  }
  if (!data || !data.recentPostPurchase || !data.recentPostPurchase.length) {
    return {
      loading,
      isGPRO0002Audience: false,
      error
    };
  }
  if (data && data.recentPostPurchase && data.recentPostPurchase.length) {
    const found = isLastPurchaseSalesHubProfessional(data.recentPostPurchase);
    if (found) {
      return {
        loading,
        isGPRO0002Audience: true,
        error
      };
    }
    return {
      loading,
      isGPRO0002Audience: false,
      error
    };
  }
  return {
    loading,
    isGPRO0002Audience: undefined,
    error
  };
};