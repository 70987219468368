import { IN_APP_SIDEPANEL_EXPOSURE_STORAGE_KEY, IN_APP_SIDEPANEL_ORIGINATING_CUSTOMIZATION_TOUR_KEY, CRM_CUSTOMIZATIONS_CONTEXT } from './exposureConstants';
export const getSessionStorage = key => {
  try {
    return sessionStorage.getItem(key);
  } catch (error) {
    return null;
  }
};
export const setSessionStorage = (key, value) => {
  try {
    return sessionStorage.setItem(key, value);
  } catch (error) {
    return null;
  }
};
export const removeSessionStorage = key => {
  try {
    return sessionStorage.removeItem(key);
  } catch (error) {
    return null;
  }
};
export const getInAppSidepanelExposure = () => {
  return getSessionStorage(IN_APP_SIDEPANEL_EXPOSURE_STORAGE_KEY);
};
export const setInAppSidepanelExposure = () => {
  setSessionStorage(IN_APP_SIDEPANEL_EXPOSURE_STORAGE_KEY, CRM_CUSTOMIZATIONS_CONTEXT);
};
export const removeInAppSidepanelExposure = () => {
  removeSessionStorage(IN_APP_SIDEPANEL_EXPOSURE_STORAGE_KEY);
};
export const getOriginatingCrmCustomizationTour = () => {
  return getSessionStorage(IN_APP_SIDEPANEL_ORIGINATING_CUSTOMIZATION_TOUR_KEY);
};
export const removeOriginatingCrmCustomizationTour = () => {
  removeSessionStorage(IN_APP_SIDEPANEL_ORIGINATING_CUSTOMIZATION_TOUR_KEY);
};
export const setOriginatingCustomizationTour = tourId => {
  setSessionStorage(IN_APP_SIDEPANEL_ORIGINATING_CUSTOMIZATION_TOUR_KEY, tourId);
};