module.exports = {
  "sectionView": {
    "name": "section view",
    "class": "view",
    "properties": {
      "section": {
        "type": "string"
      },
      "source": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "growth-invite-components",
    "version": "1"
  },
  "userInteraction": {
    "name": "user interaction",
    "class": "interaction",
    "properties": {
      "action": {
        "type": "string"
      },
      "value": {
        "type": "string",
        "isOptional": true
      },
      "source": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "growth-invite-components",
    "version": "1"
  },
  "createUser": {
    "namespace": "settingsui",
    "name": "Create User",
    "class": "usage",
    "properties": {
      "userCount": "number",
      "teamCount": "number",
      "existing": "boolean",
      "basePermissions.contacts": "boolean",
      "basePermissions.marketing": "boolean",
      "basePermissions.sales": "boolean",
      "basePermissions.admin": "boolean",
      "roleNames": "array",
      "grantedSuperAdmin": "boolean",
      "requiredCheckout": "boolean",
      "assignedToTeam": "boolean",
      "importFromCsv": "boolean",
      "emailSent": "boolean",
      "source": "string"
    },
    "version": "1"
  }
};