import PortalIdParser from 'PortalIdParser';
import { parse, stringify } from 'hub-http/helpers/params';
const PORTAL_ID = PortalIdParser.get();
export const redirectTo = url => {
  (window.top || window).location.href = url;
};
export const wrapUrlWithParams = (url, params) => {
  if (!params) {
    return url;
  }
  const [baseUrl, existingSearch] = url.split('?');
  if (existingSearch) {
    params = Object.assign({}, parse(existingSearch), params);
  }
  const hasSearch = Object.keys(params).length > 0;
  return `${baseUrl}${hasSearch ? '?' : ''}${stringify(params)}`;
};
export const getMailchimpImportUrl = () => `/email/${PORTAL_ID}/import/mailchimp`;
export const getImportUrl = () => `/import/${PORTAL_ID}/new-import/standard?onboarding=true`;
export const getMarketPlaceOpsTeamsUrl = () => `/ecosystem/${PORTAL_ID}/marketplace/apps/apps-for-operations-teams`;
export const getFreeOpsHubDemoUrl = () => `/ops-hub-demo/${PORTAL_ID}?flowId=free`;