import { useMemo } from 'react';
import enviro from 'enviro';
import { importContacts, quickImportContacts, addDataModal } from 'getting-started-shared-tasks/taskNames';
import { useTaskData } from './task';
import { getQueryParam } from '../utils/urls';
export const useImportContactTaskIdFromList = () => {
  const {
    taskMap,
    isLoading: isLoadingTaskData
  } = useTaskData();
  const maybeImportContactsTaskId = useMemo(() => {
    const maybeTaskKeyQueryParam = getQueryParam('taskKey');
    if (maybeTaskKeyQueryParam === addDataModal) {
      // TODO: Support onboarding_referrer_task_key on Handholding Banner
      let taskId = enviro.isQa() ? 289255 : 170116;
      if (taskMap) {
        const foundTasks = Object.keys(taskMap).find(taskKey => taskKey === addDataModal);
        if (foundTasks) {
          taskId = taskMap[foundTasks].id;
        }
      }
      return taskId;
    }
    if (!taskMap) {
      return null;
    }
    const foundTasks = Object.keys(taskMap).find(taskKey => taskKey === importContacts || taskKey === quickImportContacts || taskKey === addDataModal);
    if (foundTasks) {
      return taskMap[foundTasks].id;
    } else {
      return null;
    }
  }, [taskMap]);
  return {
    importContactTaskId: maybeImportContactsTaskId,
    isLoading: isLoadingTaskData
  };
};