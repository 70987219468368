import { useEffect } from 'react';
import Raven from 'raven-js';
const handleRejection = event => {
  const {
    reason
  } = event;
  if (!reason) {
    return;
  }
  // For ignored errors, still tracking unhandled rejection errors by setting a consistent message
  const errorMessage = 'Unhandled promise reject error';
  if (reason.stack) {
    const {
      message: rejectionMessage
    } = reason;
    reason.message = errorMessage;
    Raven.captureException(reason, {
      extra: {
        rejectionMessage
      }
    });
  } else if (typeof reason === 'string') {
    Raven.captureMessage(errorMessage, {
      extra: {
        rejectionMessage: reason
      }
    });
  }
};
const UnhandledPromiseRejectionListener = () => {
  useEffect(() => {
    window.addEventListener('unhandledrejection', handleRejection);
    return () => window.removeEventListener('unhandledrejection', handleRejection);
  }, []);
  return null;
};
export default UnhandledPromiseRejectionListener;