import Raven from 'raven-js';
import PortalIdParser from 'PortalIdParser';
import I18n from 'I18n';
import getLang from 'I18n/utils/getLang';
const NON_MEANINGFUL_NETWORK_ERROR_FINGERPRINT = 'Non-Meaningful Network Error';

// Network errors to isolate into groups regardless of URL
const ISOLATED_NETWORK_CODES = ['ABORT', 'NETWORKERROR', 'TIMEOUT', '401', '403'];
const ISOLATED_ERROR_FIRST_LINES = [
// A lazy load of a React app page has failed
/Loading chunk ?(.+)failed/,
// A lazy load of JS has failed
/error loading split chunk/,
// A nested API request has failed
/hub-http - \/(.+) - (401|403|429|502)/,
// A nested API request has failed
/hub-http request for (.+): (401|403|429|502)/,
// The browser prevented an action, typically trying to remove HTML that isn't on the DOM
// This is usually a Chrome extension or other peer app that is editing the main document
/Failed to execute /];

// taken from https://stackoverflow.com/a/3809435
const URI_REGEX =
// eslint-disable-next-line no-useless-escape
/https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/;
function getAllAvailableLocales(localesMap) {
  // localeKeys are usually 'parent' locales like 'en' and
  // availableLocale contains an array of variants like
  // ['en_us', 'en_ie', etc]
  const localeKeys = Object.keys(localesMap);
  return localeKeys.reduce((allLocales, localeKey) => {
    const availableLocales = localesMap[localeKey].availableLocale;
    /* eslint-disable-next-line hubspot-dev/no-reduce-accumulator-copy */
    return [...allLocales, ...availableLocales];
  }, localeKeys);
}

// get all possible locale codes to remove them from JS errors
function getAllLocaleRegex() {
  let allLocales;
  if (I18n) {
    // get the fully supported locales
    const baseLocales = getAllAvailableLocales(I18n.baseLocales);
    // get unsupported but recognised locales
    const publicLocales = getAllAvailableLocales(I18n.publicLocales);
    allLocales = new Set([...baseLocales, ...publicLocales]);
  } else {
    // fallback if I18n failed to load
    allLocales = new Set([getLang()]);
  }

  // output will generally look like a chain looking to remove
  // ['en', "en", (en), 'fr', "fr", (fr), ...]
  const allLocaleRegexStr = [...allLocales].map(lang => `'${lang}'|"${lang}"|[(]${lang}[)]`).join('|');
  return new RegExp(allLocaleRegexStr, 'g');
}
let ALL_LOCALE_REGEX;
function stripPortalId(str) {
  const portalId = PortalIdParser.get();
  if (!portalId) {
    return str;
  }
  const portalIdRegex = new RegExp(portalId.toString(), 'g');
  return str.replace(portalIdRegex, '<portal_id>');
}
function stripQueryParams(url) {
  return url.replace(/\?.*$/, '');
}
function cleanseUrl(url) {
  return stripPortalId(stripQueryParams(url));
}
function stripLocale(str) {
  return str.replace(ALL_LOCALE_REGEX, '<user_lang>');
}
function splitIntoLines(str) {
  return str.split(/\r?\n/g);
}
function containsUri(str) {
  return URI_REGEX.test(str);
}
function parseUri(str) {
  const matches = URI_REGEX.exec(str);
  return matches && matches[0];
}

// taken from https://stackoverflow.com/a/1981366
function stripRepeatedWhitespace(str) {
  return str.replace(/\s\s+/g, ' ');
}
function maybeGenerateLocaleRegex() {
  if (ALL_LOCALE_REGEX === undefined) {
    ALL_LOCALE_REGEX = getAllLocaleRegex();
  }
}

/**
 * We want to avoid having multiple sentries for the same error but we still want to track it.
 * The problem is that every request has the portalId in the query string, which results in a
 * different error message, thus a different error.
 * What we are doing here is manually capturing an error with Sentry and adding a fingerprint.
 * Sentry will group all the errors with the same fingerprint regardless of the actual error.
 * The fingerprint for each error network is: url (without query string) + error code
 */
export function captureNetworkError(error) {
  const code = error.errorCode || error.status;
  if (code && error.options && error.options.url) {
    const cleansedUrl = cleanseUrl(error.options.url);
    Raven.captureMessage(error, {
      fingerprint: ISOLATED_NETWORK_CODES.includes(code.toString()) ? [NON_MEANINGFUL_NETWORK_ERROR_FINGERPRINT] : [cleansedUrl, code],
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ fingerprint: any[]; message: s... Remove this comment to see the full error message
      message: `${code}: ${cleansedUrl}`
    });
  }
}

/**
 * Capture an error with Sentry and adding a fingerprint, then throw the error
 */
export function catchAndRethrowNetworkError(error) {
  captureNetworkError(error);
  throw error;
}
function parseStacktracedError(message, type = 'Unspecified Error') {
  const [firstLine] = splitIntoLines(message);

  // If the first line contains a URL, it more than likely a network error
  // that is wrapped up in a JavaScript stacktrace
  if (containsUri(firstLine)) {
    return `Stacktraced Network Error: ${cleanseUrl(parseUri(firstLine))}`;
  }

  // If the first line contains phrases we know are tied to non-important
  // network or browser errors
  const matchingfirstLineRegex = ISOLATED_ERROR_FIRST_LINES.find(firstLineRegex => firstLineRegex.test(firstLine));
  if (matchingfirstLineRegex) {
    return NON_MEANINGFUL_NETWORK_ERROR_FINGERPRINT;
  }

  // Strip any meaningless characters or strings from JS exceptions.
  // We're removing locale codes here for JS errors where the UI attempts
  // to resolve a locale code key within a translation object or mentions
  // a locale code in file address; generally it is unimportant what
  // exact locale code it was when investigating
  return `${type}: ${stripLocale(stripRepeatedWhitespace(message))}`;
}
export function ravenDataCallback(data, original) {
  // I18n may not be initialised when this JS file is loaded
  maybeGenerateLocaleRegex();
  // If the raven request already has a fingerprint, it has already been handled by
  // upstream from us by either our own network failure catches or by other FE tools
  // (e.g. usage tracking), and doesn't need to be assigned a fingerprint

  if (!data.fingerprint) {
    // The gist of the logic here is that we continuously try and find network errors through:
    // - `data` having an `exception` where a URL is mentioned in the first line
    // - `data` having a `request` property
    // - `data` having a `message` where a URL is mentioned in the first line
    // Otherwise we strip for excess whitespace and locales and treat as a JS error.
    let fingerprint;
    if (data.exception && data.exception.values && data.exception.values.length > 0) {
      const [exception] = data.exception.values;
      fingerprint = [parseStacktracedError(exception.value, exception.type)];
    } else if (data.request && data.request.url) {
      // Strip portal ids and query params from requests...
      fingerprint = [cleanseUrl(data.request.url)];
    } else {
      fingerprint = [parseStacktracedError(data.message)];
    }
    // This will cause raven to ignore its default grouping behaviour and go solely
    // off our scrubbed error message
    data.fingerprint = fingerprint;
  }
  data.extra = data.extra ? Object.assign({}, data.extra, {
    fingerprint: data.fingerprint
  }) : {
    fingerprint: data.fingerprint
  };
  return original ? original(data) : data;
}