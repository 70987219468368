import { addLiveChat, addSupportForm, addTrackingCode, connectDomain, connectInbox, connectInboxAndCalendar, connectYourTeamEmail, createBot, createContact, createSimpleForm, createTask, createCustomRecordProperty, editDealStages, importContacts, installExtension, inviteYourTeam, setupPersonalMeetingsLink, createLandingPage, addDataModal } from 'getting-started-shared-tasks/taskNames';
import AddLiveChatModal from './learn/AddLiveChatModal';
import AddSupportFormModal from './learn/AddSupportFormModal';
import ConnectDomainModal from './learn/ConnectDomainModal';
import ConnectInboxModal from './learn/ConnectInboxModal';
import ConnectInboxAndCalendarModal from './learn/ConnectInboxAndCalendarModal';
import ConnectTeamEmailModal from './learn/ConnectTeamEmailModal';
import CreateBotModal from './learn/CreateBotModal';
import CreateContactModal from './learn/CreateContactModal';
import CreateCampaignModal from './learn/CreateCampaignModal';
import CreateFormModal from './learn/CreateFormModal';
import CreateListModal from './learn/CreateListModal';
import CreateTaskModal from './learn/CreateTaskModal';
import CustomRecordPropertyModal from './learn/CustomRecordPropertyModal';
import CustomizeDealStagesModal from './learn/CustomizeDealStagesModal';
import ImportContactsModal from './learn/ImportContactsModal';
import InstallSalesExtensionModal from './learn/InstallSalesExtensionModal';
import InviteTeamModal from './learn/InviteTeamModal';
import SetupPersonalMeetingLinkModal from './learn/SetupPersonalMeetingLinkModal';
import TrackingCodeModal from './learn/TrackingCodeModal';
import CreateLandingPageModal from './learn/CreateLandingPageModal';
import AddDataModal from './learn/AddDataModal';
export default {
  [addLiveChat]: AddLiveChatModal,
  [addSupportForm]: AddSupportFormModal,
  [addTrackingCode]: TrackingCodeModal,
  [connectDomain]: ConnectDomainModal,
  [connectInbox]: ConnectInboxModal,
  [connectInboxAndCalendar]: ConnectInboxAndCalendarModal,
  [connectYourTeamEmail]: ConnectTeamEmailModal,
  [createBot]: CreateBotModal,
  [createContact]: CreateContactModal,
  [createCustomRecordProperty]: CustomRecordPropertyModal,
  [createSimpleForm]: CreateFormModal,
  [createTask]: CreateTaskModal,
  [editDealStages]: CustomizeDealStagesModal,
  [importContacts]: ImportContactsModal,
  [installExtension]: InstallSalesExtensionModal,
  [inviteYourTeam]: InviteTeamModal,
  'create-email': CreateCampaignModal,
  'create-list': CreateListModal,
  [setupPersonalMeetingsLink]: SetupPersonalMeetingLinkModal,
  [createLandingPage]: CreateLandingPageModal,
  [addDataModal]: AddDataModal
};