import { useContext } from 'react';
import AddonComponentsContext from '../contexts/AddonComponentsContext';
export const useAddonComponents = () => {
  return useContext(AddonComponentsContext);
};
export const useHandholdingBannerHelpers = () => {
  return useAddonComponents().HandholdingBannerHelpers;
};
export const useManualDetectedTaskButtons = () => {
  return useAddonComponents().ManualDetectedTaskButtons;
};
export const useManualDetectedTaskButton = taskKey => {
  const {
    ManualDetectedTaskButtons = {}
  } = useAddonComponents();
  return ManualDetectedTaskButtons[taskKey];
};
export const useCmsPartnerMatchingModal = () => {
  return useAddonComponents().CmsPartnerMatchingModal;
};