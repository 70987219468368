import http from 'hub-http/clients/apiClient';
import { PROGRESS_URL, PROGRESS_URL_V2 } from '../constants/apiUrls';
/**
 * V1
 */

export const getProgress = (groupKey = '') => {
  return http.get(`${PROGRESS_URL}${groupKey && `/${groupKey}`}`);
};
export const getProgressSummary = (groupKey = '') => {
  return http.get(`${PROGRESS_URL}${groupKey && `/${groupKey}`}/summary`);
};
export const getProgressSummaryAll = groupKey => {
  return http.get(`${PROGRESS_URL}/${groupKey}/summary/all`);
};

/**
 * V2
 */

export const getProgressByGroupKeys = groupKeys => {
  const groupKeysQuery = groupKeys.map(groupKey => `groupKeys=${groupKey}`).join('&');
  return http.get(`${PROGRESS_URL_V2}?${groupKeysQuery}`);
};
export const getProgressSummaryByGroupKeys = groupKeys => {
  const groupKeysQuery = groupKeys.map(groupKey => `groupKeys=${groupKey}`).join('&');
  return http.get(`${PROGRESS_URL_V2}/summary?${groupKeysQuery}`);
};