import { useMemo } from 'react';
import getCmsViewConfig from '../views/CmsViewConfig';
import getMarketingViewConfig from '../views/MarketingViewConfig';
import getSalesViewConfig from '../views/SalesViewConfig';
import getServiceViewConfig from '../views/ServiceViewConfig';
import getCommerceViewConfig from '../views/CommerceViewConfig';
export const useViewConfig = appContexts => {
  return useMemo(() => {
    return Object.assign({}, getCmsViewConfig(appContexts), getMarketingViewConfig(appContexts), getSalesViewConfig(appContexts), getServiceViewConfig(appContexts), getCommerceViewConfig(appContexts));
  }, [appContexts]);
};