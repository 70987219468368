export const activateEmailSequence = 'activate-email-sequence';
export const addContactProperty = 'add-contact-property';
export const addDataModal = 'add-data-modal';
export const addLiveChat = 'add-live-chat';
export const addSupportForm = 'add-support-form';
export const addTimetoRespondReport = 'add-time-to-respond-report';
export const addTrackingCode = 'add-tracking-code';
export const assignContacts = 'assign-contacts';
export const automateEmail = 'automate-email';
export const automateForm = 'automate-form';
export const buildAiWebsite = 'build-ai-website';
export const buildSite = 'build-site';
export const buildSiteV2 = 'build-site-v2';
export const captureTicketDetails = 'capture-ticket-details';
export const configureTicketAutomation = 'configure-ticket-automation';
export const connectAndCreateAds = 'connect-and-create-ads';
export const connectDomain = 'connect-domain';
export const connectInbox = 'connect-inbox';
export const connectInboxAndCalendar = 'connect-inbox-and-calendar';
export const connectYourDomainTask = 'connect-your-domain-task';
export const connectYourTeamEmail = 'connect-your-team-email';
export const createAccount = 'create-account';
export const createBlogTeasers = 'create-blog-teasers';
export const createBot = 'create-bot';
export const createContact = 'create-first-contact';
export const createCustomProperty = 'create-custom-property';
export const createCustomRecordProperty = 'create-custom-record-property';
export const createDeal = 'create-deal';
export const commerceCreateDeal = 'commerce-create-deal';
export const commerceConnectSync = 'commerce-connect-sync';
export const createEmail = 'create-email';
export const createEmailCampaign = 'create-email-campaign';
export const createLandingPage = 'create-landing-page';
export const createLeadAd = 'create-lead-ad';
export const createList = 'create-list';
export const createPodcast = 'create-podcast';
export const createReusableSnippet = 'create-reusable-snippet';
export const createRoutingRuleset = 'create-routing-ruleset';
export const createSimpleForm = 'create-simple-form';
export const createSimpleFormStarter = 'create-simple-form-starter';
export const createTask = 'create-task';
export const createTicket = 'create-ticket';
export const createABlogPost = 'create-a-blog-post';
export const customizeTicketProperties = 'customize-ticket-properties';
export const editDealStages = 'edit-deal-stages';
export const emailCoolRobot = 'email-cool-robot';
export const emailPersonalization = 'email-personalization';
export const embedSmartContent = 'embed-smart-content';
export const explorePublishSite = 'explore-publish-site';
export const filterContacts = 'filter-contacts';
export const getStartedWithCrmVideo = 'get-started-with-crm-video';
export const getStartedWithMarketingHubVideo = 'get-started-with-marketing-hub-video';
export const getStartedWithServiceHubVideo = 'get-started-with-service-video';
export const helpDeskAddUsers = 'help-desk-add-users';
export const helpDeskConnectChannel = 'help-desk-connect-channel';
export const helpDeskCreateView = 'help-desk-create-view';
export const helpDeskCustomizeTickets = 'help-desk-customize-tickets';
export const helpDeskGetStarted = 'help-desk-get-started';
export const helpDeskSetupSlas = 'help-desk-setup-slas';
export const importContacts = 'import-contacts';
export const installExtension = 'install-extension';
export const inviteYourTeam = 'invite-your-team';
export const learnAboutForms = 'learn-about-forms-task';
export const learnWhenToCreateDeal = 'learn-when-to-create-deal';
export const marketingEmailAiWizard = 'marketing-email-ai-wizard';
export const measureTeamPerformance = 'measure-team-performance';
export const onboardingCompleteModal = 'onboarding-complete-modal';
export const pageEditorDemo = 'page-editor-demo';
export const publishFirstWebpageTask = 'publish-first-webpage-task';
export const publishPageToTempUrl = 'publish-page-to-temp-url';
export const purchaseMarketingStarter = 'purchase-marketing-starter';
export const quickInviteModal = 'quick-invite-modal';
export const readCRMFaq = 'read-crm-faq';
export const readServiceFaq = 'read-service-faq';
export const remixContent = 'remix-content';
export const selectActiveTheme = 'select-active-theme';
export const selectStyle = 'select-style';
export const selectStyleV2 = 'select-style-v2';
export const seoRecommendations = 'seo-recommendations';
export const setupBrandVoice = 'setup-brand-voice';
export const setupLiveChatRouting = 'setup-live-chat-routing';
export const setupPersonalMeetingsLink = 'setup-personal-meetings-link';
export const setupTeamMeetingsLink = 'setup-team-meetings-link';
export const setupTicketPipeline = 'setup-ticket-pipeline';
export const tourContactRecord = 'tour-contact-record';
export const trackEmailPerformance = 'track-email-performance';
export const trackServicePerformance = 'track-service-performance';
export const trackWebsitePerformance = 'track-website-performance';
export const translateContent = 'translate-content';
export const useEmailTemplate = 'use-email-template';
export const viewMarketingDashboard = 'view-marketing-dashboard';
export const viewOnboardingCarousel = 'view-onboarding-carousel';
export const visitHubspotMarketplace = 'visit-hubspot-marketplace';
export const wordpressAcademyCourse = 'wordpress-academy-lesson';
export const wordpressMarketingDemo = 'wordpress-marketing-demo';
// This task is marked completed by a job
// https://git.hubteam.com/HubSpot/UserContext/pull/933
export const integrateAppsWithHubspot = 'integrate-apps-with-hubspot';

// Business Box - Build your online presence new tasks
export const postFirstBlog = 'post-first-blog';
export const connectCalendar = 'connect-calendar';
export const connectChannel = 'connect-channel';
export const publishChatbot = 'publish-chatbot';
export const connectAdsAccount = 'connect-ads-account';
export const downloadMobileApp = 'download-mobile-app';
export const websiteGraderLink = 'website-grader-link';
export const freeSeoCourse = 'free-seo-course';
export const createBuyerPersona = 'create-buyer-persona';
export const publishLandingPage = 'publish-landing-page';
export const learnCreateEbook = 'learn-create-ebook';
export const learnDesignSalesProgress = 'learn-design-sales-progress';
export const createSalesPlanTemplate = 'create-sales-plan-template';
export const learnVirtualConference = 'learn-virtual-conference';
export const learnFollUpEmail = 'learn-foll-up-email';

// commerce-home-ui payments_essentials_group
export const paymentsAddBankAccount = 'payments_post_enrollment_add_bank_account';
export const paymentsCustomizeCheckout = 'payments_post_enrollment_customize_checkout';

// commerce-home-ui payments_essentials_group_post_0048
export const paymentsCustomizeCheckoutPost0048 = 'payments_post_enrollment_customize_checkout_post_0048';
export const paymentsInviteYourTeamToCommercePost0048 = 'payments_invite_your_team_to_commerce_post_0048';

// ONBF16 Tour
export const selectSalesEmailTemplate = 'select-sales-email-template';
export const createMarketingReportingDashboard = 'create-marketing-reporting-dashboard';
export const createSalesReportingDashboard = 'create-sales-reporting-dashboard';
export const renameDealPipeline = 'rename-deal-pipeline';
export const quickImportContacts = 'quick-import-contacts';

// PAY031
export const learnAboutPayments = 'learn-about-payments';
export const createAPaymentLink = 'create-a-payment-link';

// ONBF025 Tour
export const setupSalesPipeline = 'set-up-deals-pipeline';

// ACQ0143
export const discoverMoreWaysToGetPaid = 'discover-more-ways-to-get-paid';

// POST031
export const paymentsActivationProfessionalServicesCreate = 'payments-activation-professional-services-create';
export const paymentsActivationProfessionalServicesUse = 'payments-activation-professional-services-use';
export const paymentsActivationSubscriptionsCreate = 'payments-activation-subscriptions-create';
export const paymentsActivationSubscriptionsUse = 'payments-activation-subscriptions-use';
export const paymentsActivationPaidContentCreate = 'payments-activation-paid-content-create';
export const paymentsActivationPaidContentUse = 'payments-activation-paid-content-use';
export const paymentsActivationProjectsCreate = 'payments-activation-projects-create';
export const paymentsActivationProjectsUse = 'payments-activation-projects-use';
export const paymentsActivationPhysicalGoodsCreate = 'payments-activation-physical-goods-create';
export const paymentsActivationPhysicalGoodsUse = 'payments-activation-physical-goods-use';
export const paymentsActivationDonationsCreate = 'payments-activation-donations-create';
export const paymentsActivationDonationsUse = 'payments-activation-donations-use';

// PAY042
export const createAnInvoice = 'commerce-create-an-invoice';
export const createPaymentLink = 'commerce-create-a-payment-link';
export const createAQuote = 'commerce-create-a-quote';
export const testCheckout = 'commerce-test-checkout';

// Growth Coaching Playbooks
export const automateEmailFollowUp = 'automate-email-follow-up';
export const automateFormFollowUp = 'automate-form-follow-up';
export const createBloggingCalendar = 'create-blogging-calendar';
export const emailVsColdCall = 'email-vs-cold-call';
export const evaluateWebsiteAccessibility = 'evaluate-website-accessibility';
export const identifyLeadsInCrm = 'identify-leads-in-crm';
export const implementEmailDesign = 'implement-email-design';
export const learnAboutContentHub = 'learn-about-content-hub';
export const learnAboutLiveChat = 'learn-about-live-chat';
export const learnAboutMarketingHub = 'learn-about-marketing-hub';
export const learnAboutSalesHub = 'learn-about-sales-hub';
export const learnAboutServiceHub = 'learn-about-service-hub';
export const learnAboutSmartCrm = 'learn-about-smart-crm';
export const learnHowToQualifyLeads = 'learn-how-to-qualify-leads';
export const leverageTrafficData = 'leverage-traffic-data';
export const mapYourSalesProcess = 'map-your-sales-process';
export const masterBlogWriting = 'master-blog-writing';
export const planEmailMarketing = 'plan-email-marketing';
export const prepareAdvertisingPlan = 'prepare-advertising-plan';
export const sendYourFirstSalesEmail = 'send_your_first_sales_email';
export const setUpDeals = 'set-up-deals';
export const understandConversionPaths = 'understand-conversion-paths';
export const understandCustomerSegments = 'understand-customer-segments';
export const understandingHelpDesk = 'understanding-help-desk';
export const writeCallToAction = 'write-call-to-action';
export const writePersuasiveAds = 'write-persuasive-ads';

// TACQ-001
export const commerceCreateProduct = 'commerce-create-a-product';
export const commerceCreateSubscription = 'commerce-create-a-subscription';
export const commerceReportingDashboard = 'commerce-reporting-dashboard';
export const commerceCollectPayments = 'commerce-collect-payments';