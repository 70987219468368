import Raven from 'raven-js';
import { getQueryParams } from './urls';
import { getTracker, eventNames } from './tracker';
const validateTrackingProperties = properties => {
  if (properties && (typeof properties !== 'object' || Array.isArray(properties))) {
    const errorMessage = 'Invalid properties passed to usage tracker, the value should be a plain object.';
    console.error(errorMessage);
    Raven.captureException(new Error(errorMessage), {
      extra: {
        propertiesValue: properties
      }
    });
  }
};
const assignOnboardingProps = properties => {
  const container = document.querySelector('.user-guide-container');
  if (!container || !container.dataset) {
    return properties;
  }
  const {
    groupKey,
    onboardingKey,
    selectedView
  } = container.dataset;
  return Object.assign({}, properties, {
    groupKey: properties.groupKey || groupKey,
    onboardingKey: properties.onboardingKey || onboardingKey,
    selectedView: properties.selectedView || selectedView
  });
};
export const trackWithOnboardingProps = (eventName, defaultProperties, additionalProperties) => {
  validateTrackingProperties(additionalProperties);
  return getTracker().track(eventName, assignOnboardingProps(Object.assign({}, defaultProperties, additionalProperties)));
};
export const trackPageView = (screen, additionalProperties) => {
  validateTrackingProperties(additionalProperties);
  const {
    via
  } = getQueryParams();
  return getTracker().track(eventNames.pageView, Object.assign({
    screen,
    via
  }, additionalProperties));
};
export const trackTabView = additionalProperties => {
  const {
    via
  } = getQueryParams();
  return trackWithOnboardingProps(eventNames.tabView, null, Object.assign({
    via
  }, additionalProperties));
};
export const trackSectionView = (section, value) => {
  return trackWithOnboardingProps(eventNames.sectionView, {
    section,
    value
  });
};
export const trackModalView = modalName => trackSectionView('modal', modalName);
export const trackUserInteraction = (action, value, additionalProperties) => {
  return trackWithOnboardingProps(eventNames.userInteraction, {
    action,
    value
  }, additionalProperties);
};
export const trackTaskInteraction = (action, taskName, additionalProperties) => {
  return trackWithOnboardingProps(eventNames.taskInteraction, {
    action,
    taskName
  }, additionalProperties);
};

/**
 *
 * Tracker for explore card interactions. Tracks each action against key information about the card to allow for better data clarity.
 *
 * @param {string} action - a short description of the action being taken, for example "start marketing demo", "click back button", "close modal"
 * @param {string} name - name of the card, a short string in kebab case that describes the purpose of the card, ideally mapping to the heading - for example get-to-know-your-crm
 * @param {string} hub - hub that the card relates to [optional - default if not provided will be "common"]
 *                        choose from available views eg "marketing", "crm", "sales", or default "common" if this card doesn't specify a hub
 * @param {string} tier - tier that the card relates to [optional - default if not provided will be "common"]
 *                        choose from "free", "starter", "professional", "enterprise", or default "common" if this card doesn't specify a tier
 * @param {string} experience - experience level that the card relates to [optional - default if not provided will be "common"]
 *                              choose from "none", "crm experience", "hubspot experience", or default "common" if this card doesn't specify an experience level
 * @param {object} additionalProperties - additional properties to be tracked
 */
export const trackExploreCardInteraction = (action, name, hub, tier, experience, additionalProperties) => {
  return trackWithOnboardingProps(eventNames.exploreCardInteraction, {
    action,
    name,
    hub,
    tier,
    experience
  }, additionalProperties);
};
export const trackClickSideNav = view => {
  return trackUserInteraction('click side nav item', view);
};