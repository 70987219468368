import { ONBOARDING_STARTER_SCOPES, ONBOARDING_PRO_SCOPES, ONBOARDING_ENTERPRISE_SCOPES, ALL_ONBOARDING_PAID_SCOPES, PRO_PLUS_ONBOARDING_SCOPES } from '../scopeConstants';
import { excludesAll, includesAny } from './arrayUtils';
export const getIsFreePortal = scopes => {
  return excludesAll(scopes, ALL_ONBOARDING_PAID_SCOPES);
};
export const getIsPaidPortal = scopes => {
  return includesAny(scopes, ALL_ONBOARDING_PAID_SCOPES);
};
export const getIsStarterPortal = scopes => {
  return includesAny(scopes, ONBOARDING_STARTER_SCOPES) && excludesAll(scopes, PRO_PLUS_ONBOARDING_SCOPES);
};
export const getIsFreeOrStarterPortal = scopes => {
  return excludesAll(scopes, PRO_PLUS_ONBOARDING_SCOPES);
};
export const getIsProPortal = scopes => {
  return includesAny(scopes, ONBOARDING_PRO_SCOPES) && excludesAll(scopes, ONBOARDING_ENTERPRISE_SCOPES);
};
export const getIsProPlusPortal = scopes => {
  return includesAny(scopes, PRO_PLUS_ONBOARDING_SCOPES);
};
export const getIsEnterprisePortal = scopes => {
  return includesAny(scopes, ONBOARDING_ENTERPRISE_SCOPES);
};