export let UserType;
(function (UserType) {
  UserType["MARKETING"] = "marketing";
  UserType["SALES"] = "sales";
  UserType["SERVICE"] = "service";
})(UserType || (UserType = {}));
export const UserTypeByJobField = {
  'administration-operations': UserType.SALES,
  sales: UserType.SALES,
  marketing: UserType.MARKETING,
  'services-support': UserType.SERVICE,
  other: UserType.SALES
};
export const UserTypeByJobRole = {
  'customer-service-rep': UserType.SERVICE,
  'sales-director-manager': UserType.SALES,
  'sales-rep': UserType.SALES,
  'administrator-operations': UserType.SALES,
  'marketer-content-creator': UserType.MARKETING,
  'marketing-director-manager': UserType.MARKETING
};