export const MARKETING_STARTER_DEMO = 'marketing-starter-demo';
export const MARKETING_STARTER_DEMO_COMPLETE = 'marketing-starter-demo-complete';
export const SALES_STARTER_DEMO = 'sales-starter-demo';
export const SALES_STARTER_DEMO_COMPLETE = 'sales-starter-demo-complete';
export const MARKETING_FREE_DEMO = 'marketing-free-demo';
export const MARKETING_FREE_DEMO_COMPLETE = 'marketing-free-demo-complete';

// STARTER QUERY PARAMETERS
export const CHECKOUT = 'checkout';

// Parameters attached post-purchase to denote Hubs just purchased, and non-free Hubs the customer had at the time of purchase
export const MARKETING_STARTER = 'marketing-starter';
export const SALES_STARTER = 'sales-starter';
export const SERVICE_STARTER = 'service-starter';
export const CMS_STARTER = 'cms-starter';
export const OPS_STARTER = 'operations-starter';