export const getUrl = (path, options) => {
  const {
    hubletOriginOverride
  } = options || {};
  if (!hubletOriginOverride) {
    return path;
  }
  const originOverride = hubletOriginOverride.replace(/\/$/, '');
  return `${originOverride}/${path.replace(/^\//, '')}`;
};
export const getQueryParam = key => {
  const searchParams = new URLSearchParams(document.location.search);
  return searchParams.get(key);
};