import { useEffect, useState } from 'react';
import { requestDraftSitePagesTotal } from '../clients/requestDraftSitePagesTotal';
import { logSentryException } from '../utils/logSentry';
export const useHasDraftSitePages = () => {
  const [draftsTotal, setDraftsTotal] = useState(0);
  useEffect(() => {
    requestDraftSitePagesTotal().then(setDraftsTotal).catch(e => {
      logSentryException(e);
      setDraftsTotal(0);
    });
  }, []);
  return draftsTotal > 0;
};