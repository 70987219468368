import http from 'hub-http/clients/apiClient';
import getUserInfo from 'hub-http/userInfo';
import PortalIdParser from 'PortalIdParser';
export const getIfPortalHasSeatsAccess = userInfo => {
  return Promise.resolve(userInfo || getUserInfo()).then(({
    user
  }) => {
    var _user$scopes;
    return (user === null || user === void 0 || (_user$scopes = user.scopes) === null || _user$scopes === void 0 ? void 0 : _user$scopes.includes('seats-access')) || false;
  });
};
export const getInviteSeatsInfo = () => {
  return http.get(`app-users/v1/seat-assignments/${PortalIdParser.get()}/seat-info`).then(allSeatsInfo => {
    var _coreSeatInfo$maxAssi;
    const coreSeatInfo = allSeatsInfo.find(seatInfo => seatInfo.seatName === 'core');

    // Portals without seats access will not have 'core' seat info
    if (!coreSeatInfo) {
      return null;
    }
    return {
      inUseSeats: coreSeatInfo === null || coreSeatInfo === void 0 ? void 0 : coreSeatInfo.currentAssignedSeats,
      totalSeats: (_coreSeatInfo$maxAssi = coreSeatInfo === null || coreSeatInfo === void 0 ? void 0 : coreSeatInfo.maxAssignableSeats) !== null && _coreSeatInfo$maxAssi !== void 0 ? _coreSeatInfo$maxAssi : undefined
    };
  });
};