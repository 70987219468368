/**
 * To be kept in sync with the types in user-context:
 * {@link https://git.hubteam.com/HubSpot/user-context/blob/df642846bd2d49bb7dd449f842e071ce198172e9/static/js/onboardingSettings.ts#L9 user-context/static/js/onboardingSettings.ts}
 */
export const ONBOARDING_VIEWS = {
  SALES: 'sales',
  MARKETING: 'marketing',
  SERVICE: 'service',
  CMS: 'cms',
  COMMERCE: 'commerce',
  OPS: 'ops',
  SUITE: 'suite',
  BUSINESS_BOX: 'businessBox',
  USE_CASE: 'useCase'
};

/**
 * To be kept in sync with the types in user-context:
 * {@link https://git.hubteam.com/HubSpot/user-context/blob/df642846bd2d49bb7dd449f842e071ce198172e9/static/js/onboardingSettings.ts#L21 user-context/static/js/onboardingSettings.ts}
 */