import Raven from 'raven-js';
import storeInstance from './storageProvider';
export const deleteSuperstoreValue = key => {
  return storeInstance().then(store => store.delete(key));
};
export function getSuperstoreValue(key) {
  return storeInstance().then(store => store.get(key));
}
export function setSuperstoreValue(key, value) {
  return storeInstance().then(store => store.set(key, value));
}
export const reportSuperstoreError = (key, method, error) => {
  const e = new Error(`Error on ${method} superstore key ${key}`);
  e.stack = error.stack;
  Raven.captureException(e, {
    extra: {
      message: error.message
    }
  });
};