import { useHighestCmsHubEdition, useHighestEdition, useHighestMarketingHubEdition, useHighestSalesHubEdition, useHighestServiceHubEdition } from 'onboarding-scopes';
import { HubEdition } from 'onboarding-scopes/scopeConstants';
import { ONBOARDING_VIEWS } from 'onboarding-settings-client/constants/onboardingViews';
const {
  CMS,
  COMMERCE,
  MARKETING,
  SALES,
  SERVICE
} = ONBOARDING_VIEWS;

// TODO - remove this when useCase is removed from the OnboardingViews
// https://git.hubteam.com/hubspot/growthonboardingplanningsetup/issues/3953
export const useHighestProductLevelEditions = () => {
  const cmsEdition = useHighestCmsHubEdition();
  const commerceEdition = useHighestEdition();
  const marketingEdition = useHighestMarketingHubEdition();
  const salesEdition = useHighestSalesHubEdition();
  const serviceEdition = useHighestServiceHubEdition();
  return {
    [CMS]: cmsEdition,
    [COMMERCE]: commerceEdition,
    [MARKETING]: marketingEdition,
    [SALES]: salesEdition,
    [SERVICE]: serviceEdition
  };
};
export const useTier = selectedView => {
  return useHighestProductLevelEditions()[selectedView];
};
export const useIsFreeTier = selectedView => {
  const tier = useTier(selectedView);
  return tier === HubEdition.FREE;
};
export const useI18nTierKeys = selectedView => {
  const tier = useTier(selectedView);
  const capitalizedTier = (tier || HubEdition.FREE).charAt(0).toUpperCase() + tier.slice(1);

  // `tier` is used for tracking purposes
  // `capitalizedTier` is used to create i18n key
  return {
    tier,
    capitalizedTier
  };
};