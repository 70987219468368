import { useEffect } from 'react';
import { logExposure } from '../api/experimentsApis';
import { useIsGPRO0003 } from 'growth-pro-utils-lib/hooks/useIsGPRO0003';
import { useTreatmentParameterValue } from 'setup-guide-components/hooks/experiments';
const ExperimentExposures = () => {
  // EXAMPLE BELOW 👇
  // const isEligibleForOnbc007 = useIsEligibleForOnbc007();

  // useEffect(() => {
  //   if (isEligibleForOnbc007) {
  //     logExposure(ONBC007_TREATMENT_KEY);
  //   }
  // }, [isEligibleForOnbc007]);

  const variant = useTreatmentParameterValue('gpro-0003', 'group');
  const {
    isGPRO0003Audience
  } = useIsGPRO0003(variant || '');
  useEffect(() => {
    if (isGPRO0003Audience) {
      logExposure('gpro-0003');
    }
  }, [isGPRO0003Audience]);
  return null;
};
export default ExperimentExposures;