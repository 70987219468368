import http from 'hub-http/clients/apiClient';
import { ONBOARDING_SETTINGS_URL } from '../constants/apiUrls';
import { getHubletUrl, getDeleteUrl } from '../utils/urlUtils';
export function getSettings(options) {
  return http.get(getHubletUrl(ONBOARDING_SETTINGS_URL, options));
}
export function updateSettings({
  onboardingSettings,
  options
}) {
  const onboardingSettingsRequestUrl = getHubletUrl(ONBOARDING_SETTINGS_URL, options);
  return http.patch(onboardingSettingsRequestUrl, {
    data: onboardingSettings
  });
}
export function removeSettings({
  view,
  key
}, options) {
  return http.delete(getHubletUrl(getDeleteUrl({
    path: ONBOARDING_SETTINGS_URL,
    view,
    key
  }), options));
}
export function removeOnboardingView({
  view
}) {
  return http.delete(`${ONBOARDING_SETTINGS_URL}/view/${view}`);
}