import { ONBOARDING_SETTINGS_ERROR, ONBOARDING_SETTINGS_RECEIVED, ONBOARDING_VIEW_ACTIVATED } from '../actions/ActionTypes';
const onboarding = (state = {}, action) => {
  switch (action.type) {
    case ONBOARDING_SETTINGS_RECEIVED:
      return Object.assign({}, state, {
        onboardingSettings: action.onboardingSettings
      });
    case ONBOARDING_SETTINGS_ERROR:
      return Object.assign({}, state, {
        onboardingSettingsError: action.error
      });
    case ONBOARDING_VIEW_ACTIVATED:
      {
        const {
          onboardingSettings
        } = state;
        return Object.assign({}, state, {
          onboardingSettings: Object.assign({}, onboardingSettings, {
            selectedView: action.selectedView
          })
        });
      }
    default:
      return state;
  }
};
export default onboarding;