/* hs-eslint ignored failing-rules */
/* eslint-disable @typescript-eslint/no-floating-promises */

import { CONSOLIDATED_STARTER_GROUP_KEYS, SUITE_OVERVIEW_GROUP_KEYS } from 'getting-started-shared-tasks/gettingStartedGroupKeys';
import {
// Consolidated Keys
ONBOARDING_STARTER_SALES, ONBOARDING_STARTER_MARKETING, ONBOARDING_STARTER_SERVICE, ONBOARDING_STARTER_CMS } from 'getting-started-shared-tasks/onboardingKeys';
import Raven from 'raven-js';
import { setDefaultGroupKey, setGettingStartedGroupKey } from 'user-context/onboarding';
import { ONBOARDING_VIEWS, setHubOnboardingSettings, setOnboardingSettings } from 'user-context/onboardingSettings';
import { DEFAULT_FREE_ONBOARDING_SETTINGS_VIEWS } from './constants/onboardingSettingsConstants';
import { enrollInUserGuide } from './gettingStartedEnrollment';
const {
  SALES,
  MARKETING,
  SERVICE,
  CMS
} = ONBOARDING_VIEWS;
const {
  SUITE_STARTER_OVERVIEW
} = SUITE_OVERVIEW_GROUP_KEYS;
const {
  starterMarketingView,
  starterSalesView,
  starterServiceView,
  starterContentView
} = CONSOLIDATED_STARTER_GROUP_KEYS;
export const enrollInCrmSuiteStarterOnboarding = async (userId, selectedView) => {
  const onboardingSettings = {
    views: {
      [SALES]: {
        groupKey: starterSalesView,
        onboardingKey: ONBOARDING_STARTER_SALES
      },
      [MARKETING]: {
        groupKey: starterMarketingView,
        onboardingKey: ONBOARDING_STARTER_MARKETING
      },
      [SERVICE]: {
        groupKey: starterServiceView,
        onboardingKey: ONBOARDING_STARTER_SERVICE
      },
      [CMS]: {
        groupKey: starterContentView,
        onboardingKey: ONBOARDING_STARTER_CMS
      }
    },
    selectedView
  };
  const newDefaultGroupKey = SUITE_STARTER_OVERVIEW;
  const setOnboardingSettingsPromise = setOnboardingSettings(onboardingSettings, userId);
  const setGettingStartedKeyGroupPromise = setGettingStartedGroupKey(newDefaultGroupKey);
  const enrollInUserGuidePromise = enrollInUserGuide();
  await Promise.all([setOnboardingSettingsPromise, setGettingStartedKeyGroupPromise, enrollInUserGuidePromise]);
};
export const enrollInMultiHubOnboarding = (currentOnboardingSettings, userId, selectedView, selectedViewSettings) => {
  let newOnboardingSettingViews;
  // Merge current onboarding settings with default settings
  if (!currentOnboardingSettings || !currentOnboardingSettings.views) {
    // If there are no current onboarding settings, use the default views
    newOnboardingSettingViews = Object.assign({}, DEFAULT_FREE_ONBOARDING_SETTINGS_VIEWS);
  } else {
    // Make sure all default views are populated
    newOnboardingSettingViews = Object.assign({}, DEFAULT_FREE_ONBOARDING_SETTINGS_VIEWS, currentOnboardingSettings.views);
  }
  const newOnboardingSettings = {
    views: newOnboardingSettingViews,
    selectedView
  };

  // Replace the selected view with the new starter one
  newOnboardingSettings.views[selectedView] = selectedViewSettings;
  setOnboardingSettings(newOnboardingSettings, userId);
  if (selectedViewSettings && selectedViewSettings.groupKey) {
    setGettingStartedGroupKey(selectedViewSettings.groupKey);
  }
  enrollInUserGuide();
};
export const batchEnrollMultiHubOnboarding = (userId, newViewSettings, selectedView) => {
  if (Object.keys(newViewSettings).length === 0) {
    return Promise.resolve();
  }

  // Replace the view settings with the new starter one
  const newOnboardingSettings = {
    views: Object.assign({}, newViewSettings),
    selectedView
  };
  return setOnboardingSettings(newOnboardingSettings, userId).then(() => {
    const selectedViewGroupKey = newOnboardingSettings.views[selectedView].groupKey;
    const requests = [enrollInUserGuide()];
    if (selectedViewGroupKey) {
      requests.push(setDefaultGroupKey(selectedViewGroupKey));
    }
    return Promise.all(requests);
  }).catch(error => {
    Raven.captureException(error);
  });
};
export const enrollInHubOnboarding = (userId, hubType, onboardingSettings) => {
  setHubOnboardingSettings(hubType, onboardingSettings, userId);
  enrollInUserGuide();
};