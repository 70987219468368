import { useCallback, useEffect, useState } from 'react';
import Raven from 'raven-js';
import { deleteDomainBasedInviteLink, generateDomainBasedInviteLink, getDomainBasedInviteLink, getIsDomainBasedInvitesEnabled } from '../api/domainInviteApi';
import { getHubInfo } from '../api/hubsApi';
let promises;
const getPromises = () => {
  if (!promises) {
    promises = [getHubInfo(), getDomainBasedInviteLink(), getIsDomainBasedInvitesEnabled()];
  }
  return promises;
};
export const useDomainInvite = (showDomainInvite = true) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isEnablingDomainInvite, setIsEnablingDomainInvite] = useState(false);
  const [inviteInfo, setInviteInfo] = useState({});
  const [domainName, setDomainName] = useState(undefined);
  const [enabledInSettings, setEnabledInSettings] = useState(false);
  const enableDomainLink = useCallback(() => {
    setIsEnablingDomainInvite(true);
    generateDomainBasedInviteLink().then(domainBasedInvite => {
      setInviteInfo(domainBasedInvite);
    }).catch(error => Raven.captureException(error)).finally(() => {
      setIsEnablingDomainInvite(false);
    });
  }, []);
  const disableDomainLink = useCallback(tokenToDelete => {
    setIsEnablingDomainInvite(true);
    deleteDomainBasedInviteLink(tokenToDelete).then(domainBasedInvite => {
      setInviteInfo(domainBasedInvite);
    }).catch(error => Raven.captureException(error)).finally(() => {
      setIsEnablingDomainInvite(false);
    });
  }, []);
  useEffect(() => {
    if (!showDomainInvite) {
      return;
    }
    setIsLoading(true);
    Promise.allSettled(getPromises()).then(([hubDetails, domainBasedInvite, domainInviteSettings]) => {
      if (domainBasedInvite.status === 'fulfilled') {
        setInviteInfo(domainBasedInvite.value);
      }
      if (hubDetails.status === 'fulfilled') {
        setDomainName(hubDetails.value.domain);
      }
      if (domainInviteSettings.status === 'fulfilled') {
        setEnabledInSettings(domainInviteSettings.value.domainBasedInvitesEnabled);
      }
    }).catch(error => Raven.captureException(error)).finally(() => {
      setIsLoading(false);
    });
  }, [showDomainInvite]);
  return {
    isLoading,
    domainName,
    enabledInSettings,
    inviteLink: inviteInfo.inviteUrl,
    linkStatus: inviteInfo.state,
    token: inviteInfo.token,
    isEnablingDomainInvite,
    enableDomainLink,
    disableDomainLink
  };
};
export const preloadDomainInvite = showDomainInvite => {
  if (!showDomainInvite) {
    return [Promise.resolve()];
  }
  return getPromises();
};

// Only exported for testing
export const resetPromises = () => {
  promises = undefined;
};