'use es6';

import { _isUsingChrome } from '../utils/isUsingChrome';
import { isFunction } from '../utils/Utils';
class ChromeExtensionInstallHelpers {
  constructor() {
    this.authorizedWebstoreDomain = null;
    this.extensionUrl = null;
    this.isUsingChrome = _isUsingChrome();
    this._isExtensionInstalled = false;
    this.setup();
  }

  // Deprecated: no longer necessary to have a mock install process, since tryExtensionInstall just opens a new window.
  installDebug() {
    return false;
  }
  onInstallSuccess(callback) {
    return () => {
      this._isExtensionInstalled = true;
      if (isFunction(callback)) {
        callback();
      }
    };
  }
  tryExtensionInstall(onSuccess) {
    onSuccess = this.onInstallSuccess(onSuccess);
    window.open(this.extensionUrl, '_blank');
    return onSuccess();
  }
}
export default ChromeExtensionInstallHelpers;