export const DISMISSED_TASK_CARDS = 'dismissedUserGuideTaskCards';
export const getDismissedTaskCards = storage => {
  const dismissedCardsValue = storage.getItem(DISMISSED_TASK_CARDS);
  return Array.isArray(dismissedCardsValue) ? dismissedCardsValue : [];
};
export const dismissTaskCard = (storage, groupKey) => {
  const previouslyDismissedCards = getDismissedTaskCards(storage);
  if (previouslyDismissedCards.includes(groupKey)) {
    return previouslyDismissedCards;
  }
  const dismissedCards = [...previouslyDismissedCards, groupKey];
  storage.setItem(DISMISSED_TASK_CARDS, dismissedCards);
  return dismissedCards;
};