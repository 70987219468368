import { getPortalId } from '../utils/getPortalId';
const PARAMETER_KEY = 'group';
export const EXPERIMENT_GROUP = {
  CONTROL: 'control',
  VARIANT: 'variant'
};
export const EXPERIMENT_ID = {
  GPRO_0004: 'gpro-0004',
  GPRO_0003: 'gpro-0003',
  GPRO_0005: 'gpro-0005',
  GPRO_0006: 'gpro-0006',
  GPRO_0007: 'gpro-0007'
};
export const TREATMENTS_SCHEMA = {
  [EXPERIMENT_ID.GPRO_0004]: {
    identifier: getPortalId(),
    parameters: {
      [PARAMETER_KEY]: Object.values(EXPERIMENT_GROUP)
    }
  },
  [EXPERIMENT_ID.GPRO_0003]: {
    identifier: getPortalId(),
    parameters: {
      [PARAMETER_KEY]: ['control', 'variation']
    }
  },
  [EXPERIMENT_ID.GPRO_0005]: {
    identifier: getPortalId(),
    parameters: {
      [PARAMETER_KEY]: ['control', 'variation']
    }
  },
  [EXPERIMENT_ID.GPRO_0006]: {
    identifier: getPortalId(),
    parameters: {
      [PARAMETER_KEY]: ['control', 'variation']
    }
  },
  [EXPERIMENT_ID.GPRO_0007]: {
    identifier: getPortalId(),
    parameters: {
      [PARAMETER_KEY]: ['control', 'variation']
    }
  }
};