import { configureTicketAutomation, createBuyerPersona, createCustomProperty, createSalesPlanTemplate, downloadMobileApp, freeSeoCourse, helpDeskCustomizeTickets, helpDeskGetStarted, learnAboutForms, visitHubspotMarketplace, websiteGraderLink, wordpressAcademyCourse, wordpressMarketingDemo, learnCreateEbook, learnDesignSalesProgress, learnFollUpEmail, learnVirtualConference, createBlogTeasers, translateContent, setupTicketPipeline, paymentsCustomizeCheckout, createBloggingCalendar, emailVsColdCall, evaluateWebsiteAccessibility, identifyLeadsInCrm, implementEmailDesign, learnAboutContentHub, learnAboutLiveChat, learnAboutMarketingHub, learnAboutSalesHub, learnAboutServiceHub, learnAboutSmartCrm, learnHowToQualifyLeads, leverageTrafficData, mapYourSalesProcess, masterBlogWriting, prepareAdvertisingPlan, setUpDeals, understandConversionPaths, understandCustomerSegments, understandingHelpDesk, writeCallToAction, writePersuasiveAds, planEmailMarketing, createABlogPost } from './taskNames';
export const TASKS_AUTO_COMPLETE_WHEN_STARTED = [createCustomProperty, learnAboutForms, visitHubspotMarketplace, wordpressAcademyCourse, wordpressMarketingDemo,
// Business box tasks:
createBuyerPersona, createSalesPlanTemplate, downloadMobileApp, freeSeoCourse, learnCreateEbook, learnDesignSalesProgress, learnFollUpEmail, learnVirtualConference, websiteGraderLink,
// Content hub tasks:
createBlogTeasers, translateContent,
// Help desk tasks:
configureTicketAutomation, helpDeskCustomizeTickets, helpDeskGetStarted, setupTicketPipeline,
// commerce-home-ui tasks
paymentsCustomizeCheckout,
// Growth Coaching Playbook tasks:
learnAboutSmartCrm, learnAboutContentHub, masterBlogWriting, createBloggingCalendar, leverageTrafficData, evaluateWebsiteAccessibility, learnAboutMarketingHub, writeCallToAction, understandConversionPaths, writePersuasiveAds, prepareAdvertisingPlan, learnAboutSalesHub, mapYourSalesProcess, identifyLeadsInCrm, emailVsColdCall, learnHowToQualifyLeads, understandCustomerSegments, implementEmailDesign, learnAboutServiceHub, understandingHelpDesk, learnAboutLiveChat, setUpDeals, planEmailMarketing, createABlogPost];