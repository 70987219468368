import { useContext } from 'react';
import ExperimentContext from '../contexts/ExperimentContext';
const useTreatments = () => {
  const {
    hasError,
    isFetching,
    hasFetched,
    treatments
  } = useContext(ExperimentContext);
  const isExperimentsReady = !hasError && !isFetching && hasFetched;
  return isExperimentsReady ? treatments : {};
};
export const useTreatmentParameterValue = (treatmentKey, parameterKey) => {
  const treatments = useTreatments();
  if (!treatments[treatmentKey] || !treatments[treatmentKey].parameters) {
    return null;
  }
  const {
    parameters
  } = treatments[treatmentKey];
  const parameterValue = parameters[parameterKey];
  return parameterValue;
};