import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Metrics } from '../../utils/metrics';
const SetupMetrics = () => {
  const taskError = useSelector(state => state.task.error);
  const taskProgressError = useSelector(state => state.taskProgress.error);
  useEffect(() => {
    if (taskError) {
      Metrics.counter('load-tasks-failed').increment();
    }
  }, [taskError]);
  useEffect(() => {
    if (taskProgressError) {
      Metrics.counter('load-tasks-progress-failed').increment();
    }
  }, [taskProgressError]);
  return null;
};
export default SetupMetrics;