import { getFullUrl } from 'hubspot-url-utils';
export const prefetchDataImportPage = () => {
  const pageRoute = 'onboarding-data-import-ui-prefetch';
  // Prevent duplicate appending iframe
  if (document.getElementById(pageRoute)) {
    return;
  }
  const iframe = document.createElement('iframe');
  iframe.id = pageRoute;
  iframe.src = `${getFullUrl('app')}/${pageRoute}`;
  iframe.style.display = 'none';
  document.body.appendChild(iframe);
};