/* hs-eslint ignored failing-rules */
/* eslint-disable hubspot-dev/no-unsafe-storage */

import http from 'hub-http/clients/apiClient';
import { changeEnrollmentOnboardingSettings } from './api/onboardingSettings';
const ONBOARDING_SETTINGS_URI = `onboarding-settings/v1/user-guide-enrollment`;
export function enrollInUserGuide(options) {
  return changeEnrollmentOnboardingSettings(true, options);
}
export function unenrollInUserGuide(options) {
  return changeEnrollmentOnboardingSettings(false, options);
}
export function isEnrollParamPresent() {
  return window.location.search.indexOf('enroll=true') >= 0;
}
export function isUserEnrolledInUserGuide() {
  return http.get(ONBOARDING_SETTINGS_URI).then(({
    enrolledInUserGuide
  }) => enrolledInUserGuide);
}