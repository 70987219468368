import I18n from 'I18n';
import { addTrackingCode } from 'getting-started-shared-tasks/taskNames';
import { getIsWordpress } from 'setup-guide-api/api/wordpressApi';
const setTaskCopyToWordpressCopy = task => {
  task.name = I18n.text('app.wordpress_task.name');
  task.description = I18n.text('app.wordpress_task.description');
  task.linkText = I18n.text('app.wordpress_task.linkText');
  task.link = '/add-tracking-code/wordpress';
};
export const mutateTrackingCodeTaskCopy = async data => {
  const addTrackingCodeTask = data === null || data === void 0 ? void 0 : data.taskMap[addTrackingCode];
  try {
    var _await$getIsWordpress;
    if (Boolean(addTrackingCodeTask) && (_await$getIsWordpress = await getIsWordpress()) !== null && _await$getIsWordpress !== void 0 && _await$getIsWordpress.hasWordPress) {
      setTaskCopyToWordpressCopy(addTrackingCodeTask);
    }
    return data;
  } catch (err) {
    return data;
  }
};