import { useMemo } from 'react';
import { useSelector } from 'react-redux';
export const useAuthData = () => {
  return useSelector(state => state.auth);
};
export const useDemographics = () => {
  return useSelector(state => state.auth.demographics);
};
export const useGates = () => {
  const {
    gates = []
  } = useAuthData();
  return gates;
};
export const useIsUngated = gateKey => {
  const {
    gates = []
  } = useAuthData();
  return gates.includes(gateKey);
};
export const useScopes = () => {
  return useSelector(state => state.auth.user.scopes);
};
export const useHasAnyScope = scopes => {
  const userScopes = useSelector(state => state.auth.user.scopes);
  return useMemo(() => scopes.some(scope => userScopes.includes(scope)), [scopes, userScopes]);
};

/**
 * Unseated is defined as a user on a portal which has the seats-access scope, and the view-only seat.
 * A user who doesn't have the scope isn't "unseated" because seats has not yet rolled out to that portal.
 */
export const useIsUnseated = () => {
  const scopes = useSelector(state => state.auth.user.scopes);
  const seats = useSelector(state => state.auth.seats);
  const isScopedForSeats = scopes.includes('seats-access');
  const isUnseated = seats.includes('view-only');
  return isScopedForSeats && isUnseated;
};