'use es6';

import PortalIdParser from 'PortalIdParser';
const hubFields = ['', 'salesProductLevel', 'paidHub', 'marketingProductLevel', 'serviceProductLevel', 'addonProducts', 'parcelNames', 'companySize'];
const userFields = ['', 'paidSeats'];
const query = hubFields.join('&hubField=') + userFields.join('&hubUserField=');
export default (({
  apiClient
}) => {
  return apiClient.get(`iris-read/v1/app?hubId=${PortalIdParser.get()}${query}`);
});