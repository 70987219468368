import hubApiClient from 'hub-http/clients/apiClient';
import { getRealTypeOf } from 'usage-tracker-core/common/helpers';
export const getHubPrivacySettings = async () => {
  const response = await hubApiClient.get('data-privacy-settings/hub-settings');
  if (getRealTypeOf(response) === 'object') {
    // @ts-expect-error - the method above guarantee's
    // that the response above is an object and not null
    return response;
  }
  throw new Error('Failed to retrieve Hub Privacy settings');
};