import events from '../../events.yaml';
export const eventNames = Object.keys(events).reduce((result, name) => {
  result[name] = name;
  return result;
}, {});
let trackerInstance = null;
export const setClonedTracker = tracker => {
  const clonedTracker = tracker.clone({
    preserveTrackerEvents: false,
    preserveTrackerProperties: false,
    trackerName: 'growth-data-modal',
    events
  });
  trackerInstance = clonedTracker;
};
export const getTracker = () => {
  return trackerInstance;
};
export const trackPageview = ({
  screen,
  subscreen,
  app
}) => {
  const tracker = getTracker();
  if (tracker) {
    tracker.track(eventNames.pageView, {
      screen,
      subscreen,
      app
    });
  }
};
export const trackUserInteraction = ({
  action,
  value,
  app
}) => {
  const tracker = getTracker();
  if (tracker) {
    tracker.track(eventNames.userInteraction, {
      action,
      value,
      app
    });
  }
};