'use es6';

import { fetchRegisterAttributes } from '../apis/UsersApi';
import { VSTO_KEY, O365_KEY } from '../constants/AttributeKeys';
import SidekickExtensionInstallHelpers from '../SidekickExtensionInstallHelpers';

// This method consolidates the checks for determining whether any of the various extensions are installed.
// Since both the O365 and VSTO methods check a user attribute, we can make one call here instead of two.

export const anyExtensionInstalled = () => {
  return fetchRegisterAttributes().then(attrs => {
    return attrs[O365_KEY] || attrs[VSTO_KEY] || SidekickExtensionInstallHelpers.hasExtension();
  }).catch(() => {
    // Even if the network call fails, we can check chrome ext status
    return SidekickExtensionInstallHelpers.hasExtension();
  });
};