'use es6';

import EarlyRequester from './EarlyRequester';
import Iris from './Iris';
import User from './User';
import Survey from './Survey';

/**
 * @param {object} config - contains `apiClient` and `portalId` which can be used to initialize
 **/
export const initializeAll = config => {
  const promises = [EarlyRequester(config), Iris(config), User(config)];
  if (config.type) {
    promises.push(Survey(config));
  }
  return Promise.all(promises).then(([early, iris, user, survey]) => {
    return {
      EarlyRequester: early,
      Iris: iris,
      User: user,
      Survey: survey
    };
  });
};