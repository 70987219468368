import SentryManager from '../manager/SentryManager';
export const localStorage = {
  getItem: key => {
    try {
      return window.localStorage.getItem(key);
    } catch (error) {
      return null;
    }
  },
  setItem: (key, value) => {
    try {
      window.localStorage.setItem(key, value);
    } catch (error) {
      // ignore error
    }
  },
  removeItem: key => {
    try {
      window.localStorage.removeItem(key);
    } catch (error) {
      // ignore error
    }
  }
};
export const sessionStorage = {
  setItem(key, value) {
    try {
      window.sessionStorage.setItem(key, value);
    } catch (error) {
      SentryManager.reportSentry('setSessionStorage', error.message, {
        key,
        value
      });
    }
  },
  getItem(key) {
    try {
      return window.sessionStorage.getItem(key);
    } catch (error) {
      SentryManager.reportSentry('getSessionStorage', error.message, {
        key
      });
      return null;
    }
  },
  removeItem(key) {
    try {
      window.sessionStorage.removeItem(key);
    } catch (error) {
      SentryManager.reportSentry('removeSessionStorage', error.message, {
        key
      });
    }
  }
};
export const parseJSON = candidateString => {
  if (candidateString === null) {
    return null;
  }
  try {
    return JSON.parse(candidateString);
  } catch (error) {
    SentryManager.reportSentry('parseJSON', error.message, {
      candidateString
    });
    return null;
  }
};