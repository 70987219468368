import * as sessionReplay from '@amplitude/session-replay-browser';
import * as browserHelper from 'usage-tracker-core/client/browserHelper';
import * as defaultTrackers from 'usage-tracker-core/common/defaultTrackers';
import { debounce } from 'usage-tracker-core/common/helpers';
import * as srConstants from './constants';
import * as srUtils from './utils';

// Generates a config for Session Replay based on provided options and environment variables.
const getSessionReplayConfig = (options = {}) => {
  const {
    // The sample rate of the session replay events. From 0-1 (1 = 100%, 0 = 0%)
    sampleRate = srConstants.DEFAULT_SAMPLE_RATE,
    // If you want to opt-out programmatically from the session replays
    optOut = false,
    // Which elements to block (remove the elements) for PII reasons
    blockSelectors = [],
    // Which elements to mask with ***** (hide with a placeholder) for PII reasons
    maskSelectors = [],
    // Which elements to unmask (restore original content)
    unmaskSelectors = []
  } = options;
  const sessionReplaySettings = {
    // We allow Apps to opt-out of Session Replay within their instances when wrapping
    // on `withSessionReplay` method. (Assuming if they want to opt-out) on specific things
    // such as, QA environment, or specific Portals, etc.
    optOut,
    // If no sample-rate is provided, we default to sampling 1% of the sessions
    // @see https://www.docs.developers.amplitude.com/session-replay/sdks/standalone/#sampling-rate
    sampleRate,
    // The HAmplitude Session ID, for all trackers this will be a 1:1 match
    // to what we ingest, process and send to Amplitude on our own Events
    sessionId: defaultTrackers.getSessionId(),
    // The HAmplitude Session ID, this will be a 1:1 match on most of the trackers.
    // Any tracking client that uses UTK as an identifier will have a non-matching device_id
    // since we replace the Device ID to the UTK for UTK-based identified events
    // Purely Anonymous (deviceId) and In-App trackers (email and portalId-based) trackers
    // will have always an 1:1 match to what we ingest, process and send to Amplitude on our own Events
    deviceId: defaultTrackers.getDeviceId(),
    // Allows us to mask specific elements from being sent to Amplitude for PII reasons
    // We by default include a bunch of predefined selectors, but Apps should add extra selectors on their own discretion
    privacyConfig: {
      blockSelector:
      // All the elements to be blocked by default on the SDK-level
      srConstants.DEFAULT_PII_SELECTORS.BLOCK.concat(blockSelectors),
      maskSelector:
      // All the elements to be masked by default on the SDK-level
      srConstants.DEFAULT_PII_SELECTORS.MASK.concat(maskSelectors),
      unmaskSelector:
      // All the elements to be unmasked by default on the SDK-level
      srConstants.DEFAULT_PII_SELECTORS.UNMASK.concat(unmaskSelectors),
      // The Conservative Setting by default will mask all text and all form fields
      // including HTML text, user input, links, etc.
      defaultMaskLevel: 'conservative'
    }
  };

  // If the user requests debugMode, which can be useful for test environments, or debugging its behaviour locally
  // or simply forcing Session Replay to run, we enable debug mode on the Session Replay level
  // This can be useful for observing sessions and many other things.
  if (!browserHelper.isDebugEnabled) {
    return sessionReplaySettings;
  }
  return Object.assign({}, sessionReplaySettings, {
    // Enables Debug Mode on Session Replay-level
    debugMode: true,
    // Log Level 4 = Debug Logs
    // See: `@amplitude/session-replay-browser/index.d.ts#LogLevel`
    logLevel: 4,
    // We force everyone to not be opted out when debug is enabled
    optOut: false,
    // We force the sample rate to be 100% when debug is enabled
    sampleRate: 1
  });
};
const withSessionReplay = (tracker, options = {}) => {
  const {
    // The AbortSignal to be used for shutting down the Session Replay instance
    abortSignal,
    // The  timeout for Session Replay to be shutdown automatically due to `usage-tracker-js` inactivity
    inactivityTimeout = srConstants.DEFAULT_SHUTDOWN_TIMEOUT
  } = options;
  if (browserHelper.isDebugEnabled) {
    console.warn('[usage-tracker-session-replay] debug mode enabled.');
  }

  // which includes if we're running on Portal 53 which should be disabled by default
  // or if running on a Selenium environment (which is a testing environment)
  // or if running on Jasmine (which is a testing environment)
  const isDisallowedEnvironment = srUtils.getIsDisallowedEnvironment();

  // Verifies if the current running environment is sensitive and should be blocked
  // since on sensitive environments we should not run Session Replay at all
  const isSensitiveEnvironmentPromise = srUtils.getIsSensitiveEnvironment();

  // Generates the Session Replay Config based on the provided options
  const sessionReplayConfig = getSessionReplayConfig(options);

  // Retrieves the correct Amplitude API Key based on the current environment
  // Note that these do not change during runtime, so it is safe to call during the
  // initialisation of this function.
  const sessionReplayApiKey = browserHelper.isProdDeployment ? srConstants.AMPLITUDE_API_KEYS.HUBSPOT_PRODUCT : srConstants.AMPLITUDE_API_KEYS.HUBSPOT_PRODUCT_QA;

  // This holds the current Promise, to prevent `.track()` from instantiating multiple promises
  // while one is still being resolved. It also ensures that we only have only Promise active
  // whilst Session Replay is bootstrapping or bootstrapped.
  let sessionReplayPromise;
  const getSessionReplayPromise = async () => {
    if (browserHelper.isDebugEnabled) {
      console.debug('[usage-tracker-session-replay] initialising.');
    }

    // Awaits for the resolution of the sensitive environment check
    const isSensitiveEnvironment = await isSensitiveEnvironmentPromise;

    // Note that skipping Session Replay bootstrap (`init`) call does not affect
    // calling other `sessionReplay` methods, such as `getSessionReplayProperties`
    // They will simply do nothing if Session Replay is not bootstrapped.
    const shouldSkipSessionReplay = isSensitiveEnvironment || isDisallowedEnvironment;

    // If the environment is not sensitive/disallowed or debug mode is enabled
    // then we bootstrap the Session Replay instance; Otherwise, Session Replay
    // will not be bootstrapped due to the environment not being allowed.
    if (!shouldSkipSessionReplay || browserHelper.isDebugEnabled) {
      // Creates the Session Replay instance. Note that this returns an object
      // with a Promise which is the actual SessionReplay instance
      const sessionReplayInitResult = sessionReplay.init(sessionReplayApiKey, sessionReplayConfig);

      // Awaits for the Session Replay instance to be bootstrapped
      await sessionReplayInitResult.promise;
    }
  };

  // This method is used to shutdown the Session Replay instance
  // and signal that a new bootstrap must be called before using Session Replay again
  const shutdownSessionReplay = (alllowReinitialisation = true) => {
    var _sessionReplayPromise;
    if (browserHelper.isDebugEnabled) {
      console.debug('[usage-tracker-session-replay] shutting down.');
    }

    // If the AbortSignal is aborted, we shutdown the Session Replay instance
    // to prevent any further events from being sent to Amplitude
    sessionReplay.shutdown();

    // Rests the `session replay` promise to `undefined` to allow for a new Session Replay instance
    // to be bootstrapped as soon as `.track` is called again
    sessionReplayPromise = alllowReinitialisation ? undefined : // This handles the scenario, where if the `.abort()` is called before the Session Replay
    // instance was created, the Promise does not exist yet, meaning a `.track()` call would
    // actually trigger the Session Replay to be bootstrapped, which is not what we want.
    (_sessionReplayPromise = sessionReplayPromise) !== null && _sessionReplayPromise !== void 0 ? _sessionReplayPromise : Promise.resolve();
  };

  // Debounces the `shutdownSessionReplay` method to be called after the `inactivityTimeout`
  const inactivityShutdownDebouncer = debounce(shutdownSessionReplay, inactivityTimeout);

  // Creates a Proxy for an actual `usage-tracker` instance that will inject Amplitude's Session Replay
  // and do all necessary logic to ensure events are properly configured and tracked
  const applyProxy = trackerInstance => {
    return new Proxy(trackerInstance, {
      get: (target, property) => {
        // Wraps the native track method (for non-standalone tracking only at the moment)
        // that attaches Session Replay Properties
        if (property === 'track') {
          // @TODO: Handle usage-tracker-js standalone mode for events.yaml sunset in the near future
          return (key, properties = {}) => {
            var _sessionReplayPromise2;
            sessionReplayPromise = // Ensures that only one Promise is active at a time, preventing concurrent/parallel/async
            // resolutions of the initialisation and environment check of Session Replay
            (_sessionReplayPromise2 = sessionReplayPromise) !== null && _sessionReplayPromise2 !== void 0 ? _sessionReplayPromise2 : getSessionReplayPromise();

            // Amplitude Engineering mentioned that their `.init` method will never reject
            // Hence we don't need a `.catch` here, but we should handle the Promise resolution
            void sessionReplayPromise.then(() => {
              // Initiates (and resets) the shutdown debouncer, every time a `.track()` is called
              inactivityShutdownDebouncer();

              // Ensures that the Session Replay Engine has successfully bootstrapped
              // Before actually calling the .track() method, so that the events get tracked
              // with the proper session replay properties
              // Retrieves the latest deviceId and sessionId from the default trackers
              const deviceId = defaultTrackers.getDeviceId();
              const sessionId = defaultTrackers.getSessionId();

              // An `usage-tracker` session might change during time (device id's could also change)
              // hence if the sessionId differs, we update the Session Replay instance with the new
              // session id's and device id's so that the events get properly attached
              // Note: Calling `setSessionId` will trigger a call to Amplitude's /config endpoint
              // for Session Replay -- not sure why this is done, but it's how the SDK works
              // Note: Only checking if Session ID has changed is enough, because on `usage-tracker-core`
              // if the `device_id` changes, the `session_id` will also change.
              if (sessionId !== sessionReplay.getSessionId()) {
                sessionReplay.setSessionId(sessionId, deviceId);
              }

              // Retrieves the Session Replay Properties that should be injected within an Event
              const srProperties = sessionReplay.getSessionReplayProperties();

              // Note that the Session Replay Properties might get "flagged" as unknown properties
              target.track(key, Object.assign({}, properties, srProperties));
            });
          };
        }

        // Ensures that when cloning a tracker instance, Session Replay
        // is still attached to the new cloned instance
        if (property === 'clone') {
          return cloneConfig => {
            return applyProxy(target.clone(cloneConfig));
          };
        }

        // Fallbacks to default behaviour for all other operations
        return target[property];
      }
    });
  };

  // Allows custom AbortSignal to be passed for shutting down the Session Replay instance
  abortSignal === null || abortSignal === void 0 || abortSignal.addEventListener('abort', () => {
    // Manual shutdowns prevent Session Replay from re-bootstrapping
    shutdownSessionReplay(false);
  });

  // Applies a Proxy to the current Usage Tracker instance
  return applyProxy(tracker);
};
export { withSessionReplay };