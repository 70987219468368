import { createClient } from 'laboratory-lib';
import { QUICK_FETCH_TREATMENT_KEY } from '../constants/taskConstants';
import PortalIdParser from 'PortalIdParser';
import experimentSchemas from '../../experiments.yaml';
const experiments = experimentSchemas;
export const TreatmentsClient = {
  client: null,
  get() {
    if (!this.client) {
      this.client = createClient({
        quickFetchLabel: QUICK_FETCH_TREATMENT_KEY,
        experiments,
        identifiers: {
          portalId: PortalIdParser.get()
        }
      });
    }
    return this.client;
  }
};
export function getExperiments() {
  return new Promise(TreatmentsClient.get().resolve);
}
export function logExposure(treatmentKey) {
  return TreatmentsClient.get().logExposure(treatmentKey);
}