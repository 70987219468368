import http from 'hub-http/clients/apiClient';
import { DEFAULT_USER_GROUP_URL, TASK_BY_TASKID_URL, TASKS_URL, TASKS_URL_V2 } from '../constants/apiUrls';
const callHttpGet = (url, query) => {
  if (query) {
    return http.get(url, {
      query
    });
  }
  return http.get(url);
};

/**
 * V1
 */

export const getTasks = query => {
  return callHttpGet(TASKS_URL, query);
};
export const getDefaultGroupForUser = () => {
  return http.get(DEFAULT_USER_GROUP_URL);
};
export const getTaskById = (taskId, query) => {
  return callHttpGet(`${TASK_BY_TASKID_URL}/${taskId}`, query);
};
export const getTasksByGroupKey = (groupKey, query) => {
  return callHttpGet(`${TASKS_URL}/${groupKey}`, query);
};
export const setDefaultGroupForUser = groupKey => {
  return http.post(DEFAULT_USER_GROUP_URL, {
    groupKey
  });
};
export const skipTask = taskKey => {
  return http.post(`${TASKS_URL}/${taskKey}/skip`);
};
export const undoSkipTask = taskKey => {
  return http.delete(`${TASKS_URL}/${taskKey}/skip`);
};

/**
 * V2
 */

export const getTasksByGroupKeys = (groupKeys, query) => {
  const fullQuery = Object.assign({}, query || {}, {
    groupKeys
  });
  return callHttpGet(TASKS_URL_V2, fullQuery);
};