import { useCallback, useContext, useEffect, useState } from 'react';
import Raven from 'raven-js';
import { getIfPortalHasSeatsAccess, getInviteSeatsInfo } from '../api/seatsApi';
import InviteSeatsContext from '../contexts/InviteSeatsContext';
export const useInviteSeats = () => {
  const inviteSeatsContext = useContext(InviteSeatsContext);
  const [inviteSeats, setInviteSeats] = useState(inviteSeatsContext || {
    isLoading: true
  });
  const fetchInviteSeats = useCallback(() => {
    getIfPortalHasSeatsAccess().then(hasSeatAccess => hasSeatAccess ? getInviteSeatsInfo() : null).then(seatInfo => {
      const hasSeatAccess = Boolean(seatInfo);
      setInviteSeats(Object.assign({
        fetchInviteSeats,
        hasSeatAccess,
        hasInviteSeats: hasSeatAccess && typeof (seatInfo === null || seatInfo === void 0 ? void 0 : seatInfo.inUseSeats) === 'number' && typeof (seatInfo === null || seatInfo === void 0 ? void 0 : seatInfo.totalSeats) === 'number' ? seatInfo.inUseSeats < seatInfo.totalSeats : true,
        isLoading: false
      }, seatInfo));
    }).catch(error => {
      error.message = 'Error getting invite seats info';
      Raven.captureException(error);
      setInviteSeats({
        fetchInviteSeats,
        isLoading: false,
        hasInviteSeats: true
      });
    });
  }, []);
  useEffect(() => {
    if (inviteSeatsContext) {
      return;
    }
    fetchInviteSeats();
  }, [inviteSeatsContext, fetchInviteSeats]);
  return inviteSeatsContext || inviteSeats;
};