/* hs-eslint ignored failing-rules */
/* eslint-disable hubspot-dev/no-unsafe-storage */

// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'SafeStorage' Remove this comment to see the full error message
import SafeStorage from 'SafeStorage';
class ProjectStorage {
  constructor(params) {
    if (params) {
      this.setup(params);
    }
  }

  /**
   *
   * @param {Object} params
   * @param {Number} params.portalId - Portal id
   * @param {Number} params.userId - User id
   * @param {String} params.project - Project name
   */
  setup({
    portalId,
    project = window.hubspot.bender.currentProject,
    userId
  }) {
    this.portalId = portalId;
    this.userId = userId;
    this.project = project;
    this.userKey = `${portalId}-${userId}`;
  }
  getParsedProjectStorage() {
    if (!this.project) {
      console.warn('Please setup project-storage before getting/setting item.');
      return null;
    }
    const unparsedStored = SafeStorage.getItem(this.project) || '{}';
    const stored = JSON.parse(unparsedStored) || {
      [this.userKey]: {}
    };
    if (!stored[this.userKey]) {
      stored[this.userKey] = {};
    }
    return stored;
  }
  clear() {
    const stored = this.getParsedProjectStorage();
    if (!stored) {
      return;
    }
    delete stored[this.userKey];
    SafeStorage.setItem(this.project, JSON.stringify(stored));
  }
  getItem(key) {
    const stored = this.getParsedProjectStorage();
    if (!stored) {
      return null;
    }
    return stored[this.userKey][key];
  }
  setItem(key, value) {
    const stored = this.getParsedProjectStorage();
    if (!stored) {
      return;
    }
    stored[this.userKey][key] = value;
    SafeStorage.setItem(this.project, JSON.stringify(stored));
  }
}
export default ProjectStorage;