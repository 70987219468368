import { captureNetworkError } from 'growth-onboarding-reliability/utils/raven';
import { undoSkipTask as deleteSkipTask, skipTask as postSkipTask } from 'user-context-app-client/api/tasks';
import I18n from 'I18n';
import { TASK_REQUEST_REQUESTED, TASK_REQUEST_RECEIVED, TASK_REQUEST_ERROR, TASK_SET_COMPLETE_REQUESTED, TASK_SET_COMPLETE_RECEIVED, TASK_SET_COMPLETE_ERROR, TASK_SKIP_REQUESTED, TASK_SKIP_ERROR, TASK_UNDO_SKIP_REQUESTED, TASK_UNDO_SKIP_ERROR, TASK_CLICK_START, TASK_PROGRESS_REQUESTED, TASK_PROGRESS_RECEIVED, TASK_PROGRESS_ERROR, createRequestActions, TASK_ERROR_STATUS_RESET } from './ActionTypes';
import { getUserTasks, getUserProgress, completeUserTask } from '../api/taskApi';
export function callHandler(data, handler) {
  return typeof handler === 'function' ? handler(data) : data;
}
const getDefaultFetchTasksActions = () => createRequestActions(TASK_REQUEST_REQUESTED, TASK_REQUEST_RECEIVED, TASK_REQUEST_ERROR);
export function fetchTasks(groupKey, {
  actions = getDefaultFetchTasksActions(),
  onFinished,
  onError
} = {}) {
  const timestamp = Date.now();
  return dispatch => {
    dispatch(actions.request(groupKey || undefined, timestamp));
    getUserTasks(groupKey).then(response => {
      callHandler(response, onFinished);
      dispatch(actions.success(response, timestamp));
    }).catch(error => {
      callHandler(error, onError);
      dispatch(actions.failure(error));
    });
  };
}
function requestCompleteTask() {
  return {
    type: TASK_SET_COMPLETE_REQUESTED
  };
}
function requestCompleteTaskSuccess(taskKey) {
  return {
    type: TASK_SET_COMPLETE_RECEIVED,
    payload: taskKey
  };
}
function requestCompleteTaskError(taskErrorStatus) {
  return {
    type: TASK_SET_COMPLETE_ERROR,
    payload: taskErrorStatus
  };
}
export function completeTask(taskKey) {
  return dispatch => {
    dispatch(requestCompleteTask());
    return completeUserTask(taskKey).then(() => dispatch(requestCompleteTaskSuccess(taskKey))).catch(error => {
      captureNetworkError(error);
      dispatch(requestCompleteTaskError({
        message: I18n.text('setup-guide-api.checklist.error.taskCompletionError.message'),
        titleText: I18n.text('setup-guide-api.checklist.error.taskCompletionError.title')
      }));
    });
  };
}
function requestSkipTask(taskKey) {
  return {
    type: TASK_SKIP_REQUESTED,
    payload: taskKey
  };
}
function requestSkipTaskError(taskKey, taskErrorStatus) {
  return {
    type: TASK_SKIP_ERROR,
    payload: {
      taskKey,
      taskErrorStatus
    }
  };
}
export function skipTask(taskKey) {
  return dispatch => {
    dispatch(requestSkipTask(taskKey));
    return postSkipTask(taskKey).then(null, () => dispatch(requestSkipTaskError(taskKey, {
      message: I18n.text('setup-guide-api.checklist.error.taskSkipError.message'),
      titleText: I18n.text('setup-guide-api.checklist.error.taskSkipError.title')
    })));
  };
}
function requestUndoSkipTask(taskKey) {
  return {
    type: TASK_UNDO_SKIP_REQUESTED,
    payload: taskKey
  };
}
function requestUndoSkipTaskError(taskKey, taskErrorStatus) {
  return {
    type: TASK_UNDO_SKIP_ERROR,
    payload: {
      taskKey,
      taskErrorStatus
    }
  };
}
export function undoSkipTask(taskKey) {
  return dispatch => {
    dispatch(requestUndoSkipTask(taskKey));
    return deleteSkipTask(taskKey).then(null, () => dispatch(requestUndoSkipTaskError(taskKey, {
      message: I18n.text('setup-guide-api.checklist.error.taskUndoSkipError.message'),
      titleText: I18n.text('setup-guide-api.checklist.error.taskUndoSkipError.title')
    })));
  };
}
export function startTask(taskKey) {
  return {
    type: TASK_CLICK_START,
    payload: taskKey
  };
}
function requestProgress(timestamp) {
  return {
    type: TASK_PROGRESS_REQUESTED,
    timestamp
  };
}
function receiveProgress(tasksCompleted, tasksSkipped, timestamp) {
  return {
    type: TASK_PROGRESS_RECEIVED,
    payload: {
      tasksCompleted,
      tasksSkipped
    },
    timestamp
  };
}
export function requestProgressError(error) {
  return {
    type: TASK_PROGRESS_ERROR,
    payload: error
  };
}
export function fetchProgress(groupKey) {
  return dispatch => {
    const timestamp = Date.now();
    dispatch(requestProgress(timestamp));
    getUserProgress(groupKey).then(({
      completedTaskKeys,
      skippedTaskKeys: tasksSkipped
    }) => {
      tasksSkipped = tasksSkipped.filter(task => !completedTaskKeys.includes(task));
      dispatch(receiveProgress(completedTaskKeys, tasksSkipped, timestamp));
    }).catch(e => {
      dispatch(requestProgressError(new Error(`Failed to fetch task progress: ${e.message}`)));
    });
  };
}
export function resetTaskErrorStatus(dispatch) {
  dispatch({
    type: TASK_ERROR_STATUS_RESET
  });
}