module.exports = {
  "app-management": {
    "namespace": "data-sync",
    "name": "app-management",
    "class": "usage",
    "properties": {
      "applicationId": "number",
      "source": "string",
      "action": [
        "APP_CONNECTED",
        "APP_RECONNECTED"
      ]
    }
  },
  "sync-setup": {
    "namespace": "data-sync",
    "name": "sync-setup",
    "class": "interaction",
    "properties": {
      "applicationId": "number",
      "applicationName": "string",
      "action": [
        "START_SYNC_SETUP",
        "DEFER_SYNC_SETUP",
        "SELECT_OBJECT_TO_SYNC",
        "CONFIRM_OBJECT_SECLECTION",
        "CHOOSE_OBJECT_SEGMENT",
        "ADD_CUSTOM_MAPPING",
        "TOGGLE_DEFAULT_MAPPING",
        "CHOOSE_CONFLICT_RESOLUTION",
        "MANAGE_DUPLICATES",
        "ADD_REMOTE_FILTER",
        "ADD_HUBSPOT_FILTER"
      ]
    }
  }
};