import { create } from '../internal/hs-intl';
import { defaultLanguage } from '../internal/legacyI18nInit';
export default (({
  language,
  excludeFallback
}) => {
  const I18nProvider = create();
  const localesToLoad = [language];
  if (!excludeFallback) {
    localesToLoad.push(defaultLanguage);
  }
  I18nProvider.setLocale(localesToLoad);
  return I18nProvider;
});