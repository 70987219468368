import { getIsFreePortal } from 'onboarding-scopes';
/**
 * A Portal is eligible for team invite if it:
 * - doesn't have seats access
 * - is a free portal
 * - user has "invite-domain-based-read" scope
 */
export const getIsEligibleForDomainInvite = ({
  scopes
}) => {
  const isPortalWithSeatsPlan = scopes.includes('seats-access');
  const hasDomainInviteReadAccess = scopes.includes('invite-domain-based-read');
  const isFreePortal = getIsFreePortal(scopes);
  return !isPortalWithSeatsPlan && isFreePortal && hasDomainInviteReadAccess;
};