import http from 'hub-http/clients/apiClient';
const BRANDING_PATH = '/branding/v1';
export const requestCreateBrandKit = ({
  name,
  activeThemePath
}) => http.post(`${BRANDING_PATH}/brand-kit`, {
  data: {
    name,
    activeThemePath
  }
});
export const requestFetchPrimaryBrandKit = () => http.get(`${BRANDING_PATH}/primary-brand-kit`);