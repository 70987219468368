import { useEffect, useState } from 'react';
import { captureNetworkError } from 'growth-onboarding-reliability/utils/raven';
// @ts-expect-error dependency missing types
import { getAvailableTrials } from 'self-service-api/api/getAvailableTrials';
import { MARKETING_PRO_TRIAL_ID, OPERATIONS_PROFESSIONAL_TRIAL_ID, SALES_PROFESSIONAL_TRIAL_ID, SERVICE_PROFESSIONAL_TRIAL_ID } from 'self-service-api/constants/Trials';
const VALID_PRO_TRIAL_IDS = [MARKETING_PRO_TRIAL_ID, OPERATIONS_PROFESSIONAL_TRIAL_ID, SALES_PROFESSIONAL_TRIAL_ID, SERVICE_PROFESSIONAL_TRIAL_ID];
import { useIsStarterPortal } from 'onboarding-scopes';
export const useShouldSeeProTrialsBanner = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [shouldSeeProTrialsBanner, setShouldSeeProTrialsBanner] = useState(false);
  const isStarterPortal = useIsStarterPortal();
  useEffect(() => {
    if (!isStarterPortal) {
      setIsLoading(false);
      return;
    }
    setIsLoading(true);
    getAvailableTrials().then(availableTrials => {
      const filteredAvailableTrialNames = availableTrials.filter(trial => VALID_PRO_TRIAL_IDS.includes(trial.trialId)).map(trial => trial.name);
      setShouldSeeProTrialsBanner(!!filteredAvailableTrialNames.length);
    }).catch(captureNetworkError).finally(() => {
      setIsLoading(false);
    });
  }, [isStarterPortal]);
  return {
    isLoading,
    shouldSeeProTrialsBanner
  };
};