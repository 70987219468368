import PortalIdParser from 'PortalIdParser';
export const API_BASE_URL = 'onboardingtours/v1';
export const TOUR_ID = 'tourId';
export const ONBOARDING_TOUR_ID = 'onboardingTourId';
export const ONBOARDING_TOUR_STEP = 'onboardingTourStep';
export const ONBOARDING_TOUR_SOURCE = 'onboardingTourSource';
export const VIA_TOUR = 'onboarding-tours';
export const ONBOARDING_TOUR_PARAMS = [ONBOARDING_TOUR_ID, TOUR_ID, ONBOARDING_TOUR_STEP, ONBOARDING_TOUR_SOURCE];
export const getGrowthPlanUrl = () => `/growth-plan/${PortalIdParser.get()}`;
export const getUserGuideUrl = () => `/user-guide/${PortalIdParser.get()}`;
export const VIA_POST_CRM_SETUP_TOUR_PARAM = 'post-crm-setup-tour';