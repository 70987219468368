export const UPGRADE_BANNER_TYPES = {
  LIMIT_PACK_BANNER: 'LIMIT_PACK_BANNER',
  REMOVE_BRANDING_BANNER: 'REMOVE_BRANDING_BANNER',
  USAGE_LIMIT_BANNER: 'USAGE_LIMIT_BANNER'
};
export const POINT_TYPES = Object.assign({}, UPGRADE_BANNER_TYPES, {
  DEFAULT: 'LOCKED_FEATURE',
  MODAL: 'LOCKED_FEATURE',
  // Paywalls aren't available in the Upgrade Management System
  PAYWALL: 'PAYWALL',
  SOFT_PAYWALL: 'SOFT_PAYWALL',
  USAGE_LIMIT_COUNTER: 'USAGE_LIMIT_COUNTER'
});