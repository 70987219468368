'use es6';

import { objectDoesExist } from './utils/Utils';
import ChromeExtensionInstallHelpers from './lib/ChromeExtensionInstallHelpers';
import ChromeExtension from './constants/ChromeExtension';
const _hasExtension = () => {
  return objectDoesExist(window, ['SIG_EXT']);
};
class SidekickExtensionInstallHelpers extends ChromeExtensionInstallHelpers {
  setup() {
    const ChromeExtensionData = ChromeExtension.sidekick;
    this.extensionUrl = ChromeExtensionData.extensionUrl;
    this._isExtensionInstalled = _hasExtension();
  }
  hasExtension() {
    return this._isExtensionInstalled || _hasExtension();
  }
}
export default new SidekickExtensionInstallHelpers();