export let CTAS;
(function (CTAS) {
  CTAS["COMPLETED_CRM_ESSENTIALS"] = "completed-crm-essentials-cta";
  CTAS["COMPLETED_CRM_ESSENTIALS_CMS"] = "completed-crm-essentials-cms-cta";
  CTAS["COMPLETED_CMS_TOOLS"] = "completed-cms-cta";
  CTAS["COMPLETED_CRM_ESSENTIALS_MARKETING"] = "completed-crm-essentials-marketing-cta";
  CTAS["MARKETING_FREE_DEMO"] = "free-marketing-demo";
  CTAS["COMPLETED_MARKETING_TOOLS"] = "completed-marketing-cta";
  CTAS["COMPLETED_CRM_ESSENTIALS_SALES"] = "completed-crm-essentials-sales-cta";
  CTAS["SALES_FREE_DEMO"] = "free-sales-demo";
  CTAS["COMPLETED_SALES_TOOLS"] = "completed-sales-cta";
  CTAS["SERVICE_SLIDESHOW"] = "service-slideshow";
  CTAS["COMPLETED_CRM_ESSENTIALS_SERVICE"] = "completed-crm-essentials-service-cta";
  CTAS["COMPLETED_SERVICE_TOOLS"] = "completed-service-cta";
  CTAS["COMMERCE_OVERVIEW_TOOLS"] = "commerce-overview-tools";
  CTAS["COMMERCE_PAYMENTS_LESSON"] = "commerce-payments-lesson";
  CTAS["COMMERCE_CONNECT_STRIPE"] = "commerce-connect-stripe";
})(CTAS || (CTAS = {}));
export let LEARN_MORE_CTAS;
(function (LEARN_MORE_CTAS) {
  LEARN_MORE_CTAS["ACADEMY_CMS"] = "academy-cms";
  LEARN_MORE_CTAS["DEMO_CMS"] = "demo-cms";
  LEARN_MORE_CTAS["MARKETPLACE_CMS"] = "marketplace-cms";
  LEARN_MORE_CTAS["ACADEMY_MARKETING"] = "academy-marketing";
  LEARN_MORE_CTAS["CONTACTS_TOUR_MARKETING"] = "contacts-tour-marketing";
  LEARN_MORE_CTAS["MARKETPLACE_MARKETING"] = "marketplace-marketing";
  LEARN_MORE_CTAS["ACADEMY_SALES_DATA"] = "academy-sales-data";
  LEARN_MORE_CTAS["MOBILE_APP_SALES"] = "mobile-app-sales";
  LEARN_MORE_CTAS["MARKETPLACE_SALES"] = "app-marketplace-sales";
  LEARN_MORE_CTAS["ACADEMY_SERVICE"] = "academy-customer-service";
  LEARN_MORE_CTAS["CUSTOMER_SERVICE_CRM"] = "crm-customer-service";
  LEARN_MORE_CTAS["MARKETPLACE_SERVICE"] = "app-marketplace-service";
})(LEARN_MORE_CTAS || (LEARN_MORE_CTAS = {}));