import http from 'hub-http/clients/apiClient';
import { getDemographicsUrl } from './urls';
export * from './portalClient';
export * from './userClient';
export function getDemographics() {
  return http.get(getDemographicsUrl());
}
export function setDemographics(demographics, urlTransform) {
  return http.post(getDemographicsUrl(urlTransform), {
    data: demographics
  });
}