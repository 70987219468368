'use es6';

import { isHubSpotter, isForceOpen, warn } from '../utils';
import translations from '../wootric-translations';
import * as UserAttributes from '../sources/UserAttributes';
const cesTokenQA = 'NPS-2ec5d342';
const cesTokenProd = 'NPS-e579c260';
export const getConfig = (token, props = {}, surveyKey) => {
  const {
    field_required,
    permission_labels,
    customMessages: overrideMessages,
    complete,
    supported
  } = translations;
  const lang = supported[props.language] ? props.language : 'EN';

  // Wootric doesn't support translating custom messages for other languages:
  // http://docs.wootric.com/javascript/#custom-language-setting
  // So we have to build our own light translation setup here
  let customMessages = {};
  let translatedConfig = {};
  if (complete[lang]) {
    const {
      ces_questions,
      followup_question,
      followup_thank_you,
      placeholder_text
    } = overrideMessages[lang];
    customMessages = {
      followup_question,
      followup_thank_you,
      placeholder_text
    };
    if (props['properties']) {
      if (ces_questions && ces_questions[surveyKey]) {
        customMessages.ces_question = ces_questions[surveyKey];
        props['properties']['question'] = customMessages.ces_question;
      }
      props['event_name'] = props['properties']['event'];
    }
  }
  translatedConfig = {
    field_required,
    permission_labels
  };
  const wootricSettings = Object.assign({}, props, {
    account_token: token,
    ask_permission_to_share_feedback: true,
    // this generates the checkbox (pre-checked)
    modal_footprint: 'normal',
    callbacks: {
      after_show: config => {
        UserAttributes.touch('nav:seen');
        if (config && config.localized_texts && translations.complete[props.language]) {
          try {
            config.localized_texts.send = translations.customMessages[props.language].send;
          } catch (e) {
            warn(`Could not set localized "Send" text for language: ${props.language}; message: ${e.message}`);
          }
        }
      },
      after_decline() {
        UserAttributes.touch('nav:declined');
      },
      after_response() {
        UserAttributes.touch('nav:responded');
      }
    }
  }, translatedConfig);
  return {
    customMessages,
    wootricSettings
  };
};
export const configureCes = (props, surveyKey) => {
  const token = isHubSpotter(props.email) ? cesTokenQA : cesTokenProd;
  if (isForceOpen()) {
    window.wootric_no_surveyed_cookie = true;
    console.debug('"wootric-nps-open" flag is set.', 'Wootric should be forced open.', 'Disregard the message it gives saying "remove survey immediately".');
  }

  // seconds to delay before opening (default 10)
  window.wootric_time_delay = 1;
  const {
    wootricSettings,
    customMessages
  } = getConfig(token, props, surveyKey);
  window.customMessages = customMessages;
  window.wootricSettings = wootricSettings;
};