import http from 'hub-http/clients/apiClient';
export let DomainInviteStatus;
(function (DomainInviteStatus) {
  DomainInviteStatus["ACTIVE"] = "ACTIVE";
  DomainInviteStatus["DELETED"] = "DELETED";
  DomainInviteStatus["EXPIRED"] = "EXPIRED";
})(DomainInviteStatus || (DomainInviteStatus = {}));
export const getIsDomainBasedInvitesEnabled = () => http.get('invite/v3/settings/domainBasedInvitesEnabled');
export const getDomainBasedInviteLink = () => http.get('/invite/v3/invites/domain-based');
export const generateDomainBasedInviteLink = () => http.post('invite/v3/invites/domain-based', {
  data: {}
});
export const deleteDomainBasedInviteLink = domainInviteLinkToken => http.delete(`invite/v3/invites/domain-based/${domainInviteLinkToken}`);