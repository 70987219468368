export const APP_STARTED = 'APP_STARTED';
export const INITIALIZE_AUTH = 'INITIALIZE_AUTH';
export const ONBOARDING_SETTINGS_RECEIVED = 'ONBOARDING_SETTINGS_RECEIVED';
export const ONBOARDING_VIEW_ACTIVATED = 'ONBOARDING_VIEW_ACTIVATED';
export const TASK_REQUEST_REQUESTED = 'TASK_REQUEST_REQUESTED';
export const TASK_REQUEST_RECEIVED = 'TASK_REQUEST_RECEIVED';
export const TASK_REQUEST_ERROR = 'TASK_REQUEST_ERROR';
export const TASK_SET_COMPLETE_REQUESTED = 'TASK_SET_COMPLETE_REQUESTED';
export const TASK_SET_COMPLETE_RECEIVED = 'TASK_SET_COMPLETE_RECEIVED';
export const TASK_SET_COMPLETE_ERROR = 'TASK_SET_COMPLETE_ERROR';
export const TASK_SKIP_REQUESTED = 'TASK_SKIP_REQUESTED';
export const TASK_SKIP_RECEIVED = 'TASK_SKIP_RECEIVED';
export const TASK_SKIP_ERROR = 'TASK_SKIP_ERROR';
export const TASK_UNDO_SKIP_REQUESTED = 'TASK_UNDO_SKIP_REQUESTED';
export const TASK_UNDO_SKIP_RECEIVED = 'TASK_UNDO_SKIP_RECEIVED';
export const TASK_UNDO_SKIP_ERROR = 'TASK_UNDO_SKIP_ERROR';
export const TASK_CLICK_START = 'TASK_CLICK_START';
export const TASK_PROGRESS_REQUESTED = 'TASK_PROGRESS_REQUESTED';
export const TASK_PROGRESS_RECEIVED = 'TASK_PROGRESS_RECEIVED';
export const TASK_PROGRESS_ERROR = 'TASK_PROGRESS_ERROR';
export const SUITE_OVERVIEW_TASK_REQUEST_REQUESTED = 'SUITE_OVERVIEW_TASK_REQUEST_REQUESTED';
export const SUITE_OVERVIEW_TASK_REQUEST_RECEIVED = 'SUITE_OVERVIEW_TASK_REQUEST_RECEIVED';
export const SUITE_OVERVIEW_TASK_REQUEST_ERROR = 'SUITE_OVERVIEW_TASK_REQUEST_ERROR';
export const SUITE_OVERVIEW_TASK_PROGRESS_REQUESTED = 'SUITE_OVERVIEW_TASK_PROGRESS_REQUESTED';
export const SUITE_OVERVIEW_TASK_PROGRESS_RECEIVED = 'SUITE_OVERVIEW_TASK_PROGRESS_RECEIVED';
export const SUITE_OVERVIEW_TASK_PROGRESS_ERROR = 'SUITE_OVERVIEW_TASK_PROGRESS_ERROR';
export const TASK_ERROR_STATUS_RESET = 'TASK_ERROR_STATUS_RESET';
export function createRequestAction(actionType) {
  return (payload, timestamp) => ({
    type: actionType,
    payload,
    timestamp
  });
}
export function createRequestActions(requestActionType, successActionType, failureActionType) {
  return {
    request: createRequestAction(requestActionType),
    success: createRequestAction(successActionType),
    failure: createRequestAction(failureActionType)
  };
}