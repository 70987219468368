import { createStack } from 'hub-http';
import promiseClient from 'hub-http/adapters/promiseClient';
import { retry } from 'hub-http/middlewares/core';
import hubapiStack from 'hub-http/stacks/hubapi';
const SUCCESS_NO_CONTENT_STATUS_CODE = 204;
const retryMiddleware = retry(({
  status
}) => {
  return status !== SUCCESS_NO_CONTENT_STATUS_CODE;
}, {
  delay: 1000,
  maxRetries: 5
});
export default promiseClient(createStack(hubapiStack, retryMiddleware));