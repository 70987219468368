import { useContext } from 'react';
import ViewConfigContext from '../contexts/ViewConfigContext';
export const useAllViewConfig = () => {
  return useContext(ViewConfigContext);
};
export const useTaskViewConfig = onboardingKey => {
  return useAllViewConfig()[onboardingKey] || {};
};
export const useLearnMoreConfig = onboardingKey => {
  return useTaskViewConfig(onboardingKey).learnMore;
};
export const useTaskGroupConfigOverrides = (onboardingKey, groupKey) => {
  const taskViewConfig = useTaskViewConfig(onboardingKey);
  return (taskViewConfig.taskGroupOverrides || {})[groupKey] || {};
};