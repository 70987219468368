'use es6';

const renderList = list => {
  return list && list.length ? list.sort().join('|') : null;
};
export default (({
  Iris = {}
}) => {
  const {
    hub: {
      salesProductLevel,
      marketingProductLevel,
      serviceProductLevel,
      addonProducts,
      companySize,
      parcelNames
    } = {},
    hubUser: {
      paidSeats
    } = {}
  } = Iris;
  return Promise.resolve({
    salesTier: salesProductLevel,
    serviceTier: serviceProductLevel,
    marketingTier: marketingProductLevel === 'ent' ? 'enterprise' : marketingProductLevel,
    addons: renderList(addonProducts),
    companySize,
    parcels: renderList(parcelNames),
    paidSeats: renderList(paidSeats)
  });
});