'use es6';

export default (({
  EarlyRequester
}) => {
  const {
    user: {
      scopes = []
    }
  } = EarlyRequester;
  return {
    crmTier: scopes.indexOf('crm-access') >= 0 ? 'free' : 'none'
  };
});