// Progress URLs
export const PROGRESS_URL = '/usercontext-app/v1/onboarding/progress';
export const EXTERNAL_PROGRESS_URL = '/usercontext-app/v1/external/onboarding/progress';

// Task URLs
export const TASKS_URL = '/usercontext-app/v1/onboarding/tasks';
export const EXTERNAL_TASKS_URL = '/usercontext-app/v1/external/onboarding/tasks';
export const DEFAULT_USER_GROUP_URL = '/usercontext-app/v1/onboarding/tasks/group';
export const TASK_BY_TASKID_URL = '/usercontext-app/v1/onboarding/tasks/task';

// V2 URLs
export const PROGRESS_URL_V2 = '/usercontext-app/v2/onboarding/progress';
export const TASKS_URL_V2 = '/usercontext-app/v2/onboarding/tasks';