import PortalIdParser from 'PortalIdParser';
import { DATA_SYNC_OPTIONS } from './dataSyncConstants';
import { DataSyncOptionApp } from './types';
const getCopyPasteImportUrl = () => `/data-import-table/${PortalIdParser.get()}?intro`;
export const GOSU004CopyPasteApp = 'CopyAndPaste';
export const GOSU004CopyPasteDataSyncOptions = {
  [DataSyncOptionApp.Spreadsheet]: DATA_SYNC_OPTIONS[DataSyncOptionApp.Spreadsheet],
  [GOSU004CopyPasteApp]: {
    id: 'copy-paste',
    url: getCopyPasteImportUrl()
  },
  [DataSyncOptionApp.Gmail]: DATA_SYNC_OPTIONS[DataSyncOptionApp.Gmail],
  [DataSyncOptionApp.Outlook]: DATA_SYNC_OPTIONS[DataSyncOptionApp.Outlook],
  [DataSyncOptionApp.Mailchimp]: DATA_SYNC_OPTIONS[DataSyncOptionApp.Mailchimp],
  [DataSyncOptionApp.Zoho]: DATA_SYNC_OPTIONS[DataSyncOptionApp.Zoho]
}; //Hack type change for avoiding too many changes on all configs and components.

export const GOSU004CopyPasteAppConfigs = {
  [GOSU004CopyPasteApp]: {
    illustrationProps: {
      illustration: 'copy-paste'
    },
    textMessageKey: 'growthDataModal.gosu004.copyPaste'
  }
};