import { useEffect, useRef, useState } from 'react';

/**
 * Hook for create Intersection Observer in component
 * https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API
 * Example:
    const [ref, entry] = useIntersectionObserver();
    const isComponentVisible = entry && entry.isIntersecting;

    useEffect(() => {
      if (isComponentVisible) {
        // Logic only when component is visible
      }
    }, [isComponentVisible]);

    return <ComponentToBeObserved ref={ref} />;
 */
export function useIntersectionObserver({
  root = null,
  rootMargin = '0px',
  threshold = [0]
} = {}) {
  const targetRef = useRef(null);
  const observerRef = useRef();
  const [entry, setEntry] = useState();
  const [observerOptions] = useState({
    root,
    rootMargin,
    threshold
  });
  useEffect(() => {
    if (!targetRef.current) {
      return undefined;
    }
    const observer = new window.IntersectionObserver(entries => setEntry(entries[0]), observerOptions);
    observer.observe(targetRef.current);
    observerRef.current = observer;
    return () => observer.disconnect();
  }, [observerOptions]);
  return [targetRef, entry, observerRef];
}
export function useIsScrolledAway() {
  const [ref, entry, observerRef] = useIntersectionObserver({
    threshold: [0, 0.01]
  });
  const isScrolledAway = entry && entry.intersectionRatio === 0;
  return [ref, isScrolledAway, observerRef];
}
export function useIsFullyVisible() {
  const [targetRef, entry, observerRef] = useIntersectionObserver({
    threshold: 1
  });
  const isFullyVisible = entry && entry.intersectionRatio === 1;
  return [targetRef, isFullyVisible, observerRef];
}
const VisibilityStates = {
  VISIBLE: 'visible',
  HIDDEN: 'hidden',
  PRERENDER: 'prerender'
};
export const useVisibilityChange = onChange => {
  const visibleRef = useRef(document.visibilityState === VisibilityStates.VISIBLE);
  useEffect(() => {
    const visibilityHandler = () => {
      const newIsVisible = document.visibilityState === VisibilityStates.VISIBLE;
      if (newIsVisible !== visibleRef.current) {
        visibleRef.current = newIsVisible;
        onChange(newIsVisible);
      }
    };
    document.addEventListener('visibilitychange', visibilityHandler);
    return () => document.removeEventListener('visibilitychange', visibilityHandler);
  }, [onChange]);
};