import { useEffect } from 'react';
import { changeGroupKey } from 'setup-guide-api/api/taskApi';
import { useRemoveQueryParams } from 'setup-guide-components/hooks/history';
import { useRawTaskData } from 'setup-guide-api/hooks/task';
import { changeEnrolledInUserGuide } from 'onboarding-settings-client/api/enrollment';
import { updateSettings } from 'onboarding-settings-client/api/settings';
import { useUser } from '../selectors/user';
import { getQueryParams } from '../utils/urls';
import { useOnboardingSettings } from './config';
export const useSyncOnboardingSettings = () => {
  const onboardingSettings = useOnboardingSettings();
  const removeQueryParams = useRemoveQueryParams();
  const {
    groupKey
  } = useRawTaskData();
  const {
    user_id: userId
  } = useUser();
  const {
    activeTab,
    activeView = activeTab,
    onboardingKey: overrideOnboardingKey,
    enroll
  } = getQueryParams();
  useEffect(() => {
    if (enroll !== 'true') {
      return;
    }
    const enrollmentRequests = [];
    // Already accounts for groupKey override
    const existingViewSettings = onboardingSettings && onboardingSettings.views && onboardingSettings.views[activeView] || {};
    const newOnboardingSettings = {
      views: {
        [activeView]: existingViewSettings
      },
      selectedView: activeView
    };
    enrollmentRequests.push(changeEnrolledInUserGuide({
      isEnrolled: true
    }));
    if (activeView) {
      if (overrideOnboardingKey) {
        existingViewSettings.onboardingKey = overrideOnboardingKey;
      }
      enrollmentRequests.push(changeGroupKey(groupKey)); // Already accounts for override
      enrollmentRequests.push(updateSettings({
        onboardingSettings: newOnboardingSettings
      }));
    }
    Promise.all(enrollmentRequests).then(() => {
      removeQueryParams(['enroll', 'groupKey', 'onboardingKey', 'activeTab', 'activeView']);
    }).catch(() => {
      // Ignore query prams deletion error
    });
  }, [removeQueryParams, onboardingSettings, userId, groupKey, activeView, enroll, overrideOnboardingKey]);
};