const isCacheValid = cacheData => {
  // Invalid cache after 1 hour
  return cacheData && Date.now() - cacheData.timestamp < 1000 * 60 * 60;
};
export const getCacheTaskGroupData = (state, groupKey) => {
  const {
    cacheData
  } = state;
  const {
    taskGroups
  } = cacheData;
  if (!groupKey) {
    return undefined;
  }
  if (!isCacheValid(taskGroups[groupKey])) {
    return undefined;
  }
  return taskGroups[groupKey];
};