'use es6';

import env from 'enviro';
export function isFunction(func) {
  return typeof func === 'function';
}
export function isStringInDomain(str) {
  return window.location.host.indexOf(str) !== -1;
}
export function objectDoesExist(baseObject = window, objectArray) {
  const objectArrayLength = objectArray.length;
  const obj = baseObject[objectArray[0]];

  // Object does not exist at this path
  if (typeof obj === 'undefined' || obj === null) {
    return false;
  }

  // Traverse the object path array until we hit the end
  if (objectArrayLength > 1 && typeof obj === 'object') {
    return objectDoesExist(obj, objectArray.slice(1, objectArrayLength));
  }
  return true;
}
export const domainSuffix = (() => {
  if (env.getShort() === 'qa') {
    return 'qa';
  }
  return '';
})();