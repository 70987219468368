export const isLastPurchaseSalesHubProfessional = recentPostPurchase => {
  if (!(recentPostPurchase !== null && recentPostPurchase !== void 0 && recentPostPurchase.length)) {
    return false;
  }
  const lastPurchase = recentPostPurchase.slice(-1)[0];
  if (lastPurchase.highestTierAfter && lastPurchase.highestTierAfter === 'Professional') {
    const productsAfter = lastPurchase.productsAfter;
    const found = productsAfter.find(product => product.productName === 'Sales Hub Professional');
    if (found) {
      return true;
    }
  }
  return false;
};