'use es6';

export const calculateScreenSize = (screen = window.screen) => {
  if (screen == null || typeof screen !== 'object' || typeof screen.height !== 'number' || typeof screen.width !== 'number') {
    return 'Unknown';
  }
  return `${screen.width}x${screen.height}`;
};
export default (() => {
  return {
    screenSize: calculateScreenSize()
  };
});