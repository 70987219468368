import { VIEWS_TITLE_GETTERS, VIEWS_RECOMMENDED_SUB_TITLES } from '../constants/viewsConstants';
export const getViewTitle = view => {
  if (!view) {
    return undefined;
  }
  const viewTitleGetter = VIEWS_TITLE_GETTERS[view];
  if (!viewTitleGetter) {
    return undefined;
  }
  return viewTitleGetter();
};
export const getRecommendedViewSubTitle = view => {
  if (!view) {
    return undefined;
  }
  const viewSubTitleGetter = VIEWS_RECOMMENDED_SUB_TITLES[view];
  if (!viewSubTitleGetter) {
    return undefined;
  }
  return viewSubTitleGetter();
};