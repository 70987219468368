import { useCallback, useContext } from 'react';
import TrackerContext from '../contexts/TrackerContext';
export const useTracker = () => {
  return useContext(TrackerContext);
};
export const useModalTracker = modalName => {
  const {
    trackUserInteraction
  } = useTracker();
  const track = useCallback(action => {
    trackUserInteraction(action, modalName);
  }, [modalName, trackUserInteraction]);
  return {
    track,
    trackLink: name => track(`click link: ${name}`)
  };
};
export const useExploreCardTracker = ({
  name,
  hub = 'common',
  tier = 'common',
  experience = 'common'
}) => {
  const {
    trackExploreCardInteraction
  } = useTracker();
  const trackUserExploreCardInteraction = useCallback((action, properties) => {
    if (trackExploreCardInteraction) {
      trackExploreCardInteraction(action, name, hub, tier, experience, properties);
    }
  }, [name, hub, tier, experience, trackExploreCardInteraction]);
  return {
    trackUserExploreCardInteraction
  };
};
export const useHubTasksTracker = ({
  hubGroupKey,
  tier = 'starter'
}) => {
  const {
    trackUserInteraction
  } = useTracker();
  const trackHubTasksInteraction = useCallback((action, value, properties) => {
    if (trackUserInteraction) {
      trackUserInteraction(`hub tasks ${action}`, value, Object.assign({
        groupKey: hubGroupKey,
        tier
      }, properties));
    }
  }, [hubGroupKey, tier, trackUserInteraction]);
  return {
    trackHubTasksInteraction
  };
};