import { ONBOARDING_FREE_SERVICE, ONBOARDING_STARTER_SERVICE, ONBOARDING_INVITED_SERVICE } from 'getting-started-shared-tasks/onboardingKeys';
import { getDefaultServiceTaskGroupOverrides } from '../constants/defaultTaskGroupOverrides';
import { getDefaultServiceLearnMoreConfig } from '../constants/defaultServiceLearnMoreConfig';
export default function getServiceViewConfig(__appContexts) {
  return {
    [ONBOARDING_FREE_SERVICE]: {
      taskGroupOverrides: getDefaultServiceTaskGroupOverrides(),
      learnMore: getDefaultServiceLearnMoreConfig(__appContexts)
    },
    [ONBOARDING_INVITED_SERVICE]: {
      taskGroupOverrides: getDefaultServiceTaskGroupOverrides(),
      learnMore: getDefaultServiceLearnMoreConfig(__appContexts)
    },
    [ONBOARDING_STARTER_SERVICE]: {
      taskGroupOverrides: getDefaultServiceTaskGroupOverrides(),
      learnMore: getDefaultServiceLearnMoreConfig(__appContexts)
    }
  };
}