import { APP_STARTED, INITIALIZE_AUTH } from '../actions/ActionTypes';
import { getOverrideExperienceLevel } from '../utils/urls';
const auth = (state = {}, action) => {
  switch (action.type) {
    case INITIALIZE_AUTH:
      return {
        user: action.user,
        gates: action.gates,
        portal: action.portal,
        demographics: Object.assign({}, action.demographics.portal, action.demographics.user, getOverrideExperienceLevel()),
        seats: action.seats,
        hub: action.hub
      };
    case APP_STARTED:
    default:
      return state;
  }
};
export default auth;