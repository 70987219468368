import PortalIdParser from 'PortalIdParser';
import { GPRO0002_TREATMENT_KEY, GPRO0002_PARAMETER_KEY } from '../../constants/experimentConstants';
import { useTreatmentParameterValue } from 'setup-guide-components/hooks/experiments';
import { useIsGPRO0002Audience } from 'growth-pro-utils-lib/hooks/useIsGPRO0002Audience';
const portalId = PortalIdParser.get();
export const useGPRO0002Group = () => {
  return useTreatmentParameterValue(GPRO0002_TREATMENT_KEY, GPRO0002_PARAMETER_KEY);
};
export const useIsGPRO0002 = () => {
  const isGPRO0002Variant = useGPRO0002Group() === 'variant';
  const {
    isGPRO0002Audience
  } = useIsGPRO0002Audience(portalId);
  return isGPRO0002Audience && isGPRO0002Variant || false;
};