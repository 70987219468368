'use es6';

export default ((src, moreAttrs = {}) => {
  const script = document.createElement('script');
  const attrs = Object.assign({
    async: true
  }, moreAttrs, {
    src
  });
  Object.keys(attrs).forEach(key => {
    script.setAttribute(key, attrs[key]);
  });
  document.body.appendChild(script);
});