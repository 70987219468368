export const ONBOARDING_DEFAULT = 'default';
export const ONBOARDING_MARKETING_STARTER = 'marketing_starter';
export const ONBOARDING_MARKETING_STARTER_INVITED_SEAT = 'marketing_starter_invited_seat';
export const ONBOARDING_SALES_STARTER = 'sales_starter';
export const ONBOARDING_SALES_STARTER_INVITED_SEAT = 'sales_starter_invited_seat';
export const ONBOARDING_SALES_INVITED_PAID = 'sales_invited_paid';
export const ONBOARDING_SERVICE_STARTER = 'service_starter';
export const ONBOARDING_SERVICE_STARTER_INVITED_SEAT = 'service_starter_invited_seat';
export const ONBOARDING_GROWTH_STARTER = 'growth_suite_starter';
export const ONBOARDING_GROWTH_STARTER_SALES = 'growth_starter_sales';
export const ONBOARDING_GROWTH_STARTER_MARKETING = 'growth_starter_marketing';
export const ONBOARDING_GROWTH_STARTER_SERVICE = 'growth_starter_service';
export const ONBOARDING_GROWTH_STARTER_OPS = 'growth_starter_ops';
export const ONBOARDING_SALES_LEADER_INEXPERIENCED = 'sales_leader_inexperienced';
export const ONBOARDING_SALES_LEADER_WITH_CRM_EXPERIENCE = 'sales_leader_with_crm_experience';
export const ONBOARDING_SALES_LEADER_WITH_HUBSPOT_EXPERIENCE = 'sales_leader_with_hubspot_experience';
export const ONBOARDING_SALES_PERSON_INEXPERIENCED = 'sales_person_inexperienced';
export const ONBOARDING_SALES_PERSON_WITH_CRM_EXPERIENCE = 'sales_person_with_crm_experience';
export const ONBOARDING_SALES_PERSON_WITH_HUBSPOT_EXPERIENCE = 'sales_person_with_hubspot_experience';
export const ONBOARDING_SALES_INVITED = 'sales_invited';
export const MARKETING_FREE_GOB262_VARIANT_1 = 'marketing_free_gob262_variant_1';
export const MARKETING_FREE_GOB262_VARIANT_2 = 'marketing_free_gob262_variant_2';
export const ONBOARDING_MARKETER_INEXPERIENCED = 'marketing_inexperienced';
export const ONBOARDING_MARKETER_WITH_CRM_EXPERIENCE = 'marketing_with_crm_experience';
export const ONBOARDING_MARKETER_WITH_HUBSPOT_EXPERIENCE = 'marketing_with_hubspot_experience';
export const ONBOARDING_MARKETER_INVITED = 'marketing_invited';
export const ONBOARDING_MARKETING_FREE_SIMPLIFIED_SETUP_INEXPERIENCED = 'marketing_free_simplified_inexperienced';
export const ONBOARDING_SERVICE_FREE = 'service_free';
export const ONBOARDING_SERVICE_INVITED = 'service_invited';
export const ONBOARDING_CMS_SIMPLIFIED = 'cms_onboarding_simplified';
export const ONBOARDING_OPS_FREE_INEXPERIENCED = 'ops_free_inexperienced';
export const ONBOARDING_OPS_FREE_EXPERIENCED = 'ops_free_with_crm_experience';
export const ONBOARDING_OPS_FREE_HUBSPOT_EXPERIENCED = 'ops_free_with_hubspot_experience';
export const ONBOARDING_OPS_STARTER = 'ops_starter';
export const ONBOARDING_CRM_SUITE_STARTER_OVERVIEW = 'crm_suite_starter_overview';
export const ONBOARDING_CRM_SUITE_FREE_OVERVIEW = 'crm_suite_free_overview';
export const ONBOARDING_CRM_SUITE_STARTER_OVERVIEW_PAYMENT_VARIANT = 'crm_suite_starter_overview_payment_variant';
export const ONBOARDING_CRM_SUITE_STARTER_OVERVIEW_PAY031_VARIANT = 'crm_suite_starter_overview_pay031';

// PAY-042 - commerce hub user guide
export const ONBOARDING_COMMERCE_FREE = 'free_commerce';
export const ONBOARDING_STARTER_COMMERCE = 'starter_commerce';
export const ONBOARDING_STARTER_COMMERCE_ENROLLED = 'starter_commerce_enrolled';

// Consolidated Onboarding Keys

export const ONBOARDING_MARKETING_FREE = 'free_marketing';
export const ONBOARDING_SALES_FREE = 'free_sales';
export const ONBOARDING_FREE_SERVICE = 'free_service';
export const ONBOARDING_CMS_FREE = 'free_cms';
export const INVITED_USERS_MARKETING_STARTER = 'marketing_invited';

// Starter Consolidated Onboarding Keys
export const ONBOARDING_STARTER_MARKETING = 'starter_marketing';
export const ONBOARDING_STARTER_SALES = 'starter_sales';
export const ONBOARDING_STARTER_SERVICE = 'starter_service';
export const ONBOARDING_STARTER_CMS = 'starter_cms';

// Invited Consolidated Onboarding Keys
export const ONBOARDING_INVITED_MARKETING = 'invited_marketing';
export const ONBOARDING_INVITED_SALES = 'invited_sales';
export const ONBOARDING_INVITED_SERVICE = 'invited_service';
export const ONBOARDING_INVITED_CMS = 'invited_cms';

// Playbook Group Keys
export const PLAYBOOK_BASIC_SETUP = 'playbook_basic_setup';