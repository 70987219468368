import { useEffect, useState } from 'react';
import { requestFetchPrimaryBrandKit } from '../clients/requestFetchPrimaryBrandKit';
import { logSentryException } from '../utils/logSentry';
export const useFetchActiveThemePath = () => {
  const [themePath, setThemePath] = useState(undefined);
  useEffect(() => {
    requestFetchPrimaryBrandKit().then(({
      activeThemePath
    }) => setThemePath(activeThemePath)).catch(e => {
      // 404 status is expected if there's no primary BK
      if (e.status !== 404) {
        logSentryException(e);
      }
      setThemePath(undefined);
    });
  }, []);
  return themePath;
};