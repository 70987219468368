import { combineReducers } from 'redux';
import task from 'setup-guide-api/reducers/task';
import taskProgress from 'setup-guide-api/reducers/taskProgress';
import auth from './auth';
import cacheData from './cacheData';
import experiments from './experiments';
import onboarding from './onboarding';
export const rootReducer = combineReducers({
  auth,
  cacheData,
  experiments,
  onboarding,
  task,
  taskProgress
});

// Types uses of the getState argument in thunk actions