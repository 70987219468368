import quickFetch from 'quick-fetch';

/**
 * Failure Injection is not supported in quick-fetch, so we need to skip it manually.
 * TODO: remove this once https://git.hubteam.com/HubSpot/Frontend/issues/1514 is resolved
 */
function shouldSkipEarlyRequest() {
  try {
    return Boolean(localStorage.getItem('HTTP_FAILURE_INJECTION'));
  } catch (__err) {
    return false;
  }
}
export function getEarlyRequestByKey(requestKey, backupRequest) {
  const earlyRequest = quickFetch.getRequestStateByName(requestKey);
  if (!earlyRequest || shouldSkipEarlyRequest()) {
    return backupRequest();
  }
  return new Promise((resolve, reject) => {
    earlyRequest.whenFinished(result => {
      resolve(result);
      quickFetch.removeEarlyRequest(requestKey);
    });
    earlyRequest.onError(() => {
      quickFetch.removeEarlyRequest(requestKey);
      if (backupRequest) {
        backupRequest().then(resolve).catch(reject);
      } else {
        reject(new Error(`No backup request for ${requestKey}`));
      }
    });
  });
}