import PortalIdParser from 'PortalIdParser';
import { fetchOnboardingSettings, patchOnboardingSettings } from './api/onboardingSettingsApi';
import { setGroupKey } from './api/userContextApi';

/**
 * To be kept in sync with the types in onboarding-settings-client:
 * {@link https://git.hubteam.com/HubSpot/onboarding-settings-client/blob/568692dbaf325e806a0c2d95957d6018bb766df4/onboarding-settings-client/static/js/constants/onboardingViews.ts#L6 onboarding-settings-client/static/js/constants/onboardingViews.ts}
 */
export const ONBOARDING_VIEWS = {
  SALES: 'sales',
  MARKETING: 'marketing',
  SERVICE: 'service',
  CMS: 'cms',
  COMMERCE: 'commerce',
  OPS: 'ops',
  SUITE: 'suite',
  BUSINESS_BOX: 'businessBox',
  USE_CASE: 'useCase'
};

/**
 * To be kept in sync with the types in onboarding-settings-client:
 * {@link https://git.hubteam.com/HubSpot/onboarding-settings-client/blob/568692dbaf325e806a0c2d95957d6018bb766df4/onboarding-settings-client/static/js/constants/onboardingViews.ts#L23 onboarding-settings-client/static/js/constants/onboardingViews.ts}
 */

export const getOnboardingSettings = (userId, portalId) => {
  return fetchOnboardingSettings(userId, portalId);
};
export const setOnboardingSettings = (onboardingSettings, userId, portalId = PortalIdParser.get(), options) => {
  return patchOnboardingSettings(onboardingSettings, userId, portalId, options);
};
export const setHubOnboardingSettings = (view, settings, userId, portalId = PortalIdParser.get()) => {
  const onboardingSettings = {
    views: {
      [view]: settings
    },
    selectedView: view
  };
  const settingsRequests = [setOnboardingSettings(onboardingSettings, userId, portalId)];
  if (settings.groupKey) {
    settingsRequests.push(setGroupKey(settings.groupKey));
  }
  return Promise.all(settingsRequests);
};