import { useEffect } from 'react';
import { useIsFreeOrStarterPortal } from 'onboarding-scopes';
import { setupWootricSurvey } from '../utils/wootricSurvey';
const USER_GUIDE_CES_SURVEY_KEY = 'SETUP_COMPLETE';
const UserGuideCesSurvey = () => {
  const isFreeOrStarterPortal = useIsFreeOrStarterPortal();
  useEffect(() => {
    if (isFreeOrStarterPortal) {
      /**
       * The User Guide CES survey (SETUP_COMPLETE) setting is maintained by Wootric team
       * Current setting is: New signup users within 0-90 days
       * Please reach out to Slack channel #wootric-implementation for updating the survey settings
       */
      setupWootricSurvey('CES', USER_GUIDE_CES_SURVEY_KEY);
    }
  }, [isFreeOrStarterPortal]);
  return null;
};
export default UserGuideCesSurvey;