import * as browserHelper from 'usage-tracker-core/client/browserHelper';
import { getPortalId } from 'usage-tracker-core/common/defaultTrackers';
import { getHubPrivacySettings } from './requests';

// Retrieves the disallowed environments where Session Replay should be disabled
export const getIsDisallowedEnvironment = () => {
  // For testing purposes, we disable Session Replay on Selenium environments
  if (document.cookie.includes('hs_selenium=')) {
    return true;
  }

  // For testing purposes, we disable Session Replay on Jasmine environments
  if ('jasmine' in window) {
    return true;
  }
  return false;
};
export const getIsSensitiveEnvironment = () => {
  // For non-PROD environments, we shouldn't care or block if the sensitivty is enabled
  // since we're not storing any sensitive data on QA portals
  if (browserHelper.isQaDeployment) {
    return Promise.resolve(false);
  }

  // If we are not in a Portal-based environment,
  // we don't need to check for sensitive environments
  if (getPortalId() === null) {
    return Promise.resolve(false);
  }

  // For PII and Security reasons, we disable Session Replay on Portal 53
  // As it has HubSpot-sensitive information
  if (getPortalId() === 53) {
    return Promise.resolve(true);
  }

  // We check if the Hub Privacy Settings are enabled for the current Portal
  // and even if the request fails or has invalid formatted data we fallback to `true`
  // as it is better to be more restrictive/protective than accidentally slipping
  // sensitive portals through.
  return getHubPrivacySettings().then(({
    sensitiveDataEnabled
  }) => Boolean(sensitiveDataEnabled)).catch(() => true);
};