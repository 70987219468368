import { TASK_REQUEST_REQUESTED, TASK_REQUEST_RECEIVED, TASK_REQUEST_ERROR, TASK_SET_COMPLETE_ERROR } from '../actions/ActionTypes';
const INITIAL_STATE = {
  isFetching: false,
  hasFetched: false,
  error: undefined,
  groupKey: undefined,
  taskGroups: [],
  taskMap: {},
  timestamp: Date.now()
};
export default function taskReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case TASK_REQUEST_REQUESTED:
      {
        return Object.assign({}, INITIAL_STATE, {
          groupKey: action.payload,
          hasFetched: false,
          isFetching: true,
          timestamp: action.timestamp || Date.now()
        });
      }
    case TASK_REQUEST_RECEIVED:
      {
        const {
          groupKey,
          taskGroups,
          taskMap
        } = action.payload;
        const {
          timestamp
        } = action;
        if (timestamp && timestamp < state.timestamp) {
          return state;
        }
        return Object.assign({}, state, {
          isFetching: false,
          hasFetched: true,
          error: undefined,
          groupKey,
          taskGroups,
          taskMap,
          timestamp: timestamp || Date.now()
        });
      }
    case TASK_REQUEST_ERROR:
      {
        return Object.assign({}, state, {
          isFetching: false,
          hasFetched: false,
          error: action.payload
        });
      }
    case TASK_SET_COMPLETE_ERROR:
      {
        return Object.assign({}, state, {
          taskErrorStatus: action.payload
        });
      }
    default:
      return state;
  }
}