import { addTrackingCode, connectInbox, connectInboxAndCalendar, installExtension, quickInviteModal, integrateAppsWithHubspot } from 'getting-started-shared-tasks/manualDetectedTasks';
import { addDataModal, createContact, inviteYourTeam } from 'getting-started-shared-tasks/taskNames';
import AddTrackingCodeButton from './AddTrackingCodeButton';
import ConnectInboxButton from './ConnectInboxButton';
import InstallExtensionButton from './InstallExtensionButton';
import InviteTaskButton from './InviteTaskButton';
import QuickInviteTaskButton from './QuickInviteTaskButton';
import AddDataButton from './AddDataButton';
import CreateContactButton from './CreateContactButton';
import MarketplaceButton from './MarketplaceButton';
const ManualDetectedTaskButtons = {
  [addTrackingCode]: AddTrackingCodeButton,
  [connectInbox]: ConnectInboxButton,
  [connectInboxAndCalendar]: ConnectInboxButton,
  [installExtension]: InstallExtensionButton,
  [inviteYourTeam]: InviteTaskButton,
  [quickInviteModal]: QuickInviteTaskButton,
  [addDataModal]: AddDataButton,
  [createContact]: CreateContactButton,
  [integrateAppsWithHubspot]: MarketplaceButton
};
export default ManualDetectedTaskButtons;